import React from 'react'
import { Link } from 'react-router-dom'
import { motion } from 'framer-motion'
import LogoShopee from '../../assets/images/logoShopeepng.png'
import LogoTokopedia from '../../assets/images/logoTokped.png'
import { MdVerified } from 'react-icons/md'

const OfficialShopSection = ({ translations, language, isDarkMode }) => (
	<section
		id="officialshop"
		className="overflow-hidden relative py-20 bg-white text-center rounded-lg px-4 md:px-0"
	>
		{/* Title Section with Icon */}
		<motion.div
			initial={{ opacity: 0, y: -20 }}
			animate={{ opacity: 1, y: 0 }}
			className="flex flex-col items-center mb-8 md:mb-12"
		>
			<div className="flex items-center gap-2 md:gap-3">
				<MdVerified className="text-[#0081FB] text-3xl md:text-4xl" />
				<h1 className={`text-3xl md:text-4xl font-bold text-[#0081FB]`}>
					{translations[language].olshop}
				</h1>
			</div>
			<p className="text-gray-600 text-base md:text-lg mt-2">
				{translations[language].olshopSubtitle}
			</p>
		</motion.div>

		{/* Shop Links in Elegant Card Design */}
		<div className="flex flex-col md:flex-row justify-center gap-6 md:gap-8 mt-8 w-full">
			{/* Shopee Card */}
			<motion.div
				initial={{ opacity: 0, y: 20 }}
				animate={{ opacity: 1, y: 0 }}
				transition={{ duration: 0.6 }}
				whileHover={{ scale: 1.05 }}
				whileTap={{ scale: 0.97 }}
				className="bg-white rounded-2xl shadow-lg hover:shadow-xl w-full md:w-72 h-64 flex flex-col items-center justify-center transition duration-300 relative overflow-hidden"
			>
				<Link
					to="https://shopee.co.id/rrwgaming"
					target="_blank"
					className="flex flex-col items-center"
				>
					<img
						src={LogoShopee}
						alt="Shopee Logo"
						className="h-14 md:h-16 mb-3 md:mb-4"
					/>
					<span className="text-lg md:text-xl font-semibold text-gray-800">
						Shopee
					</span>
					<button className="mt-3 md:mt-4 px-4 py-2 bg-[#0081FB] text-white rounded-full hover:bg-[#006bbd] transition duration-300">
						{translations[language].visitStore}
					</button>
				</Link>

				{/* Decorative Elements */}
				<div className="absolute -top-5 -right-5 w-16 h-16 md:w-20 md:h-20 bg-[#FF6600] opacity-10 rounded-full"></div>
			</motion.div>

			{/* Tokopedia Card */}
			<motion.div
				initial={{ opacity: 0, y: 20 }}
				animate={{ opacity: 1, y: 0 }}
				transition={{ duration: 0.6 }}
				whileHover={{ scale: 1.05 }}
				whileTap={{ scale: 0.97 }}
				className="bg-white rounded-2xl shadow-lg hover:shadow-xl w-full md:w-72 h-64 flex flex-col items-center justify-center transition duration-300 relative overflow-hidden"
			>
				<Link
					to="https://www.tokopedia.com/hypertopia15"
					target="_blank"
					className="flex flex-col items-center"
				>
					<img
						src={LogoTokopedia}
						alt="Tokopedia Logo"
						className="h-14 md:h-16 mb-3 md:mb-4"
					/>
					<span className="text-lg md:text-xl font-semibold text-gray-800">
						Tokopedia
					</span>
					<button className="mt-3 md:mt-4 px-4 py-2 bg-[#0081FB] text-white rounded-full hover:bg-[#006bbd] transition duration-300">
						{translations[language].visitStore}
					</button>
				</Link>

				{/* Decorative Elements */}
				<div className="absolute -bottom-5 -left-5 w-16 h-16 md:w-20 md:h-20 bg-[#02A676] opacity-10 rounded-full"></div>
			</motion.div>
		</div>
	</section>
)

export default OfficialShopSection
