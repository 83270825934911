import React, { useState, useEffect } from 'react'
import { getDatabase, ref, onValue } from 'firebase/database'
import { Helmet } from 'react-helmet'
import { motion } from 'framer-motion' // Import motion from framer-motion

import quest1 from '../../assets/images/meta-quest-1.webp'
import quest2 from '../../assets/images/meta-quest-2.webp'
import quest3 from '../../assets/images/meta-quest-3.webp'
import questPro from '../../assets/images/meta-quest-pro.webp'
import { FaFileDownload } from 'react-icons/fa'
import { BsMeta } from 'react-icons/bs'
import { MdUpdate } from 'react-icons/md'
import { BsHeadsetVr } from 'react-icons/bs'
import bgPage from '../../assets/images/bg.jpg'

const translations = {
	en: {
		info: 'Info',
		firmwareQuest: 'Firmware Quest',
		descFirmwareQuest: 'Find the latest firmware for your Meta Quest devices.',
		firmwareQuestInfo:
			'This page contains a list of available firmware for Meta Quest devices if you want to force update to the latest version or encounter problems on your Quest such as bootloop, stuck on Oculus logo, or other issues.',
		buildDate: 'Build Date',
		version: 'Version',
		runtimeVersion: 'Runtime Version',
		download: 'Download',
		sourceFrom: 'Source from',
		caution:
			'Caution: Make sure you know what you are doing before flashing firmware to your device! Because this action has the risk of bricking (dead) your device if not done correctly.',
	},
	id: {
		info: 'Info',
		firmwareQuest: 'Firmware Quest',
		descFirmwareQuest:
			'Temukan firmware terbaru untuk perangkat Meta Quest Anda.',
		firmwareQuestInfo:
			'Halaman ini berisi daftar firmware yang tersedia untuk perangkat Meta Quest jika ingin update paksa ke versi terbaru atau terjadi masalah pada Quest Anda seperti bootloop, stuck di logo Oculus, atau masalah lainnya.',
		buildDate: 'Tanggal Dibuat',
		version: 'Versi',
		runtimeVersion: 'Versi Runtime',
		download: 'Unduh',
		sourceFrom: 'Sumber dari',
		caution:
			'Peringatan: Pastikan Anda tahu apa yang Anda lakukan sebelum mem-flash firmware ke perangkat Anda! Karena tindakan ini terdapat risiko brick (mati total) pada perangkat Anda jika tidak dilakukan dengan benar.',
	},
}

const FirmwareQuest = () => {
	const language = localStorage.getItem('language') || 'id'
	const [firmwareData, setFirmwareData] = useState([])
	const [selectedFilter, setSelectedFilter] = useState('Semua Quest')
	const [isMobile, setIsMobile] = useState(window.innerWidth < 1200)

	useEffect(() => {
		const handleResize = () => {
			setIsMobile(window.innerWidth < 1200)
		}

		window.addEventListener('resize', handleResize)

		return () => {
			window.removeEventListener('resize', handleResize)
		}
	}, [])

	useEffect(() => {
		const db = getDatabase()
		const firmwareRef = ref(db, 'firmwareQuest/')
		onValue(firmwareRef, (snapshot) => {
			const data = snapshot.val()
			const firmwareList = []

			for (const quest in data) {
				const questData = data[quest]
				for (const incremental in questData) {
					const firmware = questData[incremental]
					firmwareList.push({
						...firmware,
						quest,
						incremental,
					})
				}
			}

			setFirmwareData(firmwareList)
		})
	}, [])

	const filters = [
		'Semua Quest',
		'Meta Quest 1',
		'Meta Quest 2',
		'Meta Quest 3',
		'Meta Quest 3S',
		'Meta Quest Pro',
	]

	const filteredFirmware =
		selectedFilter === 'Semua Quest'
			? firmwareData
			: firmwareData.filter((firmware) => firmware.quest === selectedFilter)

	const getQuestImage = (quest) => {
		switch (quest) {
			case 'Meta Quest 1':
				return quest1
			case 'Meta Quest 2':
				return quest2
			case 'Meta Quest 3':
				return quest3
			case 'Meta Quest Pro':
				return questPro
			default:
				return quest2
		}
	}

	const formatDate = (dateString) => {
		const date = new Date(dateString)
		return new Intl.DateTimeFormat(language === 'id' ? 'id-ID' : 'en-US', {
			weekday: 'short',
			year: 'numeric',
			month: 'short',
			day: 'numeric',
			hour: '2-digit',
			minute: '2-digit',
			second: '2-digit',
		}).format(date)
	}

	const [gridColsFQ, setGridColsFQ] = useState('grid-cols-1')

	useEffect(() => {
		const handleResize = () => {
			if (window.innerWidth >= 4000) {
				setGridColsFQ('grid-cols-10')
			} else if (window.innerWidth >= 3675) {
				setGridColsFQ('grid-cols-9')
			} else if (window.innerWidth >= 3275) {
				setGridColsFQ('grid-cols-8')
			} else if (window.innerWidth >= 2875) {
				setGridColsFQ('grid-cols-7')
			} else if (window.innerWidth >= 2475) {
				setGridColsFQ('grid-cols-6')
			} else if (window.innerWidth >= 2075) {
				setGridColsFQ('grid-cols-5')
			} else if (window.innerWidth >= 1675) {
				setGridColsFQ('grid-cols-4')
			} else if (window.innerWidth >= 1275) {
				setGridColsFQ('grid-cols-3')
			} else if (window.innerWidth >= 875) {
				setGridColsFQ('grid-cols-2')
			} else {
				setGridColsFQ('grid-cols-1')
			}
		}

		handleResize()

		window.addEventListener('resize', handleResize)

		return () => {
			window.removeEventListener('resize', handleResize)
		}
	}, [])

	return (
		<div className={`py-5 w-full min-h-screen ${!isMobile && 'pt-[70px]'}`}>
			<header
				className="bg-gradient-to-r from-[#0881FB] to-[#0059b2] py-12 text-center text-white px-6 sm:px-10"
				style={{
					background: `linear-gradient(to left, rgba(8, 129, 251, 0.95) 10%, rgba(0, 89, 178, 0.95) 100%), url(${bgPage}) center/cover`,
				}}
			>
				<h1 className="text-3xl md:text-4xl font-bold tracking-wide">
					{translations[language].firmwareQuest}
				</h1>
				<p className="text-base md:text-lg mt-4">
					{translations[language].descFirmwareQuest}
				</p>
				<p className="w-full flex items-center justify-center gap-2.5 mt-6">
					<p className="px-4 py-2.5 flex items-center justify-center gap-2 rounded-full w-fit bg-white text-[#0081FB] font-semibold text-sm md:text-base">
						<p className="text-sm text-gray-500 italic">
							{translations[language].sourceFrom}{' '}
							<a
								href="https://cocaine.trade/"
								className="hover:underline text-[#0081FB] font-semibold"
							>
								Cocaine.Trade
							</a>{' '}
							-{' '}
							<a
								href="https://x.com/basti564"
								className="hover:underline text-[#0081FB] font-semibold"
							>
								basti564
							</a>
						</p>
					</p>
				</p>
			</header>

			<p className="px-4 py-5 bg-red-50 rounded-xl text-red-500 text-sm italic mb-6 text-center">
				{translations[language].caution}
			</p>

			<div className="flex flex-col items-center justify-center px-5">
				<motion.div
					className="w-full max-w-4xl flex overflow-x-auto gap-4 mb-3 scrollbar-hide"
					initial={{ opacity: 0, y: -20 }}
					animate={{ opacity: 1, y: 0 }}
					transition={{ duration: 0.5 }}
				>
					{filters.map((filter, index) => (
						<motion.button
							key={index}
							onClick={() => setSelectedFilter(filter)}
							className={`px-4 py-2 text-nowrap rounded-lg border-2 transition duration-300 ${
								selectedFilter === filter
									? 'border-blue-600 bg-blue-100 text-blue-600'
									: 'border-zinc-100 bg-zinc-100 text-zinc-500'
							}`}
							whileHover={{ scale: 1.02 }}
						>
							{filter}
						</motion.button>
					))}
				</motion.div>

				<motion.div
					className={`grid ${gridColsFQ} gap-6 mt-8 w-full`}
					initial={{ opacity: 0 }}
					animate={{ opacity: 1 }}
					transition={{ duration: 0.5 }}
				>
					{filteredFirmware.map((firmware) => (
						<motion.div
							key={firmware.incremental}
							className="bg-white rounded-2xl border-2 overflow-hidden p-6 w-full"
							initial={{ opacity: 0, scale: 0.9 }}
							animate={{ opacity: 1, scale: 1 }}
							transition={{ duration: 0.3 }}
						>
							<img
								src={getQuestImage(firmware.quest)}
								alt={`${firmware.quest}`}
								className="w-full h-40 object-cover"
							/>

							<div>
								<p className="text-xs font-semibold uppercase mb-2 px-2 py-1 bg-[#0081FB] text-white rounded-lg w-fit flex flex-row items-center justify-center gap-1.5">
									<BsMeta size={14} />
									{firmware.quest}
								</p>

								<h3 className="text-gray-800 font-bold text-lg">
									{translations[language].version}: {firmware.version}
								</h3>
								<p className="text-gray-600 mt-1 text-sm">
									{translations[language].runtimeVersion}:{' '}
									{firmware.runtimeVersion}
								</p>
								<p className="text-gray-600 text-sm">
									{translations[language].buildDate}:{' '}
									{formatDate(firmware.buildDate)}
								</p>

								<a
									href={firmware.downloadLink}
									className="mt-6 px-4 py-2 bg-[#0081FB] text-white rounded-lg hover:bg-blue-700 transition duration-300 w-full text-center flex flex-row items-center justify-center gap-2"
									download
								>
									{translations[language].download}
									<FaFileDownload />
								</a>
							</div>
						</motion.div>
					))}
				</motion.div>
			</div>
			<Helmet>
				<title>Firmware Quest | HyperTopia</title>
			</Helmet>
		</div>
	)
}

export default FirmwareQuest
