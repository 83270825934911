import React, { useEffect, useState } from 'react'
import { motion } from 'framer-motion'
import bgHero from '../../assets/images/BgHero.png'
import { FaCircleArrowDown } from 'react-icons/fa6'

const HeroSection = ({ translations, language, nextSectionRef }) => {
	const handleScroll = () => {
		if (nextSectionRef?.current) {
			const offsetTop =
				nextSectionRef.current.getBoundingClientRect().top + window.scrollY - 80
			window.scrollTo({
				top: offsetTop,
				behavior: 'smooth',
			})
		}
	}

	const [isScrollDownVisible, setIsScrollDownVisible] = useState(true)

	useEffect(() => {
		const handleScroll = () => {
			const homeSection = document.getElementById('hero')
			if (homeSection) {
				const rect = homeSection.getBoundingClientRect()
				setIsScrollDownVisible(
					rect.bottom > window.innerHeight - window.innerHeight * 0.5
				)
			}
		}

		window.addEventListener('scroll', handleScroll)

		return () => {
			window.removeEventListener('scroll', handleScroll)
		}
	}, [])

	return (
		<section
			id="hero"
			className="hero-section relative text-center flex flex-col items-center justify-start pt-24 bg-gradient-to-br from-[#0081FB] to-blue-600 overflow-hidden px-4 md:px-5"
			style={{ height: 'calc(100vh + 100px)' }}
		>
			<div
				className="mx-auto max-h-full border-2 bg-white bg-opacity-10 border-white border-opacity-80 rounded-[20px] md:rounded-[30px] p-6 md:p-8 flex flex-col items-center justify-center"
				style={{
					width: 'calc(100% - 2rem)',
					height: 'calc(90% - 2rem)',
					background: `linear-gradient(to left, rgba(0, 129, 251, 0.85) 0%, rgba(0, 129, 251, 0.85) 100%), url(${bgHero}) center/cover`,
				}}
			>
				{/* Judul */}
				{language === 'en' ? (
					<motion.h1
						initial={{ opacity: 0, y: -50 }}
						animate={{ opacity: 1, y: 0 }}
						transition={{ duration: 1 }}
						className="text-4xl md:text-5xl lg:text-6xl font-extrabold text-white leading-tight mb-4 md:mb-5"
					>
						<span className="text-yellow-300">The Best Place</span> to <br />
						<span className="text-white">Explore and Play</span> Games
					</motion.h1>
				) : (
					<motion.h1
						initial={{ opacity: 0, y: -50 }}
						animate={{ opacity: 1, y: 0 }}
						transition={{ duration: 1 }}
						className="text-4xl md:text-5xl lg:text-6xl font-extrabold text-white leading-tight mb-4 md:mb-5"
					>
						<span className="text-yellow-300">Tempat Terbaik</span> untuk <br />
						<span>
							<span className="text-white">Menjelajah dan Bermain</span> Game
						</span>
					</motion.h1>
				)}

				{/* Subtitle */}
				<motion.p
					initial={{ opacity: 0, y: 50 }}
					animate={{ opacity: 1, y: 0 }}
					transition={{ duration: 1, delay: 0.5 }}
					className="text-base md:text-lg lg:text-xl text-white opacity-90 mb-8 md:mb-10"
				>
					{translations[language].heroSubtitle}
				</motion.p>

				<div
					className={`${isScrollDownVisible ? 'opacity-100' : 'opacity-0'} transition-all duration-500 flex flex-col justify-center items-center absolute z-10 bottom-28 left-1/2 transform -translate-x-1/2`}
				>
					<p className="absolute bottom-[110px] text-white">
						{translations[language].scrollDown}
					</p>
					<div className="scroll-down"></div>
				</div>

				{/* Tombol */}
				<motion.div
					initial={{ opacity: 0, scale: 0.8 }}
					animate={{ opacity: 1, scale: 1 }}
					transition={{ duration: 0.5, delay: 1 }}
				>
					<button
						onClick={handleScroll}
						className="flex flex-row gap-2 items-center justify-center bg-yellow-400 hover:bg-yellow-500 text-black font-semibold px-5 py-2 md:px-6 md:py-3 rounded-full shadow-lg transition duration-300"
					>
						<p>{translations[language].getStarted}</p>
						<FaCircleArrowDown />
					</button>
				</motion.div>
			</div>

			{/* Elemen dekoratif */}
			<motion.div
				className="absolute top-1/4 left-1/4 bg-blue-300 w-12 h-12 md:w-16 md:h-16 lg:w-20 lg:h-20 rounded-full opacity-40"
				initial={{ scale: 0, opacity: 0 }}
				animate={{ scale: 1, opacity: 0.4 }}
				transition={{
					duration: 1,
					delay: 1.2,
					repeat: Infinity,
					repeatType: 'reverse',
				}}
			></motion.div>
			<motion.div
				className="absolute bottom-1/4 right-1/4 bg-yellow-300 w-16 h-16 md:w-20 md:h-20 lg:w-24 lg:h-24 rounded-full opacity-30"
				initial={{ scale: 0, opacity: 0 }}
				animate={{ scale: 1, opacity: 0.3 }}
				transition={{
					duration: 1,
					delay: 1.5,
					repeat: Infinity,
					repeatType: 'reverse',
				}}
			></motion.div>

			{/* SVG Wave Pattern */}
			<div className="absolute bottom-0 left-0 w-full overflow-hidden leading-[0]">
				<svg
					className="relative block w-full h-[20px] md:h-[30px] bg-[#0081FB]"
					viewBox="0 0 1200 40"
					xmlns="http://www.w3.org/2000/svg"
					preserveAspectRatio="none"
				>
					<path
						d="M0,0 C300,50 900,50 1200,0 L1200,120 L0,120 Z"
						fill="#FFFFFF"
					></path>
				</svg>
			</div>

			<style>
				{`
				@keyframes up-down-animation {
          0%, 100% { transform: translateY(0); }
          50% { transform: translateY(-10px); }
        }

        .scroll-down {
          height: 50px;
          width: 30px;
          border: 2px solid #fff;
          position: absolute;
          bottom: 50px;
          border-radius: 50px;
          cursor: pointer;
          z-index: 20;
        }

        .scroll-down::before,
        .scroll-down::after {
          content: '';
          position: absolute;
          top: 20%;
          left: 50%;
          border: 2px solid #fff;
          height: 10px;
          width: 10px;
          transform: translate(-50%, -100%) rotate(45deg);
          border-top: transparent;
          border-left: transparent;
          animation: scrollDown 1s ease-in-out infinite;
        }

        .scroll-down::after {
          top: 30%;
          animation-delay: .3s;
        }

        @keyframes scrollDown {
          0% {
            opacity: 0;
          }
          30% {
            opacity: 1;
          }
          60% {
            opacity: 1;
          }
          100% {
            opacity: 0;
            top: 90%;
          }
				}

				@keyframes glow {
					0% {
						box-shadow: 0 0 10px rgba(255, 255, 255, 0.6), 0 0 20px rgba(255, 255, 255, 0.4);
					}
					50% {
						box-shadow: 0 0 20px rgba(255, 255, 255, 0.8), 0 0 30px rgba(255, 255, 255, 0.6);
					}
					100% {
						box-shadow: 0 0 10px rgba(255, 255, 255, 0.6), 0 0 20px rgba(255, 255, 255, 0.4);
					}
				}

				.glow-border {
					animation: glow 2s infinite;
				}
				`}
			</style>
		</section>
	)
}

export default HeroSection
