import React, { useState, useEffect } from 'react'
import { FaHeart, FaPlay, FaPlus, FaRegHeart } from 'react-icons/fa6'
import { FaDownload } from 'react-icons/fa6'
import { MdFileDownload, MdSdStorage, MdUpdate } from 'react-icons/md'
import fireDb from '../../../../firebase'
import { auth } from '../../../../firebase'
import {
	FaCheck,
	FaExclamationTriangle,
	FaInfoCircle,
	FaTimes,
} from 'react-icons/fa'
import { IoReturnUpBackOutline } from 'react-icons/io5'
import { getDownloadURL, listAll } from 'firebase/storage'
import { storage } from '../../../../firebase'
import { ref } from 'firebase/storage'
import { LazyLoadImage } from 'react-lazy-load-image-component'
import Swal from 'sweetalert2'
import toast, { Toaster } from 'react-hot-toast'
import { motion } from 'framer-motion'
import YouTube from 'react-youtube'

const formatDownloadCount = (count) => {
	if (count >= 1000000) {
		return `${(count / 1000000).toFixed(1)}M`
	} else if (count >= 1000) {
		return `${(count / 1000).toFixed(1)}K`
	}
	return count
}

const formatLikedGamesCount = (count) => {
	if (count >= 1000000) {
		return `${(count / 1000000).toFixed(1)}M`
	} else if (count >= 1000) {
		return `${(count / 1000).toFixed(1)}K`
	}
	return count
}

const ListGamesCard_Standalone = ({ game, layout, order }) => {
	const {
		gameTitle,
		linkDownload,
		gameVersion,
		gameSize,
		gameStatus,
		downloadCount,
		timeAdded,
		likedCount,
		videoIdYouTube,
		videoUrlMetaStore,
	} = game

	const language = localStorage.getItem('language') || 'id'
	const [user, setUser] = useState(null)
	const [showAnnouncement, setShowAnnouncement] = useState(false)
	const [showReportPopup, setShowReportPopup] = useState(false)
	const eligibleUsersRef = fireDb.ref('eligibleUsers/standalone')
	const imageListRef = ref(storage, 'coverGamesImages/')
	const [gameBackgroundUrl, setGameBackgroundUrl] = useState(null)
	const [loadingImages, setLoadingImages] = useState(true)
	const translations = {
		en: {
			requireToDownloadAllParts:
				'You must download all parts to extract the game!',

			report: 'Report',
			download: 'Download',
			notAvailable: 'Not Available. Game will be added gradually.',
			newAdded: 'NEW',
			notLogin: 'Please login first!',
			notEligible: 'You are not eligible to access the download link.',
			readyButNotEligible: 'Available, but do not have access to download!',
			update: 'UPDATE',
			comingSoon: 'SOON',
			details: 'Details',
			requestGame: 'Request Game',

			requested: 'Requested',
			fileNoLonger: 'File No Longer Available / Link Error / File Missing',
			gameNotLaunching: 'Game Not Launching',
			gameStuckAtLoadingScreen: 'Game Stuck at Loading Screen',
			gameCrashing: 'Game Crashing',
			gamePerformanceIssues: 'Game Performance Issues',
			gameVisualGlitches: 'Game Visual Glitches',
			others: 'Others',

			cancel: 'Cancel',
			submit: 'Submit',

			downloaded: 'Download',
			likes: 'Likes',

			removeLiked: 'Game Successfully Removed from Liked Games',
			addLiked: 'Game Successfully Added to Liked Games',

			failedRemoveLiked: 'Failed to Remove from Liked Games',
			failedAddLiked: 'Failed to Add to Liked Games',
		},
		id: {
			requireToDownloadAllParts:
				'Anda wajib mengunduh semua part untuk mengekstrak game!',

			report: 'Laporkan',
			download: 'Unduh',
			notAvailable:
				'Tidak Tersedia. Silahkan bisa request game ini di halaman Game VR > Request Game.',
			newAdded: 'BARU',
			notLogin: 'Silahkan login terlebih dahulu!',
			notEligible: 'Anda tidak memenuhi syarat untuk mengakses link download.',
			readyButNotEligible: 'Tersedia, namun tidak memiliki akses untuk unduh!',
			update: 'UPDATE',
			comingSoon: 'SEGERA',
			details: 'Detail',
			requestGame: 'Request Game',

			requested: 'Telah Direquest',
			fileNoLonger: 'File tidak tersedia / Link Error / File Hilang',
			gameNotLaunching: 'Game Tidak Bisa Dijalankan',
			gameStuckAtLoadingScreen: 'Game Tersangkut di Loading Screen',
			gameCrashing: 'Game Sering Crash',
			gamePerformanceIssues: 'Game Mengalami Masalah Performa',
			gameVisualGlitches: 'Game Mengalami Glitch Visual',
			others: 'Lainnya',

			reportProblem: 'Laporkan Masalah',
			areYouSureReportProblem:
				'Apakah Anda yakin ingin melaporkan masalah ini?',
			reportProblemSubmitted: 'Laporan Masalah Telah Terkirim.',
			thanksForReportProblem:
				'Terima kasih atas laporan masalah yang Anda kirimkan.',
			reportProblemFailed: 'Gagal Mengirim Laporan Masalah.',
			somethingWentWrong:
				'Terjadi kesalahan saat mengirim laporan masalah. Silakan coba lagi.',

			cancel: 'Batal',
			submit: 'Kirim',

			downloaded: 'Unduhan',
			likes: 'Suka',

			removeLiked: 'Game Berhasil Dihapus dari Daftar Suka',
			addLiked: 'Game Berhasil Ditambahkan ke Daftar Suka',

			failedRemoveLiked: 'Gagal Menghapus dari Daftar Suka',
			failedAddLiked: 'Gagal Menambahkan ke Daftar Suka',
		},
	}

	const [isGameRequested, setIsGameRequested] = useState(false)

	useEffect(() => {
		const unsubscribe = auth.onAuthStateChanged(async (user) => {
			if (user) {
				setUser(user)
				try {
					const snapshot = await eligibleUsersRef
						.orderByChild('email')
						.equalTo(user.email)
						.once('value')
					const eligibleUsers = snapshot.val()
					if (eligibleUsers) {
						setShowAnnouncement(false)
					} else {
						setShowAnnouncement(true)
					}
				} catch (error) {
					console.error('Error fetching eligible users:', error.message)
				}
			} else {
				setUser(null)
			}
		})

		return () => {
			unsubscribe()
		}
	}, [])

	useEffect(() => {
		const requestedGameRef = fireDb.ref(`requestedVRGames/${game.gameTitle}`)
		requestedGameRef
			.once('value')
			.then((snapshot) => {
				const requestedGame = snapshot.val()

				if (requestedGame) {
					setIsGameRequested(true)
				} else {
					setIsGameRequested(false)
				}
			})
			.catch((error) => {
				console.error('Error checking requested game:', error.message)
			})
	})

	const handleRequestGameClick = () => {
		if (!isGameRequested) {
			Swal.fire({
				title: 'Request Game',
				text: `Apakah Anda yakin ingin merequest game ${game.gameTitle}?`,
				icon: 'question',
				showCancelButton: true,
				confirmButtonText: 'Yes',
				cancelButtonText: 'No',
			}).then((result) => {
				if (result.isConfirmed) {
					const newRequestedGame = {
						gameTitle: game.gameTitle,
						gameType: 'standalone',
						metaStoreLink: game.linkMetaStore,
						reason: '',
						requestedBy: user.email,
						status: 'Pending',
						timeRequested: new Date().toISOString(),
					}

					fireDb
						.ref(`requestedVRGames/${game.gameTitle}`)
						.set(newRequestedGame)
						.then(() => {
							setIsGameRequested(true)

							Swal.fire({
								title: 'Game Requested!',
								text: `Game ${game.gameTitle} telah direquest.`,
								icon: 'success',
								confirmButtonText: 'OK',
								showCancelButton: true,
								cancelButtonText: 'Lihat Request',
							}).then((result) => {
								if (result.isConfirmed) {
								} else if (result.dismiss === Swal.DismissReason.cancel) {
									window.location.href = '/vr-games/request-games'
								}
							})
						})
						.catch((error) => {
							Swal.fire({
								title: 'Error!',
								text: 'Terjadi kesalahan saat merequest game.',
								icon: 'error',
								confirmButtonText: 'OK',
							})
						})
				}
			})
		}
	}

	const handleUpdateGameClick = () => {
		Swal.fire({
			title: `<b>Request Update Game</b>`,
			html: `
				<div style="display: flex; flex-direction: column; align-items: center;">
					<p style="margin-bottom: 8px; font-size: 20px; color: #333;">${game.gameTitle}</p>
					<label style="font-weight: bold; margin-bottom: 8px; font-size: 16px; color: #333;">Versi Game:</label>
					<input type="text" id="versionInput" class="swal2-input" placeholder="Masukkan versi game" 
						style="
							width: 100%; 
							padding: 10px;
							font-size: 14px; 
							border-radius: 10px; 
							border: 1px solid #ddd; 
							box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
						">
				</div>
			`,
			showCancelButton: true,
			confirmButtonColor: '#0081FB',
			cancelButtonColor: '#d33',
			confirmButtonText: 'Kirim Permintaan',
			cancelButtonText: 'Batal',
			buttonsStyling: true,
			customClass: {
				confirmButton: 'rounded-full px-6 py-2 text-lg',
				cancelButton: 'rounded-full px-6 py-2 text-lg',
			},
			preConfirm: () => {
				const version = Swal.getPopup().querySelector('#versionInput').value
				if (!version) {
					Swal.showValidationMessage('Silakan masukkan versi game')
					return
				}
				return version
			},
		}).then((result) => {
			if (result.isConfirmed) {
				const version = result.value
				const newUpdateGame = {
					gameTitle: game.gameTitle,
					gameType: 'standalone',
					metaStoreLink: game.linkMetaStore,
					reason: '',
					requestedBy: user.email,
					status: 'Pending',
					timeRequested: new Date().toISOString(),
					previousVersion: game.gameVersion,
					newVersion: version,
					requestType: 'update',
				}

				fireDb
					.ref(`requestedVRGames/${game.gameTitle}`)
					.set(newUpdateGame)
					.then(() => {
						Swal.fire({
							title: 'Permintaan Dikirim!',
							text: `Permintaan update untuk game ${game.gameTitle} telah dikirim.`,
							icon: 'success',
							confirmButtonText: 'OK',
						})
					})
					.catch((error) => {
						Swal.fire({
							title: 'Error!',
							text: 'Terjadi kesalahan saat mengirim permintaan update game.',
							icon: 'error',
							confirmButtonText: 'OK',
						})
					})
			}
		})
	}

	useEffect(() => {
		const fetchData = async () => {
			const cachedUrl = localStorage.getItem(`gameBackgroundUrl_${gameTitle}`)
			const lastFetchTime = localStorage.getItem(`lastFetchTime_${gameTitle}`)
			if (!cachedUrl || Date.now() - parseInt(lastFetchTime) > 3600000) {
				try {
					const response = await listAll(imageListRef)
					const gameImageName = `${gameTitle}`
					const userImage = response.items.find(
						(item) => item.name === gameImageName
					)
					const unknownImage = response.items.find(
						(item) => item.name === 'UnknownPicture'
					)
					if (userImage) {
						const url = await getDownloadURL(userImage)
						setGameBackgroundUrl(url)
						setLoadingImages(false)
						localStorage.setItem(`gameBackgroundUrl_${gameTitle}`, url)
						localStorage.setItem(`lastFetchTime_${gameTitle}`, Date.now())
					} else {
						const url = await getDownloadURL(unknownImage)
						setGameBackgroundUrl(url)
						setLoadingImages(false)
					}
				} catch (error) {
					console.error('Error fetching game background URL:', error.message)
				}
			} else {
				setGameBackgroundUrl(cachedUrl)
				setLoadingImages(false)
			}
		}

		fetchData()
	}, [gameTitle, imageListRef, window.location.pathname])

	const handleDownloadClick = () => {
		if (linkDownload.includes('\n')) {
			setShowDownloadPopup(true)
		} else {
			const updatedCount = downloadCount + 1

			fireDb
				.ref(`vrGames/standalone/${gameTitle}/downloadCount`)
				.set(updatedCount)

			const userUid = user.uid

			fireDb
				.ref(`usersData/downloadHistory/${userUid}/standalone`)
				.push(gameTitle)

			window.open(linkDownload, '_blank')
		}
	}

	const [savingDataMode, setSavingDataMode] = useState(
		localStorage.getItem('dataSaver')
	)

	useEffect(() => {
		localStorage.setItem('dataSaver', savingDataMode)
	}, [savingDataMode])

	const [showDownloadPopup, setShowDownloadPopup] = useState(false)

	const today = new Date()
	today.setHours(23, 59, 59, 999)

	const startOfWeek = new Date(today)
	startOfWeek.setDate(today.getDate() - 6)

	const endOfWeek = new Date(today)
	endOfWeek.setDate(today.getDate())

	useEffect(() => {
		const today = new Date()
		today.setHours(23, 59, 59, 999)

		const startOfWeek = new Date(today)
		startOfWeek.setDate(today.getDate() - 6)

		const endOfWeek = new Date(today)
		endOfWeek.setDate(today.getDate())
	}, [])

	const addedDate = new Date(timeAdded)

	const [reportType, setReportType] = useState('')
	const [customReport, setCustomReport] = useState('')

	const handleReportTypeChange = (event) => {
		setReportType(event.target.value)
	}

	const handleCustomReportChange = (event) => {
		setCustomReport(event.target.value)
	}

	const handleReportSubmit = () => {
		let submittedReport = reportType

		if (reportType === 'custom' && customReport.trim() !== '') {
			submittedReport = customReport
		}

		if (submittedReport) {
			Swal.fire({
				title: translations[language].reportProblem,
				text: translations[language].areYouSureReportProblem,
				icon: 'question',
				showCancelButton: true,
				confirmButtonText: 'Yes',
				cancelButtonText: 'No',
			}).then((result) => {
				if (result.isConfirmed) {
					const newReport = {
						gameTitle: gameTitle,
						gameType: 'standalone',
						requestType: 'report',
						report: submittedReport,
						requestedBy: user.email,
						version: gameVersion,
						status: 'Pending',
						timeRequested: new Date().toISOString(),
					}

					fireDb
						.ref(`requestedVRGames/${gameTitle}`)
						.set(newReport)
						.then(() => {
							setShowReportPopup(false)
							Swal.fire({
								title: translations[language].reportProblemSubmitted,
								text: translations[language].thanksForReportProblem,
								icon: 'success',
								confirmButtonText: 'OK',
							})
						})
						.catch((error) => {
							console.error('Error submitting report:', error.message)
							Swal.fire({
								title: translations[language].reportProblemFailed,
								text: translations[language].somethingWentWrong,
								icon: 'error',
								confirmButtonText: 'OK',
							})
						})
				}
			})
		} else {
			console.error('Please select a report type or provide a custom report.')
			Swal.fire({
				title: translations[language].reportProblemFailed,
				text: translations[language].somethingWentWrong,
				icon: 'error',
				confirmButtonText: 'OK',
			})
		}
	}

	const [isLiked, setIsLiked] = useState(false)

	useEffect(() => {
		const checkLikedGamesStatus = async () => {
			const userLikedGamesRef = fireDb.ref(
				`usersData/likedGames/${user.uid}/${gameTitle}`
			)
			const snapshot = await userLikedGamesRef.once('value')
			const isLiked = snapshot.exists()
			setIsLiked(isLiked)
		}

		if (user) {
			checkLikedGamesStatus()
		}
	}, [user, gameTitle])

	const handleLikedGamesToggle = () => {
		if (isLiked) {
			const userLikedGamesRef = fireDb.ref(
				`usersData/likedGames/${user.uid}/${gameTitle}`
			)
			userLikedGamesRef
				.remove()
				.then(() => {
					const updatedLikedCount = likedCount - 1
					fireDb
						.ref(`vrGames/standalone/${gameTitle}/likedCount`)
						.set(updatedLikedCount)
					setIsLiked(false)
					toast.success(translations[language].removeLiked, {
						duration: 2000,
						position: 'top-center',
					})
				})
				.catch((error) => {
					console.error('Error removing from Liked Games:', error)
					toast.error(translations[language].failedRemoveLiked, {
						duration: 2000,
						position: 'top-center',
					})
				})
		} else {
			const userLikedGamesRef = fireDb.ref(
				`usersData/likedGames/${user.uid}/${gameTitle}`
			)
			userLikedGamesRef
				.set(game)
				.then(() => {
					const updatedLikedCount = likedCount + 1
					fireDb
						.ref(`vrGames/standalone/${gameTitle}/likedCount`)
						.set(updatedLikedCount)
					setIsLiked(true)
					toast.success(translations[language].addLiked, {
						duration: 2000,
						position: 'top-center',
					})
				})
				.catch((error) => {
					console.error('Error adding to Liked Games:', error)
					toast.error(translations[language].failedAddLiked, {
						duration: 2000,
						position: 'top-center',
					})
				})
		}
	}

	const [isPlaying, setIsPlaying] = useState(false)
	const opts = {
		height: '100%',
		width: '100%',
		playerVars: {
			autoplay: 1,
			controls: 0,
			rel: 0,
			modestbranding: 1,
			iv_load_policy: 3,
		},
	}
	const handleVideoEnd = () => {
		setIsPlaying(false) // Mengembalikan ke gambar
	}

	return (
		<>
			{showReportPopup && (
				<div className="fixed mt-[8px] -mb-[8px] inset-0 bg-black bg-opacity-40 flex items-center justify-center z-50 rounded-2xl">
					<motion.div
						className="bg-white p-6 m-4 rounded-xl relative min-w-[300px] max-w-[800px] shadow-lg"
						whileHover={{ scale: 1.0 }}
						initial={{ opacity: 0, y: 30 }}
						animate={{ opacity: 1, y: 0 }}
						transition={{ duration: 0.4, ease: 'easeInOut' }}
					>
						<div className="flex justify-between items-center mb-4">
							<div className="flex flex-col">
								<p className="text-lg font-semibold">
									{translations[language].report}
								</p>
								<p className="text-gray-400">{gameTitle}</p>
							</div>
							<button
								onClick={() => setShowReportPopup(false)}
								className="bg-red-500 w-10 h-10 hover:bg-red-700 text-white p-2 rounded-full flex items-center justify-center text-xl transition-all focus:outline-none absolute top-[-20px] right-[-20px]"
							>
								<FaTimes />
							</button>
						</div>
						<hr className="border-gray-300 mb-4" />
						<div className="flex flex-col gap-2">
							<label className="flex items-center gap-2">
								<input
									type="radio"
									value="file_no_longer"
									checked={reportType === 'file_no_longer'}
									onChange={handleReportTypeChange}
								/>
								<span>{translations[language].fileNoLonger}</span>
							</label>

							<label className="flex items-center gap-2">
								<input
									type="radio"
									value="custom"
									checked={reportType === 'custom'}
									onChange={handleReportTypeChange}
								/>
								<span>Lainnya</span>
							</label>

							{/* Textarea untuk laporan kustom */}
							{reportType === 'custom' && (
								<textarea
									value={customReport}
									onChange={handleCustomReportChange}
									placeholder="Masukkan laporan masalah kustom"
									className="border border-gray-300 rounded-md p-2 w-full"
								/>
							)}
						</div>
						<hr className="border-gray-300 mt-4" />
						<div className="mt-4 flex gap-4 justify-center">
							<button
								onClick={() => setShowReportPopup(false)}
								className="hover:bg-red-500 hover:text-white border-2 text-black px-4 py-2 rounded-xl"
							>
								{translations[language].cancel}
							</button>
							<button
								onClick={handleReportSubmit}
								className="bg-blue-500 hover:bg-blue-600 text-white px-4 py-2 rounded-xl"
							>
								{translations[language].submit}
							</button>
						</div>
					</motion.div>
				</div>
			)}

			<motion.div
				className={`flex flex-row mt-2 w-full h-full rounded-2xl border-2 bg-white`}
				whileHover={{ scale: 1.0 }}
				initial={{ opacity: 0, y: 30 }}
				animate={{ opacity: 1, y: 0 }}
				transition={{ duration: 0.4, ease: 'easeInOut' }}
			>
				<Toaster />

				<div className="flex flex-col w-full h-full">
					<div className="px-4 font-semibold flex flex-row justify-between items-center gap-2 w-full bg-white rounded-t-2xl py-2.5 h-[84px]">
						<span className="flex flex-row items-center gap-2">
							{gameTitle}
							<div className="font-semibold">
								{addedDate >= startOfWeek &&
									addedDate <= endOfWeek &&
									gameStatus === 'new' && (
										<div className="text-nowrap px-2.5 py-1 bg-blue-500 text-white text-center rounded-full text-sm animate-pulse">
											{translations[language].newAdded}
										</div>
									)}
								{addedDate >= startOfWeek &&
									addedDate <= endOfWeek &&
									gameStatus === 'update' && (
										<div className="text-nowrap px-2.5 py-1 bg-yellow-500 text-white text-center rounded-full text-sm animate-pulse">
											{translations[language].update}
										</div>
									)}
								{gameStatus === 'soon' && (
									<div className="text-nowrap px-2.5 py-1 bg-green-500 text-white text-center rounded-full text-sm animate-pulse">
										{translations[language].comingSoon}
									</div>
								)}
							</div>
						</span>
						<span
							className={`h-full flex flex-row flex-nowrap gap-2 items-center ${isLiked ? '' : 'rounded-xl hover:text-red-500'}`}
						>
							<button onClick={handleLikedGamesToggle}>
								{isLiked ? (
									<FaHeart className="text-red-500 outline-none" size={20} />
								) : (
									<FaRegHeart className="outline-none" size={20} />
								)}
							</button>
							<span className={`${isLiked ? 'text-red-500' : ''}`}>
								{formatLikedGamesCount(likedCount)}
							</span>
						</span>
					</div>
					<span
						className="relative w-full px-4"
						onContextMenu={(e) => e.preventDefault()}
					>
						{loadingImages && !gameBackgroundUrl ? (
							<div className="loading-overlay h-48 bg-zinc-100 w-full">
								<div className="spinner"></div>
							</div>
						) : (
							<div className="relative">
								<LazyLoadImage
									src={gameBackgroundUrl}
									alt={gameTitle}
									className="w-full object-cover h-48 rounded-2xl"
								/>
							</div>
						)}
					</span>

					<div className="w-full h-full min-w-[320px]">
						<div className="px-5 pt-2 flex flex-col gap-1 h-full">
							{game && layout && (
								<div className="mt-1 flex flex-col gap-2 w-full">
									<div className="flex flex-wrap gap-1.5 items-center w-full">
										{Object.entries(game).map(
											([quest, supported], index, array) => {
												if (quest.startsWith('supportMetaQuest')) {
													return (
														<ul
															key={quest}
															className={`text-sm px-2 text-nowrap py-1 flex flex-row rounded-full ${
																supported
																	? 'bg-[#0081FB] text-white font-semibold'
																	: 'bg-zinc-200 text-zinc-400'
															}`}
														>
															<li>
																{quest.replace('supportMetaQuest', 'Quest ')}
															</li>
														</ul>
													)
												}
												return null
											}
										)}
									</div>
									
									{gameVersion && (
										<span className="flex items-center gap-2 text-sm font-semibold">
											<span className="py-1.5 px-1.5 rounded-full bg-gradient-to-r from-yellow-400 to-yellow-600 text-white">
												<FaInfoCircle className="w-4 h-4" />
											</span>
											<span className="text-yellow-500">
												{gameVersion.replace('Mixed Reality', 'MR')}
											</span>
										</span>
									)}

									<span className="flex items-center gap-2 text-sm font-semibold">
										<span className="py-1.5 px-1.5 rounded-full bg-gradient-to-r from-green-400 to-green-600 text-white">
											<MdFileDownload className="w-4 h-4" />
										</span>
										<span className="text-green-500">
											{formatDownloadCount(downloadCount)}{' '}
											{translations[language].downloaded}
										</span>
									</span>

									{gameSize && (
										<span className="flex items-center gap-2 text-sm font-semibold">
											<span className="py-1.5 px-1.5 rounded-full bg-gradient-to-r from-cyan-400 to-cyan-600 text-white">
												<MdSdStorage className="w-4 h-4" />
											</span>
											<span className="text-cyan-500">{gameSize}</span>
										</span>
									)}
								</div>
							)}
						</div>
					</div>

					{user && showAnnouncement === false && (
						<>
							<div className="flex flex-row items-center justify-end w-full h-20 p-4">
								{gameStatus !== 'soon' ? (
									<div className="flex items-center justify-center w-full h-full gap-2">
										<button
											onClick={handleDownloadClick}
											className={`px-4 h-full bg-[#0081FB] hover:bg-blue-600 transition-colors w-full py-2 duration-300 flex items-center gap-2 justify-center font-semibold text-white rounded-xl`}
										>
											<span>{translations[language].download}</span>
											<FaDownload />
										</button>

										<button
											onClick={handleUpdateGameClick}
											className={`px-4 w-fit h-full disabled:border-zinc-300 disabled:text-zinc-300 border-2 border-yellow-500 disabled:cursor-not-allowed hover:bg-yellow-500 transition-colors py-2 duration-300 flex items-center gap-2 justify-center font-semibold text-yellow-500 hover:text-white rounded-xl`}
										>
											Update
											<MdUpdate />
										</button>

										<button
											onClick={() => setShowReportPopup(true)}
											className={`px-4 w-fit h-full disabled:border-zinc-300 disabled:text-zinc-300 border-2 border-red-500 disabled:cursor-not-allowed hover:bg-red-500 transition-colors py-2 duration-300 flex items-center gap-2 justify-center font-semibold text-red-500 hover:text-white rounded-xl`}
										>
											{translations[language].report}
											<FaExclamationTriangle />
										</button>
									</div>
								) : isGameRequested ? (
									<button
										className="bg-gradient-to-br from-gray-200 to-gray-400 h-full px-4 text-center text-gray-700 cursor-not-allowed transition-colors w-full py-2 duration-300 flex items-center gap-2 justify-center font-semibold rounded-xl"
										disabled
									>
										<span>{translations[language].requested}</span>
										<FaCheck />
									</button>
								) : (
									<button
										className="bg-gradient-to-br from-green-400 to-green-600 hover:from-green-500 hover:to-green-700 h-full px-4 text-center text-white transition-colors w-full py-2 duration-300 flex items-center gap-2 justify-center font-semibold rounded-xl"
										onClick={handleRequestGameClick}
									>
										<span>{translations[language].requestGame}</span>
										<FaPlus />
									</button>
								)}
							</div>

							{showDownloadPopup && (
								<div className="fixed top-0 left-0 w-svw h-full bg-black bg-opacity-70 flex items-center justify-center -ml-2 z-50 backdrop-blur-sm">
									<div className="bg-white p-4 m-4 rounded-xl relative min-w-[300px] max-w-[800px]">
										<div className="flex justify-between items-center mb-3">
											<span className="flex flex-col gap-2">
												<p className="text-xl font-semibold text-left">
													{gameTitle}
												</p>
												<p className="text-sm text-left text-red-500">
													{translations[language].requireToDownloadAllParts}
												</p>
											</span>
											<div className="absolute top-0 right-0 justify-center flex gap-3 mt-[-20px] mr-[-20px]">
												<button
													onClick={() => setShowDownloadPopup(false)}
													className="bg-red-500 w-12 h-12 hover:bg-red-700 text-white p-3 rounded-full flex items-center justify-center text-xl transition-all focus:outline-none"
												>
													<FaTimes />
												</button>
											</div>
										</div>
										<hr className="border-gray-300 mb-4" />
										{linkDownload.split('\n').map((downloadLink, index) => (
											<div key={index} className="mb-2 w-full">
												<a
													href={downloadLink}
													target="_blank"
													rel="noopener noreferrer"
													onClick={() => {
														const updatedCount = downloadCount + 1
														fireDb
															.ref(
																`vrGames/standalone/${gameTitle}/downloadCount`
															)
															.set(updatedCount)
													}}
													className={`border-2 rounded-xl p-2 text-sm w-full hover:bg-[#0881FB] hover:text-white transition-colors flex items-center gap-2 justify-center`}
												>
													<span>Part {index + 1}</span>
													<FaDownload />
												</a>
											</div>
										))}
									</div>
								</div>
							)}
						</>
					)}

					{user && showAnnouncement && (
						<div className="flex flex-row items-center justify-start w-full h-16 p-2">
							{linkDownload !== '' ? (
								<div className="flex items-center w-full h-full">
									<a
										className={`border-t-2 border-white p-3 h-full text-sm cursor-not-allowed bg-red-500 hover:bg-red-600 transition-colors w-full py-3 duration-300 flex items-center gap-2 justify-center font-semibold text-white text-center rounded-xl`}
										disabled
									>
										<span>{translations[language].readyButNotEligible}</span>
									</a>
								</div>
							) : isGameRequested ? (
								<button
									className="bg-gradient-to-br from-gray-200 to-gray-400 h-full px-4 text-center text-gray-700 cursor-not-allowed transition-colors w-full py-2 duration-300 flex items-center gap-2 justify-center font-semibold rounded-xl"
									disabled
								>
									<span>{translations[language].requested}</span>
									<FaCheck />
								</button>
							) : (
								<button
									className="bg-gradient-to-br from-green-400 to-green-600 hover:from-green-500 hover:to-green-700 h-full px-4 text-center text-white transition-colors w-full py-2 duration-300 flex items-center gap-2 justify-center font-semibold rounded-xl"
									onClick={handleRequestGameClick}
								>
									<span>{translations[language].requestGame}</span>
									<FaPlus />
								</button>
							)}
						</div>
					)}
				</div>

				<style>
					{`
        .loading-overlay {
          width: 100%;
          height: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
        }
        
        .spinner {
          border: 4px solid rgba(0, 0, 0, 0.3); /* warna putih dengan transparansi */
          border-top: 4px solid #000000; /* warna putih */
          border-radius: 50%;
          width: 40px;
          height: 40px;
          animation: spin 1s linear infinite;
        }
        
        @keyframes spin {
          0% { transform: rotate(0deg); }
          100% { transform: rotate(360deg); }
        }
        `}
				</style>
			</motion.div>
		</>
	)
}

export default ListGamesCard_Standalone
