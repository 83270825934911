import React, { useState, useEffect } from 'react'
import fireDb from '../../firebase'
import { Helmet } from 'react-helmet'
import '../Admin/Home.css'
import {
	FaAngleDoubleRight,
	FaEdit,
	FaExternalLinkAlt,
	FaHourglassHalf,
	FaPlus,
	FaSortAlphaDown,
	FaSortAlphaDownAlt,
	FaSortNumericDown,
	FaSortNumericDownAlt,
	FaTrashAlt,
} from 'react-icons/fa'
import Swal from 'sweetalert2'
import { useDarkModeContext } from '../../DarkModeContext'
import { auth } from '../../firebase'
import {
	MdCancel,
	MdCheckCircle,
	MdLabel,
	MdLabelImportant,
	MdNewLabel,
	MdPending,
	MdReportProblem,
} from 'react-icons/md'
import { FiSearch, FiX } from 'react-icons/fi'
import { motion } from 'framer-motion'
import bgPage from '../../assets/images/bg.jpg'

const StatusFilterOptions = [
	{ label: 'Menunggu', value: 'Pending', color: 'bg-orange-500' },
	{ label: 'Proses', value: 'Process', color: 'bg-blue-500' },
	{ label: 'Selesai', value: 'Done', color: 'bg-green-500' },
	{ label: 'Dibatalkan', value: 'Canceled', color: 'bg-red-500' },
	{ label: 'Semua', value: 'All', color: 'bg-gray-500' },
]

const RequestTypeOptions = [
	{ label: 'Pembaruan', value: 'update', color: 'bg-yellow-500' },
	{ label: 'Baru', value: 'new', color: 'bg-blue-500' },
	{ label: 'Laporan', value: 'report', color: 'bg-red-500' },
	{ label: 'Semua', value: 'All', color: 'bg-gray-500' },
]

const translations = {
	en: {
		filterBy: 'Filter By',
		info: 'Info',
		requestGamesInfo:
			'This page contains the list of requested VR games. You can view, edit, or delete the games that you have requested.',
		vrGames: 'VR Games',
		searchGames: 'Search Games',
		sortBy: 'Sort by:',
		name: 'Name',
		newest: 'Newest',
		title: 'List Request VR Games',
		requestGame: 'Game Request',
		no: 'No',
		gameTitle: 'Game Title',
		version: 'Version',
		gameType: 'Game Type',
		metaStoreLink: 'Meta Store Link',
		requestedBy: 'Requested By',
		shortcutLink: 'Shortcut Link',
		viewGame: 'View',
		status: 'Status',
		reason: 'Reason',
		timeRequested: 'Time Requested',
		timeProcessed: 'Time Processed',
		action: 'Action',
		addRequestVRGames: 'Request VR Games',
		edit: 'Edit',
		delete: 'Delete',
		areYouSure: 'Are you sure that you want to delete this requested game?',
		gameDeleted: 'Game Deleted Successfully!',
		errorSomething: 'Something went wrong! Please try again later.',

		confirmButtonText: 'Yes, delete it!',
		cancelButtonText: 'Cancel',

		titleOnDelete: 'Are you sure?',
		textOnDelete: "You won't be able to revert this!",

		noData: 'No games requested yet!',
		noPermission: "You don't have permission to edit this",

		loginFirst: 'Login first to use this feature!',

		canceled: 'Canceled by Admin',
		pending: 'Pending',
		process: 'Process',
		done: 'Done',
	},
	id: {
		filterBy: 'Filter Berdasarkan',
		info: 'Info',
		requestGamesInfo:
			'Halaman ini berisi daftar game VR yang direquest. Kamu bisa melihat, mengedit, atau menghapus game yang sudah kamu request.',
		vrGames: 'Game VR',
		searchGames: 'Cari Game',
		sortBy: 'Urutkan: ',
		name: 'Nama',
		newest: 'Terbaru',
		title: 'Daftar Request VR Games',
		requestGame: 'Request Game',
		no: 'No',
		gameTitle: 'Judul Game',
		version: 'Versi',
		gameType: 'Tipe Game',
		metaStoreLink: 'Link Meta Store',
		requestedBy: 'Dibuat Oleh',
		shortcutLink: 'Link Pintasan',
		viewGame: 'Lihat',
		status: 'Status',
		reason: 'Alasan',
		timeRequested: 'Waktu Request',
		timeProcessed: 'Waktu Diproses',
		action: 'Aksi',
		addRequestVRGames: 'Request Game VR',
		edit: 'Edit',
		delete: 'Hapus',
		areYouSure: 'Apakah kamu yakin ingin menghapus game yang direquest ini?',
		gameDeleted: 'Game Berhasil Dihapus!',
		errorSomething: 'Terjadi kesalahan! Silakan coba lagi nanti.',

		confirmButtonText: 'Ya, hapus!',
		cancelButtonText: 'Batal',

		titleOnDelete: 'Apakah kamu yakin?',
		textOnDelete: 'Kamu tidak akan bisa mengembalikan ini!',

		noData: 'Tidak ada game yang direquest!',
		noPermission: 'Kamu tidak bisa edit ini',

		loginFirst: 'Login terlebih dahulu untuk menggunakan fitur ini!',

		canceled: 'Batal',
		pending: 'Menunggu',
		process: 'Proses',
		done: 'Selesai',
	},
}

const Card = ({
	gameTitle,
	gameType,
	status,
	timeProcessed,
	onEdit,
	onDelete,
	requestedBy,
	reason,
	report,
	previousVersion,
	newVersion,
	version,
	requestType,
}) => {
	const [user, setUser] = useState(null)
	const language = localStorage.getItem('language') || 'id'

	const translations = {
		en: {
			pending: 'Pending',
			inProcess: 'In Process',
			done: 'Completed',
			canceled: 'Canceled',
			edit: 'Edit',
			delete: 'Delete',
			view: 'View',
			status: 'Status:',
			timeProcessed: 'Time Processed:',
			reason: 'Reason:',
			version: 'Version:',
			updateRequest: 'Request Update',
			newRequest: 'New Game Request',
			reportRequest: 'Report Issue',
			report: 'Report:',
		},
		id: {
			pending: 'Menunggu',
			inProcess: 'Proses',
			done: 'Selesai',
			canceled: 'Dibatalkan',
			edit: 'Edit',
			delete: 'Hapus',
			view: 'Lihat',
			status: 'Status:',
			timeProcessed: 'Waktu Diproses:',
			reason: 'Alasan:',
			version: 'Versi:',
			updateRequest: 'Permintaan Update',
			newRequest: 'Permintaan Game Baru',
			reportRequest: 'Laporan Masalah',
			report: 'Laporan:',
		},
	}

	useEffect(() => {
		const unsubscribe = auth.onAuthStateChanged((user) => {
			if (user) {
				setUser(user)
			} else {
				setUser(null)
			}
		})
		return () => unsubscribe()
	}, [])

	return (
		<motion.div
			className="w-full h-full mx-auto bg-white rounded-2xl shadow-md border border-gray-200 overflow-hidden flex flex-col justify-between"
			whileHover={{ scale: 1.0 }}
			initial={{ opacity: 0, y: 30 }}
			animate={{ opacity: 1, y: 0 }}
			transition={{ duration: 0.4, ease: 'easeOut' }}
		>
			{/* Header */}
			<div className="p-5 pt-12 bg-white flex items-center justify-between border-b border-gray-200 relative">
				<div>
					{/* Tambahkan tag label di sini */}
					<span
						className={`absolute flex flex-row gap-1.5 items-center top-0 left-0 px-4 py-1.5 rounded-br-2xl text-[14px] font-semibold 
    ${
			requestType === 'update'
				? 'bg-yellow-100 text-yellow-500'
				: requestType === 'new'
					? 'bg-blue-100 text-blue-500'
					: requestType === 'report'
						? 'bg-red-100 text-red-500'
						: ''
		}`}
					>
						{requestType === 'update' && <MdLabelImportant />}
						{requestType === 'new' && <MdNewLabel />}
						{requestType === 'report' && <MdReportProblem />}

						<p>
							{requestType === 'update'
								? translations[language].updateRequest
								: requestType === 'new'
									? translations[language].newRequest
									: requestType === 'report'
										? translations[language].reportRequest
										: ''}
						</p>
					</span>

					<h3 className="text-xl font-bold text-gray-800">{gameTitle}</h3>
					<p className="text-sm text-gray-500">
						{gameType === 'standalone' ? 'Standalone VR' : 'PC VR'}
					</p>
				</div>
				{/* Status Icon */}
				<div>
					{status === 'Pending' && (
						<MdPending className="text-orange-400 text-3xl" />
					)}
					{status === 'Process' && (
						<FaHourglassHalf className="text-blue-400 text-3xl" />
					)}
					{status === 'Done' && (
						<MdCheckCircle className="text-green-400 text-3xl" />
					)}
					{status === 'Canceled' && (
						<MdCancel className="text-red-400 text-3xl" />
					)}
				</div>
			</div>

			{/* Content */}
			<div className="p-5 flex flex-col space-y-3 h-full">
				<div className="flex justify-between text-gray-600">
					<p>{translations[language].status}</p>
					<p
						className={`font-medium ${
							status === 'Pending'
								? 'text-orange-500'
								: status === 'Process'
									? 'text-blue-500'
									: status === 'Done'
										? 'text-green-500'
										: 'text-red-500'
						}`}
					>
						{status === 'Pending'
							? translations[language].pending
							: status === 'Process'
								? translations[language].inProcess
								: status === 'Done'
									? translations[language].done
									: translations[language].canceled}
					</p>
				</div>

				<div className="flex justify-between text-gray-600">
					<p>{translations[language].timeProcessed}</p>
					<p className="text-gray-500">
						{timeProcessed
							? new Date(timeProcessed).toLocaleDateString(
									language === 'en' ? 'en-US' : 'id-ID'
								)
							: '-'}
					</p>
				</div>

				<div className="flex justify-between text-gray-600">
					<p>{translations[language].reason}</p>
					<p className="text-gray-500 max-w-64 text-right">
						{reason ? reason : '-'}
					</p>
				</div>

				{requestType === 'update' && status !== 'Done' ? (
					<div className="flex items-center justify-center text-yellow-500 border border-yellow-500 bg-yellow-50 rounded-lg py-2 px-4">
						<div className="text-right">
							<p className="text-sm font-medium">
								{translations[language].previousVersion}
							</p>
							<p className="text-yellow-500 font-semibold">
								{previousVersion || '-'}
							</p>
						</div>

						<div className="mx-4 text-yellow-500">
							<FaAngleDoubleRight className="text-2xl" />
						</div>

						<div className="text-left">
							<p className="text-sm font-medium">
								{translations[language].newVersion}
							</p>
							<p className="text-yellow-500 font-semibold">
								{newVersion || '-'}
							</p>
						</div>
					</div>
				) : (
					<div className="flex justify-between text-gray-600">
						<p>{translations[language].version}</p>
						<p className="text-gray-500 max-w-64 text-right">
							{version ? version : '-'}
						</p>
					</div>
				)}

				{requestType === 'report' && status !== 'Done' && (
					<div className="flex items-center justify-center text-red-500 border border-red-500 bg-red-50 rounded-lg py-2 px-4">
						<p className="text-red-500 font-semibold w-full px-4 text-center">
							{report
								? report === 'file_no_longer'
									? language === 'en'
										? 'File No Longer Available / Link Error / File Missing'
										: 'File tidak lagi tersedia / Link Error / File Hilang'
									: report === 'custom'
										? language === 'en'
											? 'Custom Report'
											: 'Laporan Kustom'
										: report
								: '-'}
						</p>
					</div>
				)}
			</div>

			{/* Actions */}
			<div className="border-t border-gray-200 flex justify-between p-4 bg-gray-50">
				{user &&
					(user.email === requestedBy ||
						user.email === 'hypertopiaid@gmail.com') && (
						<>
							<motion.button
								className="flex items-center text-blue-500 hover:text-blue-600"
								whileHover={{ scale: 1.1 }}
								onClick={onEdit}
							>
								<FaEdit className="mr-2" />
								<span>{translations[language].edit}</span>
							</motion.button>

							<motion.button
								className="flex items-center text-red-500 hover:text-red-600"
								whileHover={{ scale: 1.1 }}
								onClick={onDelete}
							>
								<FaTrashAlt className="mr-2" />
								<span>{translations[language].delete}</span>
							</motion.button>
						</>
					)}
				{status === 'Done' && (
					<motion.a
						href={`/vr-games/list-games/${gameType}?keyword=${gameTitle}`}
						className="flex items-center text-green-500 hover:text-green-600"
						whileHover={{ scale: 1.1 }}
					>
						<FaExternalLinkAlt className="mr-2" />
						<span>{translations[language].view}</span>
					</motion.a>
				)}
			</div>
		</motion.div>
	)
}

const ListRequests_Admin = () => {
	const [data, setData] = useState({})
	const language = localStorage.getItem('language') || 'id'
	const { isDarkMode } = useDarkModeContext()
	const [user, setUser] = useState(null)

	const [sortBy, setSortBy] = useState('requested')
	const [sortOrder, setSortOrder] = useState('desc')

	const handleSortByChange = (event) => {
		setSortBy(event.target.value)
	}

	const toggleSortOrder = () => {
		setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc')
	}

	useEffect(() => {
		fireDb.ref(`requestedVRGames`).on('value', (snapshot) => {
			if (snapshot.val() !== null) {
				setData({ ...snapshot.val() })
			} else {
				setData({})
			}
		})

		return () => {
			setData({})
		}
	}, [])

	useEffect(() => {
		const unsubscribe = auth.onAuthStateChanged((user) => {
			if (user) {
				setUser(user)
			} else {
				setUser(null)
			}
		})
		return () => unsubscribe()
	}, [])

	const onDelete = (gameTitle) => {
		Swal.fire({
			title: translations[language].titleOnDelete,
			text: translations[language].textOnDelete,
			icon: 'warning',
			showCancelButton: true,
			confirmButtonColor: '#3085d6',
			cancelButtonColor: '#d33',
			confirmButtonText: translations[language].confirmButtonText,
			cancelButtonText: translations[language].cancelButtonText,
		}).then((result) => {
			if (result.isConfirmed) {
				fireDb.ref(`requestedVRGames/${gameTitle}`).remove((err) => {
					if (err) {
						Swal.fire({
							icon: 'error',
							title: 'Oops...',
							text: translations[language].errorSomething,
						})
					} else {
						Swal.fire({
							icon: 'success',
							title: 'Success!',
							text: translations[language].gameDeleted,
						})
					}
				})
			}
		})
	}

	const handleAddRequestGamesButton = () => {
		window.location.href = '/vr-games/request-games/add'
	}

	const handleUpdateRequestGamesButton = (gameTitle) => {
		window.location.href = `/vr-games/request-games/update/${gameTitle}`
	}

	const [searchTerm, setSearchTerm] = useState('')

	const handleSearchChange = (event) => {
		setSearchTerm(event.target.value)
	}

	const handleSearchBarNonActiveClick = () => {
		setSearchTerm('')
	}

	const [filterType, setFilterType] = useState(null)
	const [statusFilter, setStatusFilter] = useState('All')
	const [requestTypeFilter, setRequestTypeFilter] = useState('All')

	const handleCloseFilter = () => setFilterType(null)

	const sortedData = Object.keys(data).sort((a, b) => {
		if (sortBy === 'name') {
			return sortOrder === 'asc'
				? data[a].gameTitle.localeCompare(data[b].gameTitle)
				: data[b].gameTitle.localeCompare(data[a].gameTitle)
		} else if (sortBy === 'requested') {
			return sortOrder === 'asc'
				? new Date(data[a].timeRequested) - new Date(data[b].timeRequested)
				: new Date(data[b].timeRequested) - new Date(data[a].timeRequested)
		}
		return 0
	})

	const filteredSortedData = sortedData.filter((gameTitle) => {
		const game = data[gameTitle]
		const matchesSearch = game.gameTitle
			.toLowerCase()
			.includes(searchTerm.toLowerCase())
		const matchesStatus = statusFilter === 'All' || game.status === statusFilter
		const matchesRequestType =
			requestTypeFilter === 'All' || game.requestType === requestTypeFilter
		return matchesSearch && matchesStatus && matchesRequestType
	})

	const [isMobile, setIsMobile] = useState(window.innerWidth < 1200)

	useEffect(() => {
		const handleResize = () => {
			setIsMobile(window.innerWidth < 1200)
		}

		window.addEventListener('resize', handleResize)

		return () => {
			window.removeEventListener('resize', handleResize)
		}
	}, [])

	const containerVariants = {
		hidden: { opacity: 0, scale: 0.9 },
		visible: {
			opacity: 1,
			scale: 1,
			transition: {
				staggerChildren: 0.2,
			},
		},
	}

	const cardVariants = {
		hidden: { opacity: 0, y: 20 },
		visible: { opacity: 1, y: 0 },
	}

	return (
		<div className={`min-h-screen w-full ${!isMobile && 'pt-[75px]'}`}>
			<header
				className="bg-gradient-to-r from-[#0881FB] to-[#0059b2] py-12 text-center text-white px-6 sm:px-10"
				style={{
					background: `linear-gradient(to left, rgba(8, 129, 251, 0.95) 10%, rgba(0, 89, 178, 0.95) 100%), url(${bgPage}) center/cover`,
				}}
			>
				<h1 className="text-3xl md:text-4xl font-bold tracking-wide">
					Request VR Games
				</h1>
				<p className="text-base md:text-lg mt-4">
					{translations[language].requestGamesInfo}
				</p>
			</header>
			<div className="flex flex-col w-full pt-5 px-5 pb-5">
				{/* Tombol Add Request Games */}
				{user && (
					<button
						onClick={handleAddRequestGamesButton}
						className="text-center w-full py-2 mb-5 rounded-lg text-white font-semibold bg-[#0081FB] hover:bg-[#4083c2] transition-all duration-300"
					>
						<FaPlus className="inline-block mr-2" />
						<span>{translations[language].addRequestVRGames}</span>
					</button>
				)}

				{/* Notifikasi Login */}
				{!user && (
					<div className="p-4 bg-gradient-to-r from-red-50 to-red-100 border-2 border-red-300 rounded-xl flex justify-center text-center mb-4">
						<p>
							<span className="px-3 py-1 rounded-full bg-gradient-to-r from-red-300 to-red-500 text-white font-semibold text-center">
								{translations[language].info}
							</span>
							<span className="text-red-500 font-semibold ml-2">
								{translations[language].loginFirst}
							</span>
						</p>
					</div>
				)}

				{/* Pencarian dan Sort */}
				<div className="flex flex-row gap-2 items-center justify-center">
					<div className="relative w-full">
						<input
							type="text"
							placeholder={translations[language].searchGames}
							value={searchTerm}
							onChange={handleSearchChange}
							className="p-2 pl-4 border text-sm border-gray-300 rounded-xl outline-[#0081FB] w-full h-10 mb-4 pr-10 placeholder:text-zinc-800"
						/>
						{searchTerm && (
							<button
								onClick={handleSearchBarNonActiveClick}
								className="absolute top-0 right-0 mr-3 mt-2 text-red-500 hover:text-red-700 cursor-pointer"
							>
								<FiX size={26} />
							</button>
						)}
						{!searchTerm && (
							<span className="absolute top-0 right-0 mr-3 mt-2 text-gray-500">
								<FiSearch size={23} />
							</span>
						)}
					</div>

					<div className="flex items-center gap-2 mb-2">
						<span
							className={`font-semibold ${isDarkMode ? 'text-white' : 'text-black'} text-nowrap`}
						>
							{translations[language].sortBy}
						</span>
						<select
							onChange={handleSortByChange}
							value={sortBy}
							className="bg-gray-200 rounded px-2 py-1 w-fit outline-none cursor-pointer hover:bg-gray-300 transition-all duration-300"
						>
							<option value="name">{translations[language].name}</option>
							<option value="requested">{translations[language].newest}</option>
						</select>
						<button
							onClick={toggleSortOrder}
							className="bg-gray-200 p-2 rounded hover:bg-gray-300 transition-all duration-300"
						>
							{sortBy === 'name' ? (
								sortOrder === 'asc' ? (
									<FaSortAlphaDown />
								) : (
									<FaSortAlphaDownAlt />
								)
							) : sortOrder === 'asc' ? (
								<FaSortNumericDown />
							) : (
								<FaSortNumericDownAlt />
							)}
						</button>
					</div>
				</div>

				{/* List Game Card dengan animasi */}
				<div className="w-full flex flex-col md:flex-row items-center justify-center mb-6 space-y-2 md:space-y-0 h-auto md:h-12 px-4">
					{!filterType ? (
						<div className="flex flex-wrap justify-center md:justify-start space-x-0 md:space-x-4 space-y-2 md:space-y-0">
							{/* Main filter buttons */}
							<button
								className="px-4 py-2 bg-white hover:bg-blue-500 hover:text-white border-2 hover:border-0 text-black rounded-full transition-all duration-300 w-full md:w-auto"
								onClick={() => setFilterType('status')}
							>
								Berdasarkan Status
							</button>
							<button
								className="px-4 py-2 bg-white hover:bg-blue-500 hover:text-white border-2 hover:border-0 text-black rounded-full transition-all duration-300 w-full md:w-auto"
								onClick={() => setFilterType('requestType')}
							>
								Berdasarkan Tipe Request
							</button>
						</div>
					) : (
						<div className="flex flex-col md:flex-row items-center space-y-2 md:space-y-0 space-x-0 md:space-x-2 justify-center w-full">
							{filterType === 'status' ? (
								<button
									className="px-4 py-2 bg-blue-500 text-white rounded-full w-full md:w-auto"
									onClick={() => setFilterType('status')}
									disabled
								>
									Berdasarkan Status
								</button>
							) : (
								<button
									className="px-4 py-2 bg-blue-500 text-white rounded-full w-full md:w-auto"
									onClick={() => setFilterType('requestType')}
									disabled
								>
									Berdasarkan Tipe Request
								</button>
							)}
							<div className="hidden md:block">
								<FaAngleDoubleRight className="text-blue-500 mx-2" />
							</div>
							{/* Filter options */}
							<div className="flex flex-wrap justify-center md:justify-start space-x-0 md:space-x-2 space-y-2 md:space-y-0">
								{(filterType === 'status'
									? StatusFilterOptions
									: RequestTypeOptions
								).map((option) => (
									<button
										key={option.value}
										className={`px-4 py-2 rounded-full w-full md:w-auto ${
											(filterType === 'status' &&
												statusFilter === option.value) ||
											(filterType === 'requestType' &&
												requestTypeFilter === option.value)
												? `${option.color} text-white`
												: 'bg-gray-200 text-gray-700'
										}`}
										onClick={() =>
											filterType === 'status'
												? setStatusFilter(option.value)
												: setRequestTypeFilter(option.value)
										}
									>
										{option.label}
									</button>
								))}
							</div>
							{/* Close filter button */}
							<button
								className="p-2 bg-red-500 text-white rounded-full flex items-center justify-center mt-2 md:mt-0"
								onClick={handleCloseFilter}
							>
								<FiX size={20} />
							</button>
						</div>
					)}
				</div>

				<motion.div
					className="grid grid-cols-1 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-6 w-full"
					variants={containerVariants}
					initial="hidden"
					animate="visible"
				>
					{filteredSortedData.length > 0 ? (
						filteredSortedData.map((gameTitle, index) => (
							<motion.div
								key={gameTitle}
								variants={cardVariants}
								transition={{ duration: 0.4 }}
							>
								<Card
									key={gameTitle}
									index={index}
									gameTitle={data[gameTitle].gameTitle}
									requestedBy={data[gameTitle].requestedBy}
									version={data[gameTitle].version}
									previousVersion={data[gameTitle].previousVersion}
									newVersion={data[gameTitle].newVersion}
									gameType={data[gameTitle].gameType}
									status={data[gameTitle].status}
									report={data[gameTitle].report}
									reason={data[gameTitle].reason}
									timeProcessed={data[gameTitle].timeProcessed}
									requestType={data[gameTitle].requestType}
									onDelete={() => onDelete(gameTitle)}
									onEdit={() => handleUpdateRequestGamesButton(gameTitle)}
								/>
							</motion.div>
						))
					) : (
						<p className="text-center text-gray-600 w-full flex items-center">
							{translations[language].noData}
						</p>
					)}
				</motion.div>
			</div>

			{/* Styles and Helmet */}
			<Helmet>
				<title>
					{translations[language].requestGame} |{' '}
					{translations[language].vrGames}- HyperTopia
				</title>
			</Helmet>
		</div>
	)
}

export default ListRequests_Admin
