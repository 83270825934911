import React, { useState, useEffect } from 'react';
import { saveAs } from 'file-saver';

// Fungsi untuk mengambil data mod
const fetchModsData = async (version) => {
  const response = await fetch(`https://raw.githubusercontent.com/QuestPackageManager/bs-coremods/main/core_mods.json?${new Date().getTime()}`);
  const data = await response.json();
  return data[version] || {};
};

const generateQmod = async (modData) => {
  // Persiapkan metadata mod
  const modMetadata = {
    name: `Core mods for ${modData.version}`,
    id: `CoreMods_${modData.version}`,
    author: "QuestPackageManager",
    version: "1.0.0",
    packageId: "com.beatgames.beatsaber",
    dependencies: modData.mods.map(mod => ({
      id: mod.id,
      version: `^${mod.version}`,
      downloadIfMissing: mod.downloadLink
    }))
  };

  // Buat file .qmod (misalnya menggunakan library atau custom logic)
  const qmodFile = await createQmodFile(modMetadata); // Gantilah dengan logika untuk membuat file qmod

  return new Blob([qmodFile], { type: 'application/qmod' });
};

// Fungsi untuk membuat file .qmod (kamu bisa sesuaikan dengan logika pembuatan qmod)
const createQmodFile = async (metadata) => {
  // Ini adalah tempat untuk logika membuat file qmod
  // Biasanya ini melibatkan konversi metadata menjadi file .qmod
  // Kembalikan metadata sebagai contoh sementara
  return JSON.stringify(metadata);
};

const ModDownloader = () => {
  const [version, setVersion] = useState('');
  const [modData, setModData] = useState(null);

  // Ambil data mod ketika versi diubah
  useEffect(() => {
    if (version) {
      fetchModsData(version).then(data => setModData(data));
    }
  }, [version]);

  // Tangani pengunduhan file .qmod
  const handleDownload = async () => {
    if (modData) {
      const qmodBlob = await generateQmod(modData);
      saveAs(qmodBlob, `Mods_${modData.version}.qmod`);
    }
  };

  return (
    <div className="App">
      <h1 className="text-4xl font-bold mb-8">Download Mods in .qmod Format</h1>
      <div className="flex flex-col space-y-4">
        <select
          value={version}
          onChange={(e) => setVersion(e.target.value)}
          className="px-4 py-2 rounded-md bg-gray-700 text-white"
        >
          <option value="">Select Version</option>
          <option value="1.13.0">1.13.0</option>
          <option value="1.13.2">1.13.2</option>
          <option value="1.37.0_9064817954">1.37.0_9064817954</option>
        </select>

        <button
          onClick={handleDownload}
          disabled={!modData}
          className="px-6 py-2 bg-blue-500 text-white rounded-lg hover:bg-blue-400 transition"
        >
          Download .qmod
        </button>
      </div>
    </div>
  );
};

export default ModDownloader;
