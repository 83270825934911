import React, { useState, useEffect } from 'react'
import firebase from '../../firebase'
import toast, { Toaster } from 'react-hot-toast'
import { FaPencil, FaPlus, FaTrash } from 'react-icons/fa6'
import { Helmet } from 'react-helmet'
import { auth } from '../../firebase'
import { motion } from 'framer-motion'
import bgPage from '../../assets/images/bg.jpg'

const storedLanguage = localStorage.getItem('language') || 'id'

const translations = {
	id: {
		info: 'Info',
		multiplayerStatusInfo:
			'Halaman ini berisi daftar status game multiplayer yang tersedia.',
		addMultiplayerGameStatus: 'Tambah Status Game Multiplayer',

		gameName: 'Nama Game',
		multiplayer: 'Multiplayer',
		maxPlayers: 'Maksimal Pemain',
		notes: 'Catatan',
		lastUpdated: 'Terakhir Diperbarui',
		actions: 'Aksi',

		yes: 'Ya',
		no: 'Tidak',
		partially: 'Sebagian',

		multiplayerStatus: 'Status Multiplayer',
		vrGames: 'Game VR',
	},
	en: {
		info: 'Info',
		multiplayerStatusInfo:
			'This page contains a list of available multiplayer game statuses.',
		addMultiplayerGameStatus: 'Add Multiplayer Game Status',

		gameName: 'Game Name',
		multiplayer: 'Multiplayer',
		maxPlayers: 'Max Players',
		notes: 'Notes',
		lastUpdated: 'Last Updated',
		actions: 'Actions',

		yes: 'Yes',
		no: 'No',
		partially: 'Partially',

		multiplayerStatus: 'Multiplayer Status',
		vrGames: 'VR Games',
	},
}

const MultiplayerStatus = () => {
	const [language, setLanguage] = useState(storedLanguage)
	const [dataMultiplayerStatus, setDataMultiplayerStatus] = useState({})
	const [user, setUser] = useState(null)

	useEffect(() => {
		const unsubscribe = auth.onAuthStateChanged((user) => {
			if (user) {
				setUser(user)
			} else {
				setUser(null)
			}
		})

		return () => {
			unsubscribe()
		}
	}, [])

	useEffect(() => {
		const fetchData = () => {
			firebase.ref(`vrGames/multiplayerStatus`).on('value', (snapshot) => {
				if (snapshot.val() !== null) {
					setDataMultiplayerStatus({ ...snapshot.val() })
				} else {
					setDataMultiplayerStatus({})
				}
			})
		}

		fetchData()

		return () => {
			firebase.ref(`vrGames/multiplayerStatus`).off()
		}
	}, [])

	const onDelete_MPStatus = (MPStatus) => {
		if (
			window.confirm(
				'Are you sure that you want to delete this multiplayer status?'
			)
		) {
			firebase.ref(`vrGames/multiplayerStatus/${MPStatus}`).remove((err) => {
				if (err) {
					toast.error(err)
				} else {
					toast.success('Multiplayer status has been deleted successfully')
				}
			})
		}
	}

	const handleEdit_MPStatus = (MPStatus) => {
		window.location.href = `/vr-games/multiplayer-status/update/${MPStatus}`
	}

	const handleAddMPStatus = () => {
		window.location.href = '/vr-games/multiplayer-status/add'
	}

	const [isMobile, setIsMobile] = useState(window.innerWidth < 1200)

	useEffect(() => {
		const handleResize = () => {
			setIsMobile(window.innerWidth < 1200)
		}

		window.addEventListener('resize', handleResize)

		return () => {
			window.removeEventListener('resize', handleResize)
		}
	}, [])

	return (
		<div className={`pb-6 w-full min-h-screen ${!isMobile && 'pt-[75px]'}`}>
			<Toaster />
			<Helmet>
				<title>
					{translations[language].multiplayerStatus} |{' '}
					{translations[language].vrGames} - HyperTopia
				</title>
			</Helmet>
			<header
				className="bg-gradient-to-r from-[#0881FB] to-[#0059b2] py-12 text-center text-white px-6 sm:px-10"
				style={{
					background: `linear-gradient(to left, rgba(8, 129, 251, 0.95) 10%, rgba(0, 89, 178, 0.95) 100%), url(${bgPage}) center/cover`,
				}}
			>
				<h1 className="text-3xl md:text-4xl font-bold tracking-wide">
					{translations[language].multiplayerStatus}
				</h1>
				<p className="text-base md:text-lg mt-4">
					{translations[language].multiplayerStatusInfo}
				</p>
			</header>
			<div className="overflow-x-auto bg-white px-6">
				<table className="w-full text-left border-collapse shadow-md rounded-lg">
					<thead>
						<tr className="bg-gray-100">
							{[
								'No',
								'gameName',
								'multiplayer',
								'maxPlayers',
								'notes',
								'lastUpdated',
								'actions',
							].map((column, index) => (
								<th
									key={index}
									className="py-3 px-6 border-b font-semibold text-gray-700"
								>
									{translations[language][column]}
								</th>
							))}
						</tr>
					</thead>
					<tbody>
						{Object.keys(dataMultiplayerStatus).map((MPStatus, index) => (
							<motion.tr
								key={MPStatus}
								initial={{ opacity: 0, x: -50 }}
								animate={{ opacity: 1, x: 0 }}
								transition={{ delay: index * 0.1 }}
								className="border-b hover:bg-gray-50"
							>
								<td className="py-3 px-6">{index + 1}</td>
								<td className="py-3 px-6">
									{dataMultiplayerStatus[MPStatus].gameName}
								</td>
								<td className="py-3 px-6">
									<motion.div
										className="inline-block px-2 py-1 rounded text-sm font-semibold"
										style={{
											backgroundColor:
												dataMultiplayerStatus[MPStatus].multiplayer === 'yes'
													? '#D4EDDA'
													: dataMultiplayerStatus[MPStatus].multiplayer === 'no'
														? '#F8D7DA'
														: '#FFF3CD',
											color:
												dataMultiplayerStatus[MPStatus].multiplayer === 'yes'
													? '#155724'
													: dataMultiplayerStatus[MPStatus].multiplayer === 'no'
														? '#721C24'
														: '#856404',
										}}
										initial={{ scale: 0.9 }}
										animate={{ scale: 1 }}
										transition={{ type: 'spring', stiffness: 500 }}
									>
										{
											translations[language][
												dataMultiplayerStatus[MPStatus].multiplayer
											]
										}
									</motion.div>
								</td>
								<td className="py-3 px-6">
									{dataMultiplayerStatus[MPStatus].maxPlayers}
								</td>
								<td className="py-3 px-6">
									{dataMultiplayerStatus[MPStatus].notes}
								</td>
								<td className="py-3 px-6">
									{dataMultiplayerStatus[MPStatus].lastUpdated}
								</td>
								{user?.email === 'hypertopiaid@gmail.com' && (
									<td className="py-3 px-6 flex space-x-2">
										<motion.button
											whileHover={{ scale: 1.1 }}
											onClick={() => handleEdit_MPStatus(MPStatus)}
											className="text-yellow-500 p-1"
										>
											<FaPencil />
										</motion.button>
										<motion.button
											whileHover={{ scale: 1.1 }}
											onClick={() => onDelete_MPStatus(MPStatus)}
											className="text-red-500 p-1"
										>
											<FaTrash />
										</motion.button>
									</td>
								)}
							</motion.tr>
						))}
					</tbody>
				</table>
			</div>
		</div>
	)
}

export default MultiplayerStatus
