import React, { useState, useEffect, useRef } from 'react'
import { Helmet } from 'react-helmet'
import { useDarkModeContext } from '../DarkModeContext'
import fireDb from '../firebase'

// Import all the section components
import HeroSection from './Home/HeroSection'
import OfficialShopSection from './Home/OfficialShopSection'
import ProductsSection from './Home/ProductsSection'
import VRGamesSection from './Home/VRGamesSection'
import TutorialSection from './Home/TutorialSection'
import ClosingSection from './Home/ClosingSection'
import Footer from './Home/Footer'

const Home = () => {
	const { isDarkMode } = useDarkModeContext()
	const [products, setProducts] = useState([])
	const [games, setGames] = useState([])
	const [gamesPCVR, setGamesPCVR] = useState([])
	const language = localStorage.getItem('language') || 'id'
	const productsSectionRef = useRef(null)

	// Translations for multi-language support
	const translations = {
		en: {
			title: 'Home',
			getStarted: 'Get Started',
			scrollDown: 'Scroll Down',
			heroSubtitle:
				'Discover an extensive collection of Steam, VR, and Meta Quest games that bring excitement to your screen.',
			olshop: 'Official Online Shop',
			olshopSubtitle: 'Find exclusive deals on our official stores!',
			visitStore: 'Visit Store',
			products: 'Products',
			productsSubtitle: 'Discover our latest products and others!',
			listVRGames: 'VR Games',
			listVRGamesSubtitle:
				'Standalone and PCVR games with a growing number of titles',
			tutorial: 'Tutorial',
			tutorialSubtitle:
				'Explore our tutorials to get the most out of your Steam and Meta Quest games. Learn setup guides, tips, and tricks!',
			seeAll: 'See All',
			home: 'Home',

			thankyou: 'Thank You for Visiting HyperTopia!',
			thankyouSubtitle:
				'Stay tuned for the latest VR and Steam games. Keep exploring and expanding your collection!',

			allAboutGames: 'HyperTopia - All About Games',
			privacyPolicy: 'Privacy Policy',
			termsOfService: 'Terms of Service',

			backToTop: 'Back to Top',

			tutorialDesc1:
				'How to Enable Developer Mode on Quest Headset to Use SideQuest',
			tutorialDesc2: 'How to Install Games on Meta Quest using SideQuest',

			exploreTutorials: 'Explore Tutorials',

			home: 'Home',
			products: 'Products',
			vrGames: 'VR Games',
			profile: 'Profile',

			footerDescription:
				'Discover the best collection of VR and Steam games to elevate your gaming experience.',
		},
		id: {
			title: 'Beranda',
			getStarted: 'Mulai',
			scrollDown: 'Scroll Bawah',
			heroSubtitle:
				'Temukan koleksi game Steam, VR, dan Meta Quest yang luas yang membawa kegembiraan ke layar Anda.',
			olshop: 'Toko Online Resmi',
			olshopSubtitle: 'Temukan penawaran eksklusif di toko resmi kami!',
			visitStore: 'Kunjungi Toko',
			products: 'Produk',
			productsSubtitle: 'Temukan produk terbaru kami dan lainnya!',
			listVRGames: 'Game VR',
			listVRGamesSubtitle:
				'Terdapat Standalone dan PCVR dengan jumlah yang terus bertambah',
			tutorial: 'Tutorial',
			tutorialSubtitle:
				'Jelajahi tutorial kami untuk mendapatkan pengalaman terbaik dari game Steam dan Meta Quest Anda. Pelajari panduan setup, tips, dan trik!',
			seeAll: 'Lihat Semua',
			home: 'Beranda',

			thankyou: 'Terima Kasih Telah Mengunjungi HyperTopia!',
			thankyouSubtitle:
				'Tetap pantau untuk game VR dan Steam terbaru. Terus jelajahi dan perluas koleksimu!',

			allAboutGames: 'HyperTopia - Semua Tentang Game',
			privacyPolicy: 'Kebijakan Privasi',
			termsOfService: 'Syarat Layanan',

			backToTop: 'Kembali ke Atas',

			tutorialDesc1:
				'Cara Mengaktifkan Mode Developer pada Headset Quest untuk Menggunakan SideQuest',
			tutorialDesc2: 'Cara Menginstal Game di Meta Quest menggunakan SideQuest',

			exploreTutorials: 'Jelajahi Tutorial',

			home: 'Beranda',
			products: 'Produk',
			vrGames: 'Game VR',
			profile: 'Profil',

			footerDescription:
				'Temukan koleksi game VR dan Steam terbaik untuk meningkatkan pengalaman bermain game Anda.',
		},
	}

	// Fetch products and VR games data
	useEffect(() => {
		const productsRef = fireDb.ref('products')
		const gamesRef = fireDb.ref('vrGames/standalone')
		const gamesPCVRRef = fireDb.ref('vrGames/pcvr')

		// Fetch and set products
		productsRef.on('value', (snapshot) => {
			if (snapshot.val()) {
				const productsData = Object.values(snapshot.val())
				setProducts(
					productsData.sort((a, b) =>
						a.productName.localeCompare(
							b.productName,
							language === 'en' ? 'en-US' : 'id-ID'
						)
					)
				)
			} else {
				setProducts([])
			}
		})

		// Fetch and set VR games
		gamesRef.on('value', (snapshot) => {
			if (snapshot.val()) {
				setGames(Object.values(snapshot.val()))
			} else {
				setGames([])
			}
		})

		// Fetch and set PCVR games
		gamesPCVRRef.on('value', (snapshot) => {
			if (snapshot.val()) {
				setGamesPCVR(Object.values(snapshot.val()))
			} else {
				setGamesPCVR([])
			}
		})

		return () => {
			productsRef.off('value')
			gamesRef.off('value')
			gamesPCVRRef.off('value')
		}
	}, [language])

	const [isMobile, setIsMobile] = useState(window.innerWidth < 1200)

	useEffect(() => {
		const handleResize = () => {
			setIsMobile(window.innerWidth < 1200)
		}

		window.addEventListener('resize', handleResize)

		return () => {
			window.removeEventListener('resize', handleResize)
		}
	}, [])

	return (
		<div
			className={`${isMobile ? '-mt-[70px]' : ''} w-full min-h-screen ${isDarkMode ? 'bg-gray-900' : 'bg-white'}`}
		>
			{/* Set page title */}
			<Helmet>
				<title>{translations[language].home} | HyperTopia</title>
			</Helmet>

			{/* Render each section */}
			<HeroSection
				translations={translations}
				language={language}
				nextSectionRef={productsSectionRef}
			/>
			<section ref={productsSectionRef}>
				<OfficialShopSection
					translations={translations}
					language={language}
					isDarkMode={isDarkMode}
				/>
			</section>
			<ProductsSection
				products={products}
				translations={translations}
				language={language}
			/>
			<VRGamesSection
				games={games}
				translations={translations}
				language={language}
				gamesPCVR={gamesPCVR}
			/>
			<TutorialSection translations={translations} language={language} />
			<ClosingSection translations={translations} language={language} />
			<Footer translations={translations} language={language} />
		</div>
	)
}

export default Home
