import React, {
	createContext,
	useContext,
	useState,
	useEffect,
	useRef,
} from 'react'
import {
	FaBars,
	FaCartShopping,
	FaChevronLeft,
	FaWhatsapp,
} from 'react-icons/fa6'

import logoHypertopia from '../assets/images/New_HyperTopia_Logo.png'
import logoHypertopia_IconOnly from '../assets/images/NewLogoOnly.png'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import fireDb from '../firebase'
import { signInWithPopup } from 'firebase/auth'
import { signOut } from 'firebase/auth'
import { auth, provider } from '../firebase'
import {
	FaCheckCircle,
	FaHome,
	FaSignInAlt,
	FaSignOutAlt,
	FaTimes,
} from 'react-icons/fa'
import { RiAdminFill } from 'react-icons/ri'
import { FcGoogle } from 'react-icons/fc'
import { LazyLoadImage } from 'react-lazy-load-image-component'

import Swal from 'sweetalert2'

const SidebarMobileContext = createContext()
export default function SidebarMobile({ children }) {
	const navigate = useNavigate()
	const isDropdownOpen = false
	const language = localStorage.getItem('language') || 'id'

	const [user, setUser] = useState(null)

	const allowedEmails = ['hypertopiaid@gmail.com']

	const eligibleUsersStandaloneRef = fireDb.ref('eligibleUsers/standalone')
	const eligibleUsersPCVRRef = fireDb.ref('eligibleUsers/pcvr')

	const [loadingEligibleUsers, setLoadingEligibleUsers] = useState(true)

	const handleSignOut = () => {
		Swal.fire({
			title: translations[language].titleSignOut,
			text: translations[language].textSignOut,
			icon: 'warning',
			showCancelButton: true,
			confirmButtonColor: '#3085d6',
			cancelButtonColor: '#d33',
			confirmButtonText: translations[language].confirmSignOut,
			cancelButtonText: translations[language].cancelSignOut,
		}).then((result) => {
			if (result.isConfirmed) {
				signOut(auth)
					.then(() => {
						setUser(null)
						Swal.fire(
							translations[language].successSignOut,
							translations[language].successSignOutText,
							'success'
						)
						setShowAccountSignOutDialog(false)
						setShowSignInDialog(true)
					})
					.catch((error) => {
						Swal.fire({
							icon: 'error',
							title: translations[language].errorSignOut,
							text: translations[language].errorSignOutText,
							footer: error.message,
						})
					})
			}
		})
	}

	const translations = {
		en: {
			home: 'Home',
			products: 'Products',
			purchase: 'Purchase',
			profile: 'Profile',
			yourOrders: 'Orders',

			listVRGames: 'VR Games',
			software: 'Software Tools',
			listGames: 'List Games',
			tutorials: 'Tutorials',
			requestGames: 'Request Games',

			saveData: 'Data Saver',

			language: 'Language',
			theme: 'Theme',
			dark: 'Dark',
			light: 'Light',

			signIn: 'Sign In',
			loginWith: 'Login with',
			signOut: 'Sign Out',
			admin: 'Admin',
			adminDashboard: 'Admin Dashboard',

			tooltipDataSaver: 'Game cover images will not be displayed to save data.',

			accessDownloadStandaloneGameQuest: 'Standalone Quest Game',
			accessDownloadPCVRGameQuest: 'PCVR Game',

			titleSignOut: 'Are you sure?',
			textSignOut: 'Do you really want to sign out?',
			confirmSignOut: 'Yes, sign out!',
			cancelSignOut: 'No, stay logged in',
			successSignOut: 'Signed out!',
			successSignOutText: 'You have been successfully signed out.',
			errorSignOut: 'Oops...',
			errorSignOutText: 'Something went wrong!',

			loginText: 'Log In to your HyperTopia Account',
			givenEmail:
				'If you have already purchased a product, please log in with the Google email that has been included/given to us when ordering!',
		},
		id: {
			home: 'Beranda',
			listGames: 'Daftar Game',
			products: 'Produk',
			purchase: 'Beli',
			profile: 'Profil',
			yourOrders: 'Pesanan',

			listVRGames: 'Game VR',
			software: 'Alat Software',
			tutorials: 'Tutorial',
			requestGames: 'Request Game',

			saveData: 'Hemat Data',

			language: 'Bahasa',
			theme: 'Tema',
			dark: 'Gelap',
			light: 'Terang',

			signIn: 'Masuk',
			loginWith: 'Masuk dengan',
			signOut: 'Keluar',
			admin: 'Admin',
			adminDashboard: 'Dasbor Admin',

			tooltipDataSaver:
				'Cover gambar setiap game tidak akan ditampilkan untuk menghemat data.',

			accessDownloadStandaloneGameQuest: 'Game Standalone Quest',
			accessDownloadPCVRGameQuest: 'Game PCVR',

			titleSignOut: 'Apakah kamu yakin?',
			textSignOut: 'Apakah kamu benar-benar ingin keluar?',
			confirmSignOut: 'Ya, keluar!',
			cancelSignOut: 'Tidak, tetap masuk',
			successSignOut: 'Keluar!',
			successSignOutText: 'Anda telah berhasil keluar.',
			errorSignOut: 'Oops...',
			errorSignOutText: 'Ada yang salah!',

			loginText: 'Masuk ke Akun HyperTopia Anda',
			givenEmail:
				'Apabila sudah beli produk, silahkan login dengan email Google yang sudah dicantumkan/diberikan kepada kami pada saat memesan!',
		},
	}

	const handleLanguageChange = (event) => {
		const selectedLanguage = event.target.value
		localStorage.setItem('language', selectedLanguage)

		window.location.reload()
	}

	const [cartItems, setCartItems] = useState([])

	useEffect(() => {
		if (user) {
			const cartRef = fireDb.ref(`cart/${user.uid}`)

			// Listen for changes in the cart
			const handleSnapshot = (snapshot) => {
				const items = snapshot.val()
				const cartItemList = items ? Object.values(items) : []
				setCartItems(cartItemList)
			}

			cartRef.on('value', handleSnapshot)

			return () => {
				// Cleanup the event listener when the component unmounts
				cartRef.off('value', handleSnapshot)
			}
		}
	}, [user])

	const expanded = true

	const handleToDashboardAdmin = () => {
		navigate('/dba/dashboard', { replace: true })
	}

	const handleToHome = () => {
		navigate('/', { replace: true })
	}

	const [eligibleStandaloneGameQuest, setEligibleStandaloneGameQuest] =
		useState(false)
	const [eligiblePCVRGameQuest, setEligiblePCVRGameQuest] = useState(false)

	useEffect(() => {
		const unsubscribe = auth.onAuthStateChanged(async (user) => {
			if (user) {
				setUser(user)
				try {
					const snapshot = await eligibleUsersStandaloneRef
						.orderByChild('email')
						.equalTo(user.email)
						.once('value')
					const eligibleUsersStandalone = snapshot.val()
					if (eligibleUsersStandalone) {
						setEligibleStandaloneGameQuest(true) // User is eligible, hide the announcement
						setLoadingEligibleUsers(false)
					} else {
						setEligibleStandaloneGameQuest(false) // User is not eligible, show the announcement
						setLoadingEligibleUsers(false)
					}
				} catch (error) {
					console.error('Error fetching eligible users:', error.message)
				}
			} else {
				setUser(null)
			}
		})

		return () => {
			unsubscribe()
		}
	}, [eligibleStandaloneGameQuest, eligibleUsersStandaloneRef, user])

	useEffect(() => {
		const unsubscribe = auth.onAuthStateChanged(async (user) => {
			if (user) {
				setUser(user)
				try {
					const snapshot = await eligibleUsersPCVRRef
						.orderByChild('email')
						.equalTo(user.email)
						.once('value')
					const eligibleUsersPCVR = snapshot.val()
					if (eligibleUsersPCVR) {
						setEligiblePCVRGameQuest(true) // User is eligible, hide the announcement
						setLoadingEligibleUsers(false)
					} else {
						setEligiblePCVRGameQuest(false) // User is not eligible, show the announcement
						setLoadingEligibleUsers(false)
					}
				} catch (error) {
					console.error('Error fetching eligible users:', error.message)
				}
			} else {
				setUser(null)
			}
		})

		return () => {
			unsubscribe()
		}
	}, [eligiblePCVRGameQuest, eligibleUsersPCVRRef, user])

	const handleToChatAdmin = () => {
		const currentHour = new Date().getHours()
		let greeting

		if (currentHour >= 4 && currentHour < 12) {
			greeting = 'pagi'
		} else if (currentHour >= 12 && currentHour < 15) {
			greeting = 'siang'
		} else if (currentHour >= 15 && currentHour < 18) {
			greeting = 'sore'
		} else {
			greeting = 'malam'
		}

		window.open(
			`https://api.whatsapp.com/send/?phone=6285141175754&text=Halo%2C+Kak.+Selamat+${greeting}!&type=phone_number&app_absent=0`,
			'_blank'
		)
	}

	const handleToMeeting = () => {
		window.open('https://hypertopia.shop/meeting')
	}

	const limitationText = (text, limit) => {
		return text?.length > limit ? `${text?.substring(0, limit)}...` : text
	}

	const [expandedMobile, setExpandedMobile] = useState(false)

	const handleToggleSidebar = () => {
		setExpandedMobile((curr) => !curr)
	}

	const [showSignInDialog, setShowSignInDialog] = useState(false)
	const [showAccountSignOutDialog, setShowAccountSignOutDialog] =
		useState(false)

	const handleToggleSignInDialog = () => {
		setShowSignInDialog((curr) => !curr)
	}

	const handleToggleAccountSignOutDialog = () => {
		setShowAccountSignOutDialog((curr) => !curr)
	}

	const handleGoogleSignIn = () => {
		signInWithPopup(auth, provider)
			.then((result) => {
				const user = result.user
				if (user.disabled) {
					setUser(null)
				} else {
					setUser(user)
					localStorage.setItem('user', JSON.stringify(user))
					navigate('/dashboard')
					setShowSignInDialog(false)
				}
			})
			.catch((error) => {})
	}

	const dialogSignInRef = useRef(null)
	const dialogAccountSignOutRef = useRef(null)
	const sidebarRef = useRef(null)

	useEffect(() => {
		const handleClickOutside = (event) => {
			// Jika klik di luar elemen dialog, ubah showSignInDialog menjadi false
			if (
				dialogSignInRef.current &&
				!dialogSignInRef.current.contains(event.target)
			) {
				setShowSignInDialog(false)
			}
		}

		// Tambahkan event listener pada klik di seluruh dokumen
		document.addEventListener('mousedown', handleClickOutside)

		// Hapus event listener ketika komponen unmount
		return () => {
			document.removeEventListener('mousedown', handleClickOutside)
		}
	}, [setShowSignInDialog])

	useEffect(() => {
		const handleClickOutside = (event) => {
			// Jika klik di luar elemen dialog, ubah showSignInDialog menjadi false
			if (
				dialogAccountSignOutRef.current &&
				!dialogAccountSignOutRef.current.contains(event.target)
			) {
				setShowAccountSignOutDialog(false)
			}
		}

		// Tambahkan event listener pada klik di seluruh dokumen
		document.addEventListener('mousedown', handleClickOutside)

		// Hapus event listener ketika komponen unmount
		return () => {
			document.removeEventListener('mousedown', handleClickOutside)
		}
	}, [setShowAccountSignOutDialog])

	useEffect(() => {
		const handleClickOutside = (event) => {
			// Jika klik di luar elemen dialog, ubah showSignInDialog menjadi false
			if (sidebarRef.current && !sidebarRef.current.contains(event.target)) {
				setExpandedMobile(false)
			}
		}

		// Tambahkan event listener pada klik di seluruh dokumen
		document.addEventListener('mousedown', handleClickOutside)

		// Hapus event listener ketika komponen unmount
		return () => {
			document.removeEventListener('mousedown', handleClickOutside)
		}
	}, [setExpandedMobile])

	return (
		<>
			<nav className="fixed top-0 left-0 right-0 flex items-center justify-between bg-white text-black px-4 py-3 z-50 shadow-xl">
				<button
					onClick={handleToggleSidebar}
					className="z-20 size-9 justify-center rounded-lg bg-[#0086FB] flex flex-row gap-2 items-center transition-all text-white"
				>
					<FaBars />
				</button>
				<a
					href="/"
					className="absolute inset-0 flex items-center justify-center z-10"
				>
					<img src={logoHypertopia} alt="Hypertopia Logo" className="h-8" />
				</a>
				<div className="flex items-center flex-row gap-4 z-20">
					{user && (
						<Link to="/account/cart">
							<button
								className={`relative hover:bg-zinc-300 transition-all duration-300 p-2 rounded-full`}
							>
								<FaCartShopping className={`text-2xl text-black`} />
								<span className="absolute top-0 text-sm font-semibold bg-[#0081FB] px-1.5 rounded-full text-white">
									{cartItems.length}
								</span>
							</button>
						</Link>
					)}
					<div className="relative inline-block text-left">
						<button type="button" className="cursor-pointer outline-none">
							{user ? (
								<button onClick={handleToggleAccountSignOutDialog}>
									<img
										src={user?.photoURL}
										alt=""
										className={`size-10 rounded-full outline-none`}
									/>
								</button>
							) : (
								<button
									onClick={handleToggleSignInDialog}
									className="flex flex-row gap-2 items-center justify-center bg-[#0081FB] transition-all text-white size-9 rounded-xl"
								>
									<FaSignInAlt />
								</button>
							)}
						</button>
					</div>
				</div>
			</nav>

			<div
				className={`fixed inset-0 shadow-xl bg-white text-white z-50 w-screen bg-opacity-40 transform ${expandedMobile ? 'backdrop-blur-sm translate-x-0' : '-translate-x-full'} transition-transform duration-700`}
			>
				<aside
					ref={sidebarRef}
					className={`fixed inset-0 shadow-xl bg-white text-white z-50 w-fit transform ${expandedMobile ? 'translate-x-0' : '-translate-x-full'} transition-transform duration-700`}
				>
					<nav className="h-full flex flex-col bg-white shadow-sm">
						<div className="p-4 pb-2 flex justify-between items-center">
							<img
								src={logoHypertopia}
								className={`overflow-hidden transition-all w-40`}
								alt=""
							/>
							{/* <img src={logoHypertopia_IconOnly} className={`overflow-hidden transition-all ${!expanded ? 'w-8' : 'w-0'}`} alt="" /> */}
							<button
								onClick={() => setExpandedMobile((curr) => !curr)}
								className="p-2 rounded-lg bg-blue-600 hover:bg-blue-400 transition-all text-white"
							>
								<FaChevronLeft />
							</button>
						</div>

						<SidebarMobileContext.Provider value={{ expanded }}>
							<ul
								className="flex-1 px-3"
								style={{
									maxHeight: 'calc(100vh - 100px)',
									overflowY: 'auto',
									overflowX: 'hidden',
								}}
							>
								{children}
							</ul>
						</SidebarMobileContext.Provider>

						{expanded && user && (
							<div className={`p-3`}>
								<div className="bg-white flex flex-col justify-center items-center text-center rounded-2xl p-2.5 gap-3 border-2">
									<div className="flex flex-row justify-between items-center w-full px-2">
										<span className="text-sm text-left w-40 text-black">
											{translations[language].accessDownloadStandaloneGameQuest}
										</span>
										<div className="size-5">
											{loadingEligibleUsers ? (
												<div className="loading-overlay h-fit w-fit">
													<div className="spinner"></div>
												</div>
											) : eligibleStandaloneGameQuest ? (
												<FaCheckCircle
													size={23}
													className={`text-blue-500 text-2xl bg-white rounded-full`}
												/>
											) : (
												<FaTimes
													className={`text-red-600 text-2xl rounded-full`}
												/>
											)}
										</div>
									</div>

									<div className="flex flex-row justify-between items-center w-full px-2">
										<span className="text-sm text-left w-40 text-black">
											{translations[language].accessDownloadPCVRGameQuest}
										</span>
										<div className="size-5">
											{loadingEligibleUsers ? (
												<div className="loading-overlay h-fit w-fit">
													<div className="spinner"></div>
												</div>
											) : eligiblePCVRGameQuest ? (
												<FaCheckCircle
													size={23}
													className={`text-blue-500 text-2xl bg-white rounded-full`}
												/>
											) : (
												<FaTimes
													className={`text-red-600 text-2xl rounded-full`}
												/>
											)}
										</div>
									</div>
								</div>
							</div>
						)}

						<div className={`flex pb-3 pr-3 ${expanded ? '' : 'p-3'}`}>
							{user
								? !expanded && (
										<img
											src={user.photoURL}
											alt=""
											className={`h-10 rounded-full border-2 ${isDropdownOpen ? 'border-[#0081FB]' : 'border-zinc-300'} hover:border-[#0081FB] transition-all duration-300 outline-none`}
										/>
									)
								: ''}

							{expanded && (
								<div
									className={`flex flex-col justify-between items-center overflow-hidden transition-all ${expanded ? 'w-full ml-3' : 'w-0'}`}
								>
									{user && allowedEmails.includes(user.email) && (
										<div className="flex flex-row gap-2 w-full">
											<button
												className={`bg-gradient-to-br from-blue-300 to-blue-600 rounded-xl w-full flex px-2 py-2.5 mb-2 flex-row gap-2 items-center justify-center text-white`}
												onClick={handleToHome}
											>
												<span>{translations[language].home}</span>
												<FaHome />
											</button>
											<button
												className={`bg-gradient-to-br from-blue-300 to-blue-600 rounded-xl w-full flex px-2 py-2.5 mb-2 flex-row gap-2 items-center justify-center text-white`}
												onClick={handleToDashboardAdmin}
											>
												<span>{translations[language].admin}</span>
												<RiAdminFill />
											</button>
										</div>
									)}
									{/* <Link to='https://api.whatsapp.com/send/?phone=6285234115941&text=Halo%2C+Kak.&type=phone_number&app_absent=0' target="_blank">
                  <button className='flex flex-row items-center gap-1 rounded-full p-2 shadow-xl bg-[#25D366] hover:bg-[#3eb369] transition-all duration-300'>
                    <span className='px-2 font-semibold text-white'>Chat</span>
                    <FaWhatsapp size={36} className='text-right text-2xl text-white' />
                  </button>
                </Link> */}
									<div className="flex flex-row gap-2 w-full h-fit">
										<select
											onChange={handleLanguageChange}
											value={language}
											className={`hover:bg-zinc-200 transition-all outline-none px-2 cursor-pointer border-none text-left rounded-xl text-black`}
											role="menuitem"
										>
											<option value="en">EN</option>
											<option value="id">ID</option>
										</select>
										<button
											className={`bg-gradient-to-br from-green-400 to-green-600 rounded-xl w-full h-full flex px-2 py-2 mb-2 flex-row gap-2 items-center justify-center text-white`}
											onClick={handleToChatAdmin}
										>
											<span>{translations[language].admin}</span>
											<FaWhatsapp />
										</button>
									</div>

									<hr className="w-full border-t-2 border-zinc-200 my-4" />

									<div className="flex flex-row w-full gap-2 items-start justify-between">
										{user && (
											<span className="flex flex-row gap-3 items-start">
												<img
													src={user?.photoURL}
													alt=""
													className="size-10 rounded-full shadow-xl"
												/>
												<span className=" leading-4 pb-2">
													<h4 className="font-semibold text-base text-black">
														{limitationText(user?.displayName, 15)}
													</h4>
													<span className="text-xs text-gray-500">
														{user?.email}
													</span>
												</span>
											</span>
										)}
										{user ? (
											<button
												onClick={handleSignOut}
												className="p-2.5 rounded-xl bg-red-500 hover:bg-red-700 transition-all w-fit h-fit text-white"
											>
												<FaSignOutAlt />
											</button>
										) : (
											<button
												onClick={() => {
													setShowSignInDialog(true)
													setExpandedMobile(false)
												}}
												className="w-full p-2.5 rounded-xl bg-blue-500 hover:bg-blue-700 transition-all h-fit text-white flex flex-row gap-2 items-center justify-center"
											>
												<p>{translations[language].signIn}</p>
												<FaSignInAlt />
											</button>
										)}
									</div>
								</div>
							)}
						</div>
					</nav>

					<style>
						{`
          .loading-overlay {
            display: flex;
            justify-content: center;
            align-items: center;
          }
          
          .spinner {
            border: 3px solid rgba(0, 0, 0, 0.3); /* warna putih dengan transparansi */
            border-top: 3px solid #1E1E1E; /* warna putih */
            border-radius: 50%;
            width: 24px;
            height: 24px;
            animation: spin 1s linear infinite;
          }
          
          @keyframes spin {
            0% { transform: rotate(0deg); }
            100% { transform: rotate(360deg); }
						}
          `}
					</style>
				</aside>
			</div>

			<div
				className={`w-screen h-screen fixed bottom-0 z-30 p-5 bg-black flex justify-end items-end ${showSignInDialog || showAccountSignOutDialog ? 'bg-opacity-40 backdrop-blur-sm translate-y-0' : 'bg-opacity-0 backdrop-blur-none translate-y-full'} transform transition-all duration-700`}
			>
				{/* SIGN IN DIALOG */}
				<div
					ref={dialogSignInRef}
					className={`bg-white w-full h-fit rounded-3xl shadow-xl p-6 flex flex-col items-center transform ${showSignInDialog ? '' : 'hidden'} transition-transform duration-700`}
				>
					<Link to="/">
						<LazyLoadImage
							src={logoHypertopia_IconOnly}
							alt="hypertopia-logo"
							className="mb-5"
							width={50}
						/>
					</Link>
					<h1 className={`mb-3 font-bold text-black`}>
						{translations[language].loginText}
					</h1>
					<h1 className={`mb-4 text-sm text-center text-black`}>
						{translations[language].givenEmail}
					</h1>
					<button
						className="hover:bg-gray-300 transition-all duration-300 ease-in-out w-full h-fit bg-white border-2 py-3 rounded-full flex flex-row gap-2 items-center justify-center"
						onClick={handleGoogleSignIn}
					>
						<FcGoogle className="w-6 h-6" />
						<p className="font-semibold">
							{translations[language].loginWith} Google
						</p>
					</button>
				</div>

				{/* ACCOUNT AND SIGN OUT DIALOG */}
				<div
					ref={dialogAccountSignOutRef}
					className={`bg-white w-full h-fit rounded-3xl shadow-xl p-6 flex flex-col items-center transform ${showAccountSignOutDialog ? '' : 'hidden'} transition-transform duration-700`}
				>
					<div className="flex flex-row gap-4 items-center p-4 w-full rounded-3xl border-2 mb-3">
						<img
							src={user?.photoURL}
							alt="user-profile"
							width={70}
							className="rounded-full"
						/>
						<div className="flex flex-col items-start">
							<p className={`font-bold text-black text-lg`}>
								{user?.displayName}
							</p>
							<p className={`text-zinc-500 text-sm`}>{user?.email}</p>
						</div>
					</div>
					<button
						onClick={handleSignOut}
						className="w-full bg-red-500 hover:bg-red-600 transition-all duration-300 rounded-3xl"
					>
						<span className={`w-full p-3 flex items-center justify-center`}>
							<span className={`text-white`}>
								{translations[language].signOut}
							</span>
							<FaSignOutAlt className="inline-block ml-3 text-2xl text-white" />
						</span>
					</button>
				</div>
			</div>
		</>
	)
}

export function SidebarMobileItem({
	icon,
	text,
	active,
	alert,
	link,
	count,
	user,
	dropdownItems_NintendoSwitch,
	dropdownItems_VRGames,
	dropdownItems_Account,
	dropdownItems,
	setExpanded,
}) {
	const { expanded } = useContext(SidebarMobileContext)
	const [dropdownOpen, setDropdownOpen] = useState(false)
	const location = useLocation()

	const handleDropdownToggle = () => {
		setDropdownOpen(!dropdownOpen)
	}

	return (
		<>
			<li
				className={`
          relative flex flex-col items-start py-2.5 px-3 my-3
          font-medium rounded-md cursor-pointer
          transition-colors group
          ${
						text === 'Nintendo Switch'
							? active
								? 'bg-[#DD2020] text-white'
								: 'hover:bg-blue-50 text-gray-500'
							: text === 'Game VR' || text === 'VR Games'
								? active
									? 'bg-[#0081FB] text-white'
									: 'hover:bg-blue-50 text-gray-500'
								: active
									? 'bg-gradient-to-tr from-blue-200 to-blue-100 text-blue-800'
									: 'hover:bg-blue-50 text-gray-500'
					}
        `}
			>
				<div
					className="flex items-start"
					onClick={dropdownItems ? handleDropdownToggle : null}
				>
					<Link to={link} className="flex items-center">
						{icon}
						<span
							className={`overflow-hidden transition-all text-nowrap ${expanded ? 'w-52 ml-3' : 'w-0'}`}
						>
							{text}
						</span>
					</Link>
					{alert && user && (
						<div
							className={`absolute right-2 w-2 h-2 rounded bg-blue-400 ${expanded ? '' : 'top-2'}`}
						/>
					)}
					{count >= 0 && user && (
						<div
							className={`absolute right-2 px-2 rounded bg-blue-400 ${expanded ? '' : 'invisible'}`}
						>
							{count}
						</div>
					)}
				</div>

				{dropdownItems_NintendoSwitch &&
					location.pathname.startsWith('/nintendo-switch') && (
						<ul
							className={`relative top-full left-0 z-10 mt-2 w-full ${expanded ? '' : 'hidden'}`}
						>
							{dropdownItems_NintendoSwitch.map((item, index) => (
								<li
									key={index}
									className={`py-2 px-4 ml-2 my-1 mr-2 cursor-pointer hover:bg-red-800 rounded-lg ${item.active ? 'bg-gradient-to-br from-red-400 to-red-400 text-white' : ''}`}
								>
									<Link to={item.link} className="flex items-center gap-2">
										{item.icon}
										<span>{item.text}</span>
									</Link>
								</li>
							))}
						</ul>
					)}

				{dropdownItems_VRGames && location.pathname.startsWith('/vr-games') && (
					<ul
						className={`relative top-full left-0 z-10 mt-2 w-full ${expanded ? '' : 'hidden'}`}
					>
						{dropdownItems_VRGames.map((item, index) => (
							<li
								key={index}
								className={`py-2 px-4 ml-2 my-1 mr-2 cursor-pointer hover:bg-blue-800 rounded-lg ${item.active ? 'bg-gradient-to-br from-blue-400 to-blue-400 text-white' : ''}`}
							>
								<Link to={item.link} className="flex items-center gap-2">
									{item.icon}
									<span>{item.text}</span>
								</Link>
							</li>
						))}
					</ul>
				)}

				{dropdownItems_Account && location.pathname.startsWith('/account') && (
					<ul
						className={`relative top-full left-0 z-10 mt-2 w-full ${expanded ? '' : 'hidden'}`}
					>
						{dropdownItems_Account.map((item, index) => (
							<li
								key={index}
								className={`py-2 px-4 ml-2 my-1 mr-2 cursor-pointer hover:bg-white rounded-lg ${item.active ? 'bg-gradient-to-br from-blue-600 to-blue-800 text-white' : ''}`}
							>
								<Link to={item.link} className="flex items-center gap-2">
									{item.icon}
									<span>{item.text}</span>
									{item.count >= 0 && item.user && (
										<div
											className={`absolute right-4 px-2 rounded ${item.active ? 'bg-white text-blue-800' : 'bg-blue-800 text-white'} ${expanded ? '' : 'invisible'}`}
										>
											{item.count}
										</div>
									)}
								</Link>
							</li>
						))}
					</ul>
				)}

				{!expanded && (
					<div
						className={`
              absolute left-full rounded-md px-2 py-1 ml-6
              bg-blue-100 text-blue-800 text-sm
              invisible opacity-20 -translate-x-3 transition-all
              group-hover:visible group-hover:opacity-100 group-hover:translate-x-0 text-nowrap
              `}
					>
						{text}
					</div>
				)}
			</li>
		</>
	)
}
