import React, { useState, useEffect, useRef } from 'react'
import { Helmet } from 'react-helmet'
import { FaBook, FaMessage, FaRankingStar } from 'react-icons/fa6'
import { FaTools } from 'react-icons/fa'
import { Link } from 'react-router-dom'
import { IoGameController } from 'react-icons/io5'
import { IoIosApps } from 'react-icons/io'
import { MdGroups, MdOutlineSystemUpdateAlt } from 'react-icons/md'
import { motion } from 'framer-motion'
import bgPage from '../../assets/images/bg.jpg'

const VRGames = () => {
	const language = localStorage.getItem('language') || 'id'

	const translations = {
		en: {
			info: 'Info',
			hintInfo: 'Choose one of the menus below to access the desired page!',
			vrGames: 'VR Games',
			beatSaberMod: 'Beat Saber Mod',
			descBeatSaberMod: 'Download the latest Beat Saber mod for Quest.',
			firmwareQuest: 'Firmware Quest',
			descFirmwareQuest: 'Check the latest firmware update for Quest headset.',
			softwareHelper: 'Software Helper',
			descSoftwareHelper:
				'Useful tools for extracting RAR/ZIP and installing games to Quest.',
			listGames: 'List Games',
			descListGames:
				'Browse games available for download and play on Quest headset or PCVR.',
			tutorial: 'Tutorials',
			descTutorial: 'Guides to help install games or apps on Quest.',
			requestGame: 'Request Game',
			descRequestGame:
				'Request the game you want to be uploaded to this website.',
			leaderboard: 'Leaderboard',
			descLeaderboard:
				'Check the leaderboard of the most downloaded games on this website.',
			multiplayerStatus: 'Multiplayer Status',
			descMultiplayerStatus:
				'Check the status of multiplayer games on this website.',
		},
		id: {
			info: 'Info',
			hintInfo: 'Pilih salah satu menu di bawah untuk mengakses halaman!',
			vrGames: 'Game VR',
			beatSaberMod: 'Beat Saber Mod',
			descBeatSaberMod: 'Unduh mod Beat Saber terbaru untuk Quest.',
			firmwareQuest: 'Firmware Quest',
			descFirmwareQuest: 'Cek pembaruan firmware terbaru untuk Quest.',
			softwareHelper: 'Software Pembantu',
			descSoftwareHelper:
				'Alat-alat untuk mengekstrak RAR/ZIP dan menginstal game di Quest.',
			listGames: 'Daftar Game',
			descListGames: 'Daftar game yang bisa diunduh dan dimainkan di Quest.',
			tutorial: 'Tutorial',
			descTutorial: 'Panduan untuk menginstal game atau aplikasi di Quest.',
			requestGame: 'Request Game',
			descRequestGame: 'Ajukan game yang ingin diupload di website ini.',
			leaderboard: 'Papan Peringkat',
			descLeaderboard: 'Cek peringkat game paling banyak diunduh di website.',
			multiplayerStatus: 'Status Multiplayer',
			descMultiplayerStatus: 'Cek status game multiplayer di website ini.',
		},
	}

	const [gridColsVRGamesMenu, setGridColsVRGamesMenu] = useState('grid-cols-1')

	useEffect(() => {
		const handleResize = () => {
			setGridColsVRGamesMenu(
				window.innerWidth >= 800 ? 'grid-cols-2' : 'grid-cols-1'
			)
		}

		handleResize()
		window.addEventListener('resize', handleResize)

		return () => {
			window.removeEventListener('resize', handleResize)
		}
	}, [])

	const [isMobile, setIsMobile] = useState(window.innerWidth < 1200)

	useEffect(() => {
		const handleResize = () => {
			setIsMobile(window.innerWidth < 1200)
		}

		window.addEventListener('resize', handleResize)

		return () => {
			window.removeEventListener('resize', handleResize)
		}
	}, [])

	const menuItems = [
		{
			icon: <FaTools size={50} />,
			label: translations[language].beatSaberMod,
			description: translations[language].descBeatSaberMod,
			link: './beat-saber-mod',
		},
		{
			icon: <MdOutlineSystemUpdateAlt size={50} />,
			label: translations[language].firmwareQuest,
			description: translations[language].descFirmwareQuest,
			link: './firmware-quest',
		},
		{
			icon: <IoIosApps size={50} />,
			label: translations[language].softwareHelper,
			description: translations[language].descSoftwareHelper,
			link: './software-helper',
		},
		{
			icon: <IoGameController size={50} />,
			label: translations[language].listGames,
			description: translations[language].descListGames,
			link: './list-games',
		},
		{
			icon: <FaBook size={50} />,
			label: translations[language].tutorial,
			description: translations[language].descTutorial,
			link: './tutorials',
		},
		{
			icon: <FaMessage size={50} />,
			label: translations[language].requestGame,
			description: translations[language].descRequestGame,
			link: './request-games',
		},
		{
			icon: <FaRankingStar size={50} />,
			label: translations[language].leaderboard,
			description: translations[language].descLeaderboard,
			link: './leaderboard',
		},
		{
			icon: <MdGroups size={50} />,
			label: translations[language].multiplayerStatus,
			description: translations[language].descMultiplayerStatus,
			link: './multiplayer-status',
		},
	]

	return (
		<div
			className={`w-full ${!isMobile && 'pt-[75px]'} min-h-screen`}
		>
			<Helmet>
				<title>{translations[language].vrGames} | HyperTopia</title>
			</Helmet>

			<header
				className="bg-gradient-to-r from-[#0881FB] to-[#0059b2] py-12 text-center text-white px-6 sm:px-10"
				style={{
					background: `linear-gradient(to left, rgba(8, 129, 251, 0.95) 10%, rgba(0, 89, 178, 0.95) 100%), url(${bgPage}) center/cover`,
				}}
			>
				<h1 className="text-3xl md:text-4xl font-bold tracking-wide">
					{translations[language].vrGames}
				</h1>
				<p className="text-base md:text-lg mt-4">
					{translations[language].hintInfo}
				</p>
			</header>

			<div className={`pt-7 mx-7 pb-7 grid ${gridColsVRGamesMenu} gap-7`}>
				{menuItems.map((item, index) => (
					<Link to={item.link} key={index}>
						<motion.div
							whileHover={{ scale: 1.02 }}
							whileTap={{ scale: 0.95 }}
							className="hover:bg-gradient-to-br from-blue-400 to-blue-600 bg-white text-gray-700 hover:text-white transition-all rounded-3xl border-2 p-6 flex gap-4 items-center shadow-md"
						>
							<div className="flex gap-4 items-center">
								<div className="text-3xl">{item.icon}</div>
								<div>
									<h3 className="text-xl font-semibold">{item.label}</h3>
									<p className="text-sm">{item.description}</p>
								</div>
							</div>
						</motion.div>
					</Link>
				))}
			</div>
		</div>
	)
}

export default VRGames
