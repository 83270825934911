import React, { useEffect, useState } from 'react'
import { motion } from 'framer-motion'
import { FaWindows, FaApple, FaLinux, FaDownload } from 'react-icons/fa'
import { MdVerified } from 'react-icons/md'
import { Link } from 'react-router-dom'
import { Helmet } from 'react-helmet'
import SideQuestLogo from '../../assets/images/sidequest-logo.png'
import WinRARLogo from '../../assets/images/winrar-logo.png'
import QGOLogo from '../../assets/images/qgo-logo.png'
import { FaAndroid } from 'react-icons/fa6'
import fireDb from '../../firebase'
import { auth } from '../../firebase'
import { Icon } from '@iconify/react'
import bgPage from '../../assets/images/bg.jpg'

const SoftwareCard = ({
	logo,
	title,
	description,
	downloads,
	language,
	isEligible,
}) => {
	return (
		<motion.div
			whileHover={{ scale: 1.0 }}
			whileTap={{ scale: 0.98 }}
			className="border border-gray-200 bg-white rounded-xl p-8 shadow-md w-full mx-auto relative"
		>
			{title === 'Quest Games Optimizer' && (
				<div
					className={`flex flex-row items-center gap-2 absolute top-5 right-5 ${isEligible ? 'bg-[#0081FB]' : 'bg-red-500'} px-3 py-2 rounded-xl`}
				>
					{isEligible ? (
						<div className="flex items-center text-white">
							<Icon icon="line-md:check-all" fontSize={24} />
							<span className="ml-2 font-semibold">
								{language === 'en' ? 'You have access' : 'Anda memiliki akses'}
							</span>
						</div>
					) : (
						<div className="flex items-center text-white">
							<Icon icon="line-md:cancel" fontSize={24} />
							<span className="ml-2 font-semibold">
								{language === 'en'
									? 'You do not have access'
									: 'Anda tidak memiliki akses'}
							</span>
						</div>
					)}
				</div>
			)}
			<div className="flex flex-col items-center gap-4 relative h-full">
				<img src={logo} alt={`${title} Logo`} className="h-20" />
				<h3 className="text-xl font-semibold text-gray-800 text-center">
					{title}
				</h3>
				<p className="text-sm text-white bg-[#0081FB] rounded-lg px-4 py-2 font-semibold text-center">
					{description}
				</p>

				{isEligible && (
					<div className="grid grid-cols-2 gap-3 mt-4 w-full">
						{downloads.map((download) => (
							<Link
								key={download.link}
								to={download.link}
								target="_blank"
								rel="noopener noreferrer"
							>
								<motion.button
									whileHover={{
										backgroundColor: '#2563eb',
										color: '#fff',
										scale: 1.02,
									}}
									className="flex items-center justify-center p-3 border rounded-lg bg-gray-100 w-full transition-all"
								>
									{download.icon}
									<span className="ml-2 text-sm">{download.text}</span>
								</motion.button>
							</Link>
						))}
					</div>
				)}

				{!isEligible && title === 'Quest Games Optimizer' && (
					<div className="h-full w-full flex items-center justify-center bg-red-50 border-2 border-red-400 text-red-500 text-md font-semibold rounded-xl border-dashed">
						Silahkan membeli Produk terkait untuk mendapatkan akses ke Quest
						Games Optimizer!
					</div>
				)}
			</div>
		</motion.div>
	)
}

const SoftwareHelper = () => {
	const language = localStorage.getItem('language') || 'id'
	const [isEligible, setIsEligible] = useState(false)

	const translations = {
		en: {
			info: 'Info',
			softwareHelperInfo:
				'This page contains essential software to manage files and applications for your VR Quest device.',
			officialFromSideQuestWebsite: 'Official from SideQuest Website',
			officialFromWinRARWebsite: 'Official from WinRAR Website',
			forInstallerHelper: 'FOR INSTALLING APPS/GAMES TO QUEST',
			forExtracterHelper: 'FOR EXTRACTING .RAR/.ZIP FILES',
			forOptimizerHelper: 'FOR OPTIMIZING GAME PERFORMANCE ON QUEST',
			softwareHelper: 'Software Helper',
			vrGames: 'VR Games',
		},
		id: {
			info: 'Info',
			softwareHelperInfo:
				'Halaman ini berisi Software yang penting untuk mengelola file dan aplikasi untuk perangkat VR Quest Anda.',
			officialFromSideQuestWebsite: 'Resmi dari Situs Web SideQuest',
			officialFromWinRARWebsite: 'Resmi dari Situs Web WinRAR',
			forInstallerHelper: 'UNTUK MENGINSTAL APLIKASI/GAME KE QUEST',
			forExtracterHelper: 'UNTUK MENGEKSTRAK FILE .RAR/.ZIP',
			forOptimizerHelper: 'UNTUK MENGOPTIMALKAN PERFORMA GAME DI QUEST',
			softwareHelper: 'Software Pembantu',
			vrGames: 'Game VR',
		},
	}

	const sideQuestDownloads = [
		{
			link: 'https://github.com/SideQuestVR/SideQuest/releases/download/v0.10.42/SideQuest-Setup-0.10.42-x64-win.exe',
			icon: <FaWindows size={20} />,
			text: 'Windows 10 x64 - v0.10.42 / 100.45MB',
		},
		{
			link: 'https://github.com/SideQuestVR/SideQuest/releases/download/v0.10.42/SideQuest-0.10.42.dmg',
			icon: <FaApple size={20} />,
			text: 'OS X / MacOS 10.12+ - v0.10.42 / 126.82MB',
		},
		{
			link: 'https://github.com/SideQuestVR/SideQuest/releases/download/v0.10.42/SideQuest-0.10.42-arm64.dmg',
			icon: <FaApple size={20} />,
			text: 'OS X / MacOS 10.12+ (M1/ARM) - v0.10.42 / 120.48MB',
		},
		{
			link: 'https://github.com/SideQuestVR/SideQuest/releases/download/v0.10.42/SideQuest-0.10.42.tar.xz',
			icon: <FaLinux size={20} />,
			text: 'Linux - v0.10.42 / 93.65MB',
		},
	]

	const winRARDownloads = [
		{
			link: 'https://www.win-rar.com/fileadmin/winrar-versions/winrar/winrar-x64-701.exe',
			icon: <FaWindows size={20} />,
			text: 'Windows - 64 Bit',
		},
		{
			link: 'https://www.win-rar.com/fileadmin/winrar-versions/winrar/winrar-x32-701.exe',
			icon: <FaWindows size={20} />,
			text: 'Windows - 32 Bit',
		},
		{
			link: 'https://www.win-rar.com/fileadmin/winrar-versions/rarmacos-arm-701.tar.gz',
			icon: <FaApple size={20} />,
			text: 'macOS - ARM',
		},
		{
			link: 'https://www.win-rar.com/fileadmin/winrar-versions/rarmacos-x64-701.tar.gz',
			icon: <FaApple size={20} />,
			text: 'macOS - x64 64 Bit',
		},
	]

	const questGamesOptimizerDownloads = [
		{
			link: 'https://drive.google.com/file/d/1r9x-UF861cM_m-T-hcy9uzPwSzVBJ2uY/view?usp=drive_link',
			icon: <FaDownload size={20} />,
			text: 'Quest Games Optimizer v11.2.1.apk',
		},
	]

	const [isMobile, setIsMobile] = useState(window.innerWidth < 1200)

	useEffect(() => {
		const handleResize = () => {
			setIsMobile(window.innerWidth < 1200)
		}

		window.addEventListener('resize', handleResize)

		return () => {
			window.removeEventListener('resize', handleResize)
		}
	}, [])

	const eligibleUsersRef = fireDb.ref('eligibleUsers/qgo')
	const [user, setUser] = useState(null)
	const [eligibleQGO, setEligibleQGO] = useState(false)

	useEffect(() => {
		const unsubscribe = auth.onAuthStateChanged(async (user) => {
			if (user) {
				setUser(user)
				try {
					const snapshot = await eligibleUsersRef
						.orderByChild('email')
						.equalTo(user.email)
						.once('value')
					const eligibleUsers = snapshot.val()
					if (eligibleUsers) {
						setEligibleQGO(true)
					} else {
						setEligibleQGO(false)
					}
				} catch (error) {
					console.error('Error fetching eligible users:', error.message)
				}
			} else {
				setUser(null)
			}
		})

		return () => {
			unsubscribe()
		}
	}, [])

	return (
		<div
			className={`w-full min-h-screen pt-10 pb-10 ${!isMobile && 'pt-[75px]'} antialiased`}
		>
			<header
				className="bg-gradient-to-r from-[#0881FB] to-[#0059b2] py-12 text-center text-white px-6 sm:px-10"
				style={{
					background: `linear-gradient(to left, rgba(8, 129, 251, 0.95) 10%, rgba(0, 89, 178, 0.95) 100%), url(${bgPage}) center/cover`,
				}}
			>
				<h1 className="text-3xl md:text-4xl font-bold tracking-wide">
					{translations[language].softwareHelper}
				</h1>
				<p className="text-base md:text-lg mt-4">
					{translations[language].softwareHelperInfo}
				</p>
			</header>

			<div className="px-2 sm:px-4 lg:px-6 py-8 grid grid-cols-1 md:grid-cols-2 gap-6 max-w-full mx-auto">
				<SoftwareCard
					logo={QGOLogo}
					title="Quest Games Optimizer"
					description={translations[language].forOptimizerHelper}
					downloads={questGamesOptimizerDownloads}
					isEligible={eligibleQGO}
				/>

				<SoftwareCard
					logo={SideQuestLogo}
					title="SideQuest (Advanced Installer)"
					description={translations[language].forInstallerHelper}
					downloads={sideQuestDownloads}
					isEligible={true}
				/>

				<SoftwareCard
					logo={WinRARLogo}
					title="WinRAR 7.01"
					description={translations[language].forExtracterHelper}
					downloads={winRARDownloads}
					isEligible={true}
				/>
			</div>

			<Helmet>
				<title>
					{translations[language].softwareHelper} |{' '}
					{translations[language].vrGames} - Hypertopia
				</title>
			</Helmet>
		</div>
	)
}

export default SoftwareHelper
