import React, { useState, useEffect } from 'react'
import { useDarkModeContext } from '../../../../DarkModeContext'
import { FaHeart, FaMemory, FaPlus, FaRegHeart } from 'react-icons/fa6'
import { FaDownload } from 'react-icons/fa6'
import { BsFillCpuFill, BsGpuCard } from 'react-icons/bs'
import { MdFileDownload, MdSdStorage } from 'react-icons/md'
import fireDb from '../../../../firebase'
import { auth } from '../../../../firebase'
import { FaCheck, FaInfoCircle, FaTimes } from 'react-icons/fa'
import { getDownloadURL, listAll } from 'firebase/storage'
import { storage } from '../../../../firebase'
import { ref } from 'firebase/storage'
import { LazyLoadImage } from 'react-lazy-load-image-component'
import Swal from 'sweetalert2'

const formatDownloadCount = (count) => {
	if (count >= 1000000) {
		return `${(count / 1000000).toFixed(1)}M`
	} else if (count >= 1000) {
		return `${(count / 1000).toFixed(1)}K`
	}
	return count
}

const formatLikedGamesCount = (count) => {
	if (count >= 1000000) {
		return `${(count / 1000000).toFixed(1)}M`
	} else if (count >= 1000) {
		return `${(count / 1000).toFixed(1)}K`
	}
	return count
}

const ListGamesCard_PCVR = ({ game, layout, order }) => {
	const {
		gameTitle,
		linkDownload,
		gameVersion,
		gameSize,
		gameStatus,
		downloadCount,
		timeAdded,
		likedCount,
		recommendedProcessor,
		recommendedGraphicsCard,
		recommendedMemory,
	} = game
	const { isDarkMode } = useDarkModeContext()
	const language = localStorage.getItem('language') || 'id'
	const [user, setUser] = useState(null)
	const [showAnnouncement, setShowAnnouncement] = useState(false)
	const [showReportPopup, setShowReportPopup] = useState(false)
	const eligibleUsersRef = fireDb.ref('eligibleUsers/pcvr')
	const imageListRef = ref(storage, 'coverGamesImages/')
	const [gameBackgroundUrl, setGameBackgroundUrl] = useState(null)
	const [loadingImages, setLoadingImages] = useState(true)

	const translations = {
		en: {
			orGreater: 'or greater',
			recommendedSpec: 'Recommended Specifications',
			allQuests: 'All Quests',
			requireToDownloadAllParts:
				'You must download all parts to extract the game!',

			report: 'Report',
			download: 'Download',
			notAvailable: 'Not Available. Game will be added gradually.',
			newAdded: 'NEW',
			notLogin: 'Please login first!',
			notEligible: 'You are not eligible to access the download link.',
			readyButNotEligible: 'Available, but do not have access to download!',
			update: 'UPDATE',
			comingSoon: 'COMING SOON',
			details: 'Details',
			requestGame: 'Request Game',

			requested: 'Requested',
			fileNoLonger: 'The file is no longer in Google Drive',
			gameNotLaunching: 'Game Not Launching',
			gameStuckAtLoadingScreen: 'Game Stuck at Loading Screen',
			gameCrashing: 'Game Crashing',
			gamePerformanceIssues: 'Game Performance Issues',
			gameVisualGlitches: 'Game Visual Glitches',
			others: 'Others',

			cancel: 'Cancel',
			submit: 'Submit',

			downloaded: 'Download',
			likes: 'Likes',
		},
		id: {
			orGreater: 'atau lebih tinggi',
			recommendedSpec: 'Rekomendasi Spesifikasi',
			allQuests: 'Semua Quests',
			requireToDownloadAllParts:
				'Anda wajib mengunduh semua part untuk mengekstrak game!',

			report: 'Laporkan',
			download: 'Unduh',
			notAvailable:
				'Tidak Tersedia. Silahkan bisa request game ini di halaman Game VR > Request Game.',
			newAdded: 'BARU',
			notLogin: 'Silahkan login terlebih dahulu!',
			notEligible: 'Anda tidak memenuhi syarat untuk mengakses link download.',
			readyButNotEligible: 'Tersedia, namun tidak memiliki akses untuk unduh!',
			update: 'UPDATE',
			comingSoon: 'SEGERA HADIR',
			details: 'Detail',
			requestGame: 'Request Game',

			requested: 'Telah Direquest',
			fileNoLonger: 'File tidak lagi tersedia di Google Drive',
			gameNotLaunching: 'Game Tidak Bisa Dijalankan',
			gameStuckAtLoadingScreen: 'Game Tersangkut di Loading Screen',
			gameCrashing: 'Game Sering Crash',
			gamePerformanceIssues: 'Game Mengalami Masalah Performa',
			gameVisualGlitches: 'Game Mengalami Glitch Visual',
			others: 'Lainnya',

			reportProblem: 'Laporkan Masalah',
			areYouSureReportProblem:
				'Apakah Anda yakin ingin melaporkan masalah ini?',
			reportProblemSubmitted: 'Laporan Masalah Telah Terkirim.',
			thanksForReportProblem:
				'Terima kasih atas laporan masalah yang Anda kirimkan.',
			reportProblemFailed: 'Gagal Mengirim Laporan Masalah.',
			somethingWentWrong:
				'Terjadi kesalahan saat mengirim laporan masalah. Silakan coba lagi.',

			cancel: 'Batal',
			submit: 'Kirim',

			downloaded: 'Unduhan',
			likes: 'Suka',
		},
	}

	const [isGameRequested, setIsGameRequested] = useState(false)

	useEffect(() => {
		const unsubscribe = auth.onAuthStateChanged(async (user) => {
			if (user) {
				setUser(user)
				try {
					const snapshot = await eligibleUsersRef
						.orderByChild('email')
						.equalTo(user.email)
						.once('value')
					const eligibleUsers = snapshot.val()
					if (eligibleUsers) {
						setShowAnnouncement(false)
					} else {
						setShowAnnouncement(true)
					}
				} catch (error) {
					console.error('Error fetching eligible users:', error.message)
				}
			} else {
				setUser(null)
			}
		})

		return () => {
			unsubscribe()
		}
	}, [])

	const requestedGameRef_WithoutGameTitle = fireDb.ref(`requestedVRGames`)

	useEffect(() => {
		const requestedGameRef = fireDb.ref(`requestedVRGames/${game.gameTitle}`)
		requestedGameRef
			.once('value')
			.then((snapshot) => {
				const requestedGame = snapshot.val()

				if (requestedGame) {
					setIsGameRequested(true)
				} else {
					setIsGameRequested(false)
				}
			})
			.catch((error) => {
				console.error('Error checking requested game:', error.message)
			})
	})

	const handleRequestGameClick = () => {
		if (!isGameRequested) {
			Swal.fire({
				title: 'Request Game',
				text: `Apakah Anda yakin ingin merequest game ${game.gameTitle}?`,
				icon: 'question',
				showCancelButton: true,
				confirmButtonText: 'Yes',
				cancelButtonText: 'No',
			}).then((result) => {
				if (result.isConfirmed) {
					const newRequestedGame = {
						gameTitle: game.gameTitle,
						gameType: 'pcvr',
						metaStoreLink: game.linkMetaStore,
						reason: '',
						requestedBy: user.email,
						status: 'Pending',
						timeRequested: new Date().toISOString(),
					}

					fireDb
						.ref(`requestedVRGames/${game.gameTitle}`)
						.set(newRequestedGame)
						.then(() => {
							setIsGameRequested(true)

							Swal.fire({
								title: 'Game Requested!',
								text: `Game ${game.gameTitle} telah direquest.`,
								icon: 'success',
								confirmButtonText: 'OK',
								showCancelButton: true,
								cancelButtonText: 'Lihat Request',
							}).then((result) => {
								if (result.isConfirmed) {
								} else if (result.dismiss === Swal.DismissReason.cancel) {
									window.location.href = '/vr-games/request-games'
								}
							})
						})
						.catch((error) => {
							Swal.fire({
								title: 'Error!',
								text: 'Terjadi kesalahan saat merequest game.',
								icon: 'error',
								confirmButtonText: 'OK',
							})
						})
				}
			})
		}
	}

	useEffect(() => {
		const fetchData = async () => {
			const cachedUrl = localStorage.getItem(`gameBackgroundUrl_${gameTitle}`)
			const lastFetchTime = localStorage.getItem(`lastFetchTime_${gameTitle}`)
			if (!cachedUrl || Date.now() - parseInt(lastFetchTime) > 3600000) {
				try {
					const response = await listAll(imageListRef)
					const gameImageName = `${gameTitle}`
					const userImage = response.items.find(
						(item) => item.name === gameImageName
					)
					const unknownImage = response.items.find(
						(item) => item.name === 'UnknownPicture'
					)
					if (userImage) {
						const url = await getDownloadURL(userImage)
						setGameBackgroundUrl(url)
						setLoadingImages(false)
						localStorage.setItem(`gameBackgroundUrl_${gameTitle}`, url)
						localStorage.setItem(`lastFetchTime_${gameTitle}`, Date.now())
					} else {
						const url = await getDownloadURL(unknownImage)
						setGameBackgroundUrl(url)
						setLoadingImages(false)
					}
				} catch (error) {
					console.error('Error fetching game background URL:', error.message)
				}
			} else {
				setGameBackgroundUrl(cachedUrl)
				setLoadingImages(false)
			}
		}

		fetchData()
	}, [gameTitle, imageListRef, window.location.pathname])

	const handleDownloadClick = () => {
		if (linkDownload.includes('\n')) {
			setShowDownloadPopup(true)
		} else {
			const updatedCount = downloadCount + 1
			fireDb
				.ref(`vrGames/standalone/${gameTitle}/downloadCount`)
				.set(updatedCount)
			window.open(linkDownload, '_blank')
		}
	}

	const [savingDataMode, setSavingDataMode] = useState(
		localStorage.getItem('dataSaver')
	)

	useEffect(() => {
		localStorage.setItem('dataSaver', savingDataMode)
	}, [savingDataMode])

	const [showDownloadPopup, setShowDownloadPopup] = useState(false)

	const opts = {
		height: '200',
		width: '355',
		playerVars: {
			showinfo: 0,
		},
	}

	const today = new Date()
	today.setHours(23, 59, 59, 999)

	const startOfWeek = new Date(today)
	startOfWeek.setDate(today.getDate() - 6)

	const endOfWeek = new Date(today)
	endOfWeek.setDate(today.getDate())

	useEffect(() => {
		const today = new Date()
		today.setHours(23, 59, 59, 999)

		const startOfWeek = new Date(today)
		startOfWeek.setDate(today.getDate() - 6)

		const endOfWeek = new Date(today)
		endOfWeek.setDate(today.getDate())
	}, [])

	const addedDate = new Date(timeAdded)

	const requestedBySensor = (str, maxLength) => {
		if (str && str.length > maxLength) {
			return str.substring(0, maxLength) + '...'
		}
		return str
	}

	const [reportType, setReportType] = useState('')
	const [customReport, setCustomReport] = useState('')

	const handleReportTypeChange = (event) => {
		setReportType(event.target.value)
	}

	const handleCustomReportChange = (event) => {
		setCustomReport(event.target.value)
	}

	const handleReportSubmit = () => {
		let submittedReport = reportType

		if (reportType === 'custom' && customReport.trim() !== '') {
			submittedReport = customReport
		}

		if (submittedReport) {
			Swal.fire({
				title: translations[language].reportProblem,
				text: translations[language].areYouSureReportProblem,
				icon: 'question',
				showCancelButton: true,
				confirmButtonText: 'Yes',
				cancelButtonText: 'No',
			}).then((result) => {
				if (result.isConfirmed) {
					const newReport = {
						gameTitle: gameTitle,
						gameType: 'standalone',
						reportType: submittedReport,
						reportedBy: user.email,
						timeReported: new Date().toISOString(),
					}

					fireDb
						.ref(`reportedGames/${gameTitle}`)
						.set(newReport)
						.then(() => {
							setShowReportPopup(false)
							Swal.fire({
								title: translations[language].reportProblemSubmitted,
								text: translations[language].thanksForReportProblem,
								icon: 'success',
								confirmButtonText: 'OK',
							})
						})
						.catch((error) => {
							console.error('Error submitting report:', error.message)
							Swal.fire({
								title: translations[language].reportProblemFailed,
								text: translations[language].somethingWentWrong,
								icon: 'error',
								confirmButtonText: 'OK',
							})
						})
				}
			})
		} else {
			console.error('Please select a report type or provide a custom report.')
			Swal.fire({
				title: translations[language].reportProblemFailed,
				text: translations[language].somethingWentWrong,
				icon: 'error',
				confirmButtonText: 'OK',
			})
		}
	}

	const [isLiked, setIsLiked] = useState(false)

	useEffect(() => {
		const checkLikedGamesStatus = async () => {
			const userLikedGamesRef = fireDb.ref(
				`usersData/likedGames/${user.uid}/${gameTitle}`
			)
			const snapshot = await userLikedGamesRef.once('value')
			const isLiked = snapshot.exists()
			setIsLiked(isLiked)
		}

		if (user) {
			checkLikedGamesStatus()
		}
	}, [user, gameTitle])

	const handleLikedGamesToggle = () => {
		if (isLiked) {
			const userLikedGamesRef = fireDb.ref(
				`usersData/likedGames/${user.uid}/${gameTitle}`
			)
			userLikedGamesRef
				.remove()
				.then(() => {
					const updatedLikedCount = likedCount - 1
					fireDb
						.ref(`vrGames/standalone/${gameTitle}/likedCount`)
						.set(updatedLikedCount)
					setIsLiked(false)
					Swal.fire({
						icon: 'success',
						title: 'Removed from Liked Games',
						text: 'Game removed from Liked Games',
					})
				})
				.catch((error) => {
					console.error('Error removing from Liked Games:', error)
					Swal.fire({
						icon: 'error',
						title: 'Oops...',
						text: 'Failed to remove from Liked Games',
					})
				})
		} else {
			const userLikedGamesRef = fireDb.ref(
				`usersData/likedGames/${user.uid}/${gameTitle}`
			)
			userLikedGamesRef
				.set(game)
				.then(() => {
					const updatedLikedCount = likedCount + 1
					fireDb
						.ref(`vrGames/standalone/${gameTitle}/likedCount`)
						.set(updatedLikedCount)
					setIsLiked(true)
					Swal.fire({
						icon: 'success',
						title: 'Added to Liked Games',
						text: 'Game added to Liked Games',
					})
				})
				.catch((error) => {
					console.error('Error adding to Liked Games:', error)
					Swal.fire({
						icon: 'error',
						title: 'Oops...',
						text: 'Failed to add to Liked Games',
					})
				})
		}
	}

	const [showRecSpecs, setShowRecSpecs] = useState(true)

	const handleShowRecSpecs = () => {
		setShowRecSpecs(!showRecSpecs)
	}

	return (
		<div
			className={`flex flex-row gap-0 mt-2 w-full h-full rounded-2xl border-2 ${isDarkMode ? 'border-zinc-600' : 'border-zinc-200'} bg-white shadow-lg`}
		>
			<div className="flex flex-col w-full h-full">
				<div className="px-4 font-semibold flex flex-row justify-between items-center gap-2 w-full bg-white rounded-t-2xl py-2.5 h-[84px]">
					<span className="flex flex-row items-center gap-2">
						{/* <span className=" text-black h-fit min-w-[32px] text-center">
              {order}
            </span> */}
						{gameTitle}
						<div className="font-semibold">
							{addedDate >= startOfWeek &&
								addedDate <= endOfWeek &&
								gameStatus === 'new' && (
									<div className="text-nowrap px-2.5 py-1 bg-blue-500 text-white text-center rounded-full text-sm">
										{translations[language].newAdded}
									</div>
								)}
							{addedDate >= startOfWeek &&
								addedDate <= endOfWeek &&
								gameStatus === 'update' && (
									<div className="text-nowrap px-2.5 py-1 bg-yellow-500 text-white text-center rounded-full text-sm">
										{translations[language].update}
									</div>
								)}
							{gameStatus === 'soon' && (
								<div className="text-nowrap px-2.5 py-1 bg-green-500 text-white text-center rounded-full text-sm">
									{translations[language].comingSoon}
								</div>
							)}
						</div>
					</span>

					{/* <span className={`h-full flex flex-row flex-nowrap gap-2 items-center ${isLiked ? 'p-2.5 bg-red-500 rounded-xl' : 'bg-gray-200 rounded-xl p-2.5 hover:bg-red-100 hover:text-red-500'}`}>
            <button onClick={handleLikedGamesToggle}>
              {isLiked ? <FaHeart className="text-white outline-none" /> : <FaRegHeart className="outline-none" />}
            </button>
          </span> */}

					<span
						className={`h-full flex flex-row flex-nowrap gap-2 items-center ${isLiked ? '' : 'rounded-xl hover:text-red-500'}`}
					>
						<button onClick={handleLikedGamesToggle}>
							{isLiked ? (
								<FaHeart className="text-red-500 outline-none" size={20} />
							) : (
								<FaRegHeart className="outline-none" size={20} />
							)}
						</button>
					</span>
				</div>
				<span className="w-full px-4" onContextMenu={(e) => e.preventDefault()}>
					{loadingImages && !gameBackgroundUrl ? (
						<div className="loading-overlay h-32 bg-white w-full">
							<div className="spinner"></div>
						</div>
					) : (
						<LazyLoadImage
							src={gameBackgroundUrl}
							alt={gameTitle}
							className="w-full object-cover h-32 rounded-2xl"
						/>
					)}
				</span>
				<div className={`w-full h-full min-w-[320px]`}>
					<div className="px-5 pt-4 flex flex-col gap-1 h-full">
						{game && layout && (
							<div className="mt-1 flex flex-col gap-2 w-full">
								{gameVersion && (
									<span
										className={`flex flex-row gap-2 items-center text-sm font-semibold`}
									>
										<span className="py-1.5 px-1.5 rounded-full bg-gradient-to-r from-yellow-400 to-yellow-600 text-white">
											<FaInfoCircle className="size-4" />
										</span>
										<span className="text-yellow-500">
											{gameVersion.replace('Mixed Reality', 'MR')}
										</span>
									</span>
								)}

								<span
									className={`flex flex-row gap-2 items-center text-sm font-semibold`}
								>
									<span className="py-1.5 px-1.5 rounded-full bg-gradient-to-r from-green-400 to-green-600 text-white">
										<MdFileDownload className="size-4" />
									</span>
									<span className="text-green-500">
										{formatDownloadCount(downloadCount)}{' '}
										{translations[language].downloaded}
									</span>
								</span>

								{gameSize && (
									<span
										className={`flex flex-row gap-2 items-center text-sm font-semibold`}
									>
										<span className="py-1.5 px-1.5 rounded-full bg-gradient-to-r from-cyan-400 to-cyan-600 text-white">
											<MdSdStorage className="size-4" />
										</span>
										<span className="text-cyan-500">{gameSize}</span>
									</span>
								)}

								{likedCount >= 0 && (
									<span
										className={`flex flex-row gap-2 items-center text-sm font-semibold`}
									>
										<span className="py-1.5 px-1.5 rounded-full bg-gradient-to-r from-red-400 to-red-600 text-white">
											<FaHeart className="size-4" />
										</span>
										<span className="text-red-500">
											{formatLikedGamesCount(likedCount)}{' '}
											{translations[language].likes}
										</span>
									</span>
								)}

								<hr className="border-gray-300 my-2" />

								<span
									className={`flex flex-row gap-2 items-center text-sm font-semibold cursor-pointer hover:text-zinc-500`}
									onClick={handleShowRecSpecs}
								>
									<p>{translations[language].recommendedSpec}</p>
									{/* {showRecSpecs ? <FaCircleChevronUp size={20} /> : <FaCircleChevronDown size={20} />} */}
								</span>

								<div
									className={`flex flex-col gap-2 p-3 rounded-xl border-2 border-dashed border-zinc-300 ${showRecSpecs ? '' : 'hidden'}`}
								>
									{recommendedProcessor && (
										<span
											className={`flex flex-row gap-2 items-center text-sm font-semibold`}
										>
											<span className="py-1.5 px-1.5 rounded-full bg-gradient-to-r from-sky-400 to-sky-600 text-white">
												<BsFillCpuFill className="size-4" />
											</span>
											<span className="text-sky-500">
												{recommendedProcessor.replace(
													'equivalent or greater',
													translations[language].orGreater
												)}
											</span>
										</span>
									)}

									{recommendedGraphicsCard && (
										<span
											className={`flex flex-row gap-2 items-center text-sm font-semibold`}
										>
											<span className="py-1.5 px-1.5 rounded-full bg-gradient-to-r from-green-400 to-green-600 text-white">
												<BsGpuCard className="size-4" />
											</span>
											<span className="text-green-500">
												{recommendedGraphicsCard.replace(
													'or greater',
													translations[language].orGreater
												)}
											</span>
										</span>
									)}

									{recommendedMemory && (
										<span
											className={`flex flex-row gap-2 items-center text-sm font-semibold`}
										>
											<span className="py-1.5 px-1.5 rounded-full bg-gradient-to-r from-amber-400 to-amber-600 text-white">
												<FaMemory className="size-4" />
											</span>
											<span className="text-amber-500">
												{recommendedMemory} RAM
											</span>
										</span>
									)}
								</div>
							</div>
						)}
					</div>
				</div>

				{user && showAnnouncement === false && (
					<>
						<div className="flex flex-row items-center justify-end w-full h-fit p-4">
							{linkDownload !== '' ? (
								<div className="flex items-center justify-center w-full h-full gap-2">
									<button
										onClick={handleDownloadClick}
										className={`px-4 h-full bg-gradient-to-br from-blue-300 to-blue-600 hover:from-blue-500 hover:to-blue-700 transition-colors w-full py-2 duration-300 flex items-center gap-2 justify-center font-semibold text-white rounded-xl`}
									>
										<span>{translations[language].download}</span>
										<FaDownload />
									</button>
								</div>
							) : isGameRequested ? (
								<button
									className="bg-gradient-to-br from-gray-200 to-gray-400 h-full px-4 text-center text-gray-700 cursor-not-allowed transition-colors w-full py-2 duration-300 flex items-center gap-2 justify-center font-semibold rounded-xl"
									disabled
								>
									<span>{translations[language].requested}</span>
									<FaCheck />
								</button>
							) : (
								<button
									className="bg-gradient-to-br from-green-400 to-green-600 hover:from-green-500 hover:to-green-700 h-full px-4 text-center text-white transition-colors w-full py-2 duration-300 flex items-center gap-2 justify-center font-semibold rounded-xl"
									onClick={handleRequestGameClick}
								>
									<span>{translations[language].requestGame}</span>
									<FaPlus />
								</button>
							)}
						</div>

						{showDownloadPopup && (
							<div className="fixed top-0 left-0 w-svw h-full bg-black bg-opacity-70 flex items-center justify-center -ml-2 z-50 backdrop-blur-sm">
								<div className="bg-white p-4 m-4 rounded-xl relative min-w-[300px] max-w-[800px]">
									<div className="flex justify-between items-center mb-3">
										<span className="flex flex-col gap-2">
											<p className="text-xl font-semibold text-left">
												{gameTitle}
											</p>
											<p className="text-sm text-left text-red-500">
												{translations[language].requireToDownloadAllParts}
											</p>
										</span>
										<div className="absolute top-0 right-0 justify-center flex gap-3 mt-[-20px] mr-[-20px]">
											<button
												onClick={() => setShowDownloadPopup(false)}
												className="bg-red-500 w-12 h-12 hover:bg-red-700 text-white p-3 rounded-full flex items-center justify-center text-xl transition-all focus:outline-none"
											>
												<FaTimes />
											</button>
										</div>
									</div>
									<hr className="border-gray-300 mb-4" />
									{linkDownload.split('\n').map((downloadLink, index) => (
										<div key={index} className="mb-2 w-full">
											<a
												href={downloadLink}
												target="_blank"
												rel="noopener noreferrer"
												onClick={() => {
													const updatedCount = downloadCount + 1
													fireDb
														.ref(
															`vrGames/standalone/${gameTitle}/downloadCount`
														)
														.set(updatedCount)
												}}
												className={`border-2 rounded-xl p-2 text-sm w-full hover:bg-[#0881FB] hover:text-white transition-colors flex items-center gap-2 justify-center`}
											>
												<span>Part {index + 1}</span>
												<FaDownload />
											</a>
										</div>
									))}
								</div>
							</div>
						)}

						{showReportPopup && (
							<div className="fixed top-0 left-0 w-full h-full bg-black bg-opacity-70 flex items-center justify-center -ml-2 z-50 backdrop-blur-sm">
								<div className="bg-white p-4 m-4 rounded-xl relative min-w-[300px] max-w-[800px]">
									<div className="flex justify-between items-center mb-4">
										<div className="flex flex-col">
											<p className="text-lg font-semibold">
												{translations[language].report}
											</p>
											<p className="text-gray-400">{gameTitle}</p>
										</div>
										<div className="absolute top-0 right-0 justify-center flex gap-3 mt-[-20px] mr-[-20px]">
											<button
												onClick={() => setShowReportPopup(false)}
												className="bg-red-500 w-12 h-12 hover:bg-red-700 text-white p-3 rounded-full flex items-center justify-center text-xl transition-all focus:outline-none"
											>
												<FaTimes />
											</button>
										</div>
									</div>
									<hr className="border-gray-300 mb-4" />
									<div className="flex flex-col gap-2">
										<label className="flex items-center gap-2">
											<input
												type="radio"
												value="file_no_longer"
												checked={reportType === 'file_no_longer'}
												onChange={handleReportTypeChange}
											/>
											<span>{translations[language].fileNoLonger}</span>
										</label>

										<hr className="border-gray-300 my-4" />

										<label className="flex items-center gap-2">
											<input
												type="radio"
												value="game_not_launching"
												checked={reportType === 'game_not_launching'}
												onChange={handleReportTypeChange}
											/>
											<span>{translations[language].gameNotLaunching}</span>
										</label>
										<label className="flex items-center gap-2">
											<input
												type="radio"
												value="game_stuck_at_loading_screen"
												checked={reportType === 'game_stuck_at_loading_screen'}
												onChange={handleReportTypeChange}
											/>
											<span>
												{translations[language].gameStuckAtLoadingScreen}
											</span>
										</label>
										{/* Tambahkan opsi laporan lainnya sesuai kebutuhan */}
										<label className="flex items-center gap-2">
											<input
												type="radio"
												value="game_crashing"
												checked={reportType === 'game_crashing'}
												onChange={handleReportTypeChange}
											/>
											<span>{translations[language].gameCrashing}</span>
										</label>
										<label className="flex items-center gap-2">
											<input
												type="radio"
												value="game_performance_issues"
												checked={reportType === 'game_performance_issues'}
												onChange={handleReportTypeChange}
											/>
											<span>
												{translations[language].gamePerformanceIssues}
											</span>
										</label>
										<label className="flex items-center gap-2">
											<input
												type="radio"
												value="game_visual_glitches"
												checked={reportType === 'game_visual_glitches'}
												onChange={handleReportTypeChange}
											/>
											<span>{translations[language].gameVisualGlitches}</span>
										</label>
										<label className="flex items-center gap-2">
											<input
												type="radio"
												value="custom"
												checked={reportType === 'custom'}
												onChange={handleReportTypeChange}
											/>
											<span>{translations[language].others}</span>
										</label>
										{reportType === 'custom' && (
											<textarea
												value={customReport}
												onChange={handleCustomReportChange}
												placeholder="Masukkan laporan masalah kustom"
												className="border border-gray-300 rounded-md p-2"
											/>
										)}
									</div>
									<hr className="border-gray-300 mt-4" />
									<div className="mt-4 gap-2 flex items-center justify-center w-full">
										<button
											onClick={() => setShowReportPopup(false)}
											className="hover:bg-red-500 hover:text-white border-2 text-black px-4 py-2 rounded-xl w-full"
										>
											{translations[language].cancel}
										</button>
										<button
											onClick={handleReportSubmit}
											className="bg-blue-500 hover:bg-blue-600 text-white px-4 py-2 rounded-xl w-full"
										>
											{translations[language].submit}
										</button>
									</div>
								</div>
							</div>
						)}
					</>
				)}

				{user && showAnnouncement && (
					<div className="flex flex-row items-center justify-start w-full h-16 p-2">
						{linkDownload !== '' ? (
							<div className="flex items-center w-full h-full">
								<a
									className={`border-t-2 border-white p-3 h-full text-sm cursor-not-allowed bg-red-500 hover:bg-red-600 transition-colors w-full py-3 duration-300 flex items-center gap-2 justify-center font-semibold text-white text-center rounded-xl`}
									disabled
								>
									<span>{translations[language].readyButNotEligible}</span>
								</a>
							</div>
						) : isGameRequested ? (
							<button
								className="bg-gradient-to-br from-gray-200 to-gray-400 h-full px-4 text-center text-gray-700 cursor-not-allowed transition-colors w-full py-2 duration-300 flex items-center gap-2 justify-center font-semibold rounded-xl"
								disabled
							>
								<span>{translations[language].requested}</span>
								<FaCheck />
							</button>
						) : (
							<button
								className="bg-gradient-to-br from-green-400 to-green-600 hover:from-green-500 hover:to-green-700 h-full px-4 text-center text-white transition-colors w-full py-2 duration-300 flex items-center gap-2 justify-center font-semibold rounded-xl"
								onClick={handleRequestGameClick}
							>
								<span>{translations[language].requestGame}</span>
								<FaPlus />
							</button>
						)}
					</div>
				)}
			</div>

			<style>
				{`
        .loading-overlay {
          width: 100%;
          height: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
        }
        
        .spinner {
          border: 4px solid rgba(0, 0, 0, 0.3); /* warna putih dengan transparansi */
          border-top: 4px solid #000000; /* warna putih */
          border-radius: 50%;
          width: 40px;
          height: 40px;
          animation: spin 1s linear infinite;
        }
        
        @keyframes spin {
          0% { transform: rotate(0deg); }
          100% { transform: rotate(360deg); }
        }
        `}
			</style>
		</div>
	)
}

export default ListGamesCard_PCVR
