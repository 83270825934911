import React, { useState } from 'react'
import { FaTimes, FaExternalLinkAlt } from 'react-icons/fa'
import { motion } from 'framer-motion'
import { FaListUl } from 'react-icons/fa6'

const TutorialBeatSaber = () => {
	const [isModalOpen, setIsModalOpen] = useState(false)

	const toggleModal = () => setIsModalOpen(!isModalOpen)

	// Langkah-langkah tutorial dalam bentuk array
	const steps = [
		{
			title:
				'Pastikan kamu sudah mengaktifkan Developer Mode di Meta Quest dan sudah menginstal SideQuest Advanced di PC kamu.',
			description: (
				<>
					Jika belum, ikuti petunjuk di halaman Tutorial untuk mengaktifkan
					Developer Mode.
					<a
						href="/vr-games/tutorials#developer-mode"
						className="flex items-center gap-2 w-fit px-3 py-1.5 rounded-full bg-gradient-to-r from-[#0881FB] to-[#0059b2] text-white mt-2"
					>
						Cara Aktifkan Developer Mode
						<FaExternalLinkAlt />
					</a>{' '}
				</>
			),
		},
		{
			title:
				'Kunjungi halaman Tutorial dan unduh QuestPatcher dan Core Mods di Hypertopia.',
			description: (
				<>
					Unduh sesuai sistem operasi yang kamu gunakan (Windows, macOS, atau
					Linux). Setelah diunduh, jalankan QuestPatcher.{' '}
					<a
						href="/vr-games/tutorials#quest-patcher"
						className="flex items-center gap-2 w-fit px-3 py-1.5 rounded-full bg-gradient-to-r from-[#0881FB] to-[#0059b2] text-white mt-2"
					>
						Quest Patcher & Core Mods
						<FaExternalLinkAlt />
					</a>
				</>
			),
		},
		{
			title:
				'Pastikan kamu sudah memiliki versi BeatSaber yang dapat dimodifikasi.',
			description: (
				<>
					QuestPatcher tidak dapat digunakan untuk menurunkan versi (downgrade)
					BeatSaber, jadi pastikan kamu sudah menginstal versi yang
					direkomendasikan/diperlukan.
					<a
						href="/vr-games/list-games/standalone?keyword=Beat+Saber+%28Original%29+v1-37-0"
						className="flex items-center gap-2 w-fit px-3 py-1.5 rounded-full bg-gradient-to-r from-[#0881FB] to-[#0059b2] text-white mt-2"
					>
						Beat Saber (Original) v1.37.0_9064817954
						<FaExternalLinkAlt />
					</a>
				</>
			),
		},
		{
			title:
				'Jalankan QuestPatcher dan pilih BeatSaber dari daftar aplikasi di perangkat Meta Quest kamu.',
			description:
				'Pilih opsi Patch BeatSaber (atau pilih Repatch jika kamu sudah mem-patch sebelumnya).',
		},
		{
			title:
				'Pada bagian ModLoader, pastikan kamu memilih Scotland2 sebagai mod loader.',
			description: 'Ini untuk memuat mod di BeatSaber.',
		},
		{
			title:
				'Setelah BeatSaber ter-patch, instal Core Mods yang diperlukan dan mod lainnya.',
			description:
				'Pilih mod yang sesuai dengan versi dan preferensi kamu dari daftar QuestPatcher.',
		},
		{
			title:
				'Setelah proses instalasi selesai, buka BeatSaber di Meta Quest dan cek apakah mod yang terpasang sudah aktif.',
			description: 'Mod akan muncul di dalam menu mod dalam game.',
		},
	]

	return (
		<section className="py-8 mx-4 md:mx-10">
			<div className="max-w-screen-xl mx-auto">
				{/* Tombol untuk membuka modal */}
				<div className="w-full flex justify-center items-center">
					<button
						onClick={toggleModal}
						className="flex items-center gap-2 px-6 py-3 bg-gradient-to-r from-[#0881FB] to-[#0059b2] text-white rounded-xl transition duration-300 text-center text-sm md:text-base"
					>
						<FaListUl />
						Tutorial Pasang Mod BeatSaber di Meta Quest
					</button>
				</div>

				{/* Modal Pop-up */}
				{isModalOpen && (
					<motion.div
						className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50"
						initial={{ opacity: 0 }}
						animate={{ opacity: 1 }}
						exit={{ opacity: 0 }}
						transition={{ duration: 0.3 }}
						onClick={toggleModal} // Menambahkan event handler untuk menutup modal saat background diklik
					>
						<motion.div
							className="bg-white p-6 rounded-3xl shadow-xl max-w-2xl md:max-w-5xl w-full h-[75vh] md:h-[70vh] overflow-hidden relative mx-10"
							initial={{ y: -50 }}
							animate={{ y: 0 }}
							exit={{ y: 50 }}
							transition={{ duration: 0.3 }}
							onClick={(e) => e.stopPropagation()} // Mencegah klik di dalam modal menutup modal
						>
							<h2 className="text-2xl font-semibold text-gray-800 mb-4 text-center">
								Cara Pasang Mod BeatSaber di Meta Quest dengan QuestPatcher
							</h2>

							<hr className="my-4 border-t-2 border-gray-200" />

							{/* Konten Tutorial yang bisa digulir */}
							<div className="overflow-y-auto max-h-[60vh] md:max-h-[70vh]">
								<ol className="list-decimal pl-6 space-y-4 text-gray-600">
									{steps.map((step, index) => (
										<li key={index}>
											<strong>{step.title}</strong>
											<p>{step.description}</p>
										</li>
									))}
								</ol>
							</div>
						</motion.div>
					</motion.div>
				)}
			</div>
		</section>
	)
}

export default TutorialBeatSaber
