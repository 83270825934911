import React, { useState, useEffect } from 'react'
import fireDb from '../../firebase'
import { Helmet } from 'react-helmet'
import { motion } from 'framer-motion'
import bgPage from '../../assets/images/bg.jpg'

const translations = {
	id: {
		info: 'Info',
		leaderboardInfo:
			'Papan peringkat ini menampilkan game VR yang paling banyak diunduh oleh pengguna HyperTopia.',
		title: 'Papan Peringkat',
		vrGames: 'Game VR',

		rank: 'Peringkat',
		game: 'Game',
		downloads: 'Unduhan',
	},
	en: {
		info: 'Info',
		leaderboardInfo:
			'This leaderboard shows the VR games that are most downloaded by HyperTopia users.',
		title: 'Leaderboard',
		vrGames: 'VR Games',

		rank: 'Rank',
		game: 'Game',
		downloads: 'Downloads',
	},
}

const PAGE_SIZE = 20 // Number of games per page

const GameLeaderboardItem = ({ game, rank }) => {
	const { name, downloadCount } = game

	return (
		<motion.tr
			initial={{ opacity: 0, x: -20 }}
			animate={{ opacity: 1, x: 0 }}
			transition={{ duration: 0.3 }}
			className="bg-white hover:bg-blue-50 transition-colors duration-300"
		>
			<td className="py-3 px-6 border-b text-center font-semibold">{rank}</td>
			<td className="py-3 px-6 border-b">{name}</td>
			<td className="py-3 px-6 border-b text-center">{downloadCount}</td>
		</motion.tr>
	)
}

const GameLeaderboard = ({ games, currentPage, setCurrentPage, maxPages }) => {
	const language = localStorage.getItem('language') || 'id'

	const handleNextPage = () => {
		if (currentPage < maxPages - 1) {
			setCurrentPage((prevPage) => prevPage + 1)
		}
	}

	const handlePrevPage = () => {
		if (currentPage > 0) {
			setCurrentPage((prevPage) => prevPage - 1)
		}
	}

	const [isMobile, setIsMobile] = useState(window.innerWidth < 1200)

	useEffect(() => {
		const handleResize = () => {
			setIsMobile(window.innerWidth < 1200)
		}

		window.addEventListener('resize', handleResize)

		return () => {
			window.removeEventListener('resize', handleResize)
		}
	}, [])

	return (
		<div>
			<header
				className="bg-gradient-to-r from-[#0881FB] to-[#0059b2] py-12 text-center text-white px-6 sm:px-10"
				style={{
					background: `linear-gradient(to left, rgba(8, 129, 251, 0.95) 10%, rgba(0, 89, 178, 0.95) 100%), url(${bgPage}) center/cover`,
				}}
			>
				<h1 className="text-3xl md:text-4xl font-bold tracking-wide">
					{translations[language].title}
				</h1>
				<p className="text-base md:text-lg mt-4">
					{translations[language].leaderboardInfo}
				</p>
			</header>

			<div className="overflow-x-auto">
				<table className="w-full table-auto rounded-lg shadow-md">
					<thead>
						<tr className="bg-blue-100">
							<th className="py-3 px-6 border-b font-semibold text-gray-700">
								{translations[language].rank}
							</th>
							<th className="py-3 px-6 border-b font-semibold text-gray-700">
								{translations[language].game}
							</th>
							<th className="py-3 px-6 border-b font-semibold text-gray-700">
								{translations[language].downloads}
							</th>
						</tr>
					</thead>
					<tbody>
						{games.map((game, index) => (
							<GameLeaderboardItem
								key={index}
								game={game}
								rank={currentPage * PAGE_SIZE + index + 1}
							/>
						))}
					</tbody>
				</table>
			</div>
			<div className="flex justify-between mt-4">
				<button
					onClick={handlePrevPage}
					disabled={currentPage === 0}
					className="px-4 py-2 bg-blue-500 text-white font-semibold rounded-lg disabled:opacity-50"
				>
					Previous
				</button>
				<span>
					Page {currentPage + 1} of {maxPages}
				</span>
				<button
					onClick={handleNextPage}
					disabled={currentPage >= maxPages - 1}
					className="px-4 py-2 bg-blue-500 text-white font-semibold rounded-lg disabled:opacity-50"
				>
					Next
				</button>
			</div>
		</div>
	)
}

const LeaderboardGames = () => {
	const [games, setGames] = useState([])
	const [loading, setLoading] = useState(true)
	const [currentPage, setCurrentPage] = useState(0)

	const language = localStorage.getItem('language') || 'id'

	useEffect(() => {
		const gamesRef = fireDb.ref(`vrGames/standalone`)
		gamesRef.on('value', (snapshot) => {
			const gamesData = []
			snapshot.forEach((childSnapshot) => {
				const gameData = childSnapshot.val()
				const downloadCount = gameData.downloadCount

				if (gameData.gameTitle && gameData.gameTitle.trim() !== '') {
					gamesData.push({
						name: gameData.gameTitle,
						downloadCount: downloadCount,
					})
				}
			})

			gamesData.sort((a, b) => b.downloadCount - a.downloadCount)

			setGames(gamesData)
			setLoading(false)
		})

		return () => {
			gamesRef.off('value')
		}
	}, [])

	const maxPages = Math.ceil(games.length / PAGE_SIZE)
	const paginatedGames = games.slice(
		currentPage * PAGE_SIZE,
		(currentPage + 1) * PAGE_SIZE
	)

	const isMobile = window.innerWidth < 1200

	return (
		<div
			className={`pb-5 px-5 w-full min-h-screen ${!isMobile && 'pt-[75px]'}`}
		>
			<Helmet>
				<title>
					{translations[language].title} | {translations[language].vrGames} -
					HyperTopia
				</title>
			</Helmet>
			{loading ? (
				<div className="loading-overlay">
					<motion.div
						initial={{ opacity: 0 }}
						animate={{ opacity: 1 }}
						transition={{ duration: 0.5 }}
						className="spinner"
					></motion.div>
				</div>
			) : (
				<GameLeaderboard
					games={paginatedGames}
					currentPage={currentPage}
					setCurrentPage={setCurrentPage}
					maxPages={maxPages}
				/>
			)}
			<style>
				{`
					.loading-overlay {
						position: fixed;
						top: 0;
						left: 0;
						width: 100%;
						height: 100%;
						background-color: rgba(0, 0, 0, 0.5);
						display: flex;
						justify-content: center;
						align-items: center;
					}
					.spinner {
						border: 4px solid rgba(255, 255, 255, 0.3);
						border-top: 4px solid #ffffff;
						border-radius: 50%;
						width: 40px;
						height: 40px;
						animation: spin 1s linear infinite;
					}
					@keyframes spin {
						0% { transform: rotate(0deg); }
						100% { transform: rotate(360deg); }
					}
				`}
			</style>
		</div>
	)
}

export default LeaderboardGames
