import React, { useState, useEffect } from 'react'
import axios from 'axios'
import { useLocation } from 'react-router-dom'

const GoogleDriveAuth = () => {
	const [fileId, setFileId] = useState('15NP47is4VxgGpZwRHVo82kgRA7BISqUV')
	const [downloadProgress, setDownloadProgress] = useState(0)
	const location = useLocation()

	// Fungsi untuk mendapatkan URL otentikasi dari server
	const getAuthUrl = async () => {
		try {
			const response = await axios.get(
				'http://localhost:3002/api/google_auth_url'
			)
			// Redirect pengguna ke URL otentikasi
			window.location.href = response.data.authUrl
		} catch (error) {
			console.error('Failed to get auth URL', error)
		}
	}

	// Fungsi untuk menangani pengiriman kode otorisasi ke backend
	const handleCodeSubmit = async (code) => {
		try {
			const response = await axios.post(
				'http://localhost:3002/api/google_api',
				{ code, fileId },
				{
					onDownloadProgress: (progressEvent) => {
						let percent = Math.floor(
							(progressEvent.loaded * 100) / progressEvent.total
						)
						setDownloadProgress(percent)
					},
					responseType: 'blob', // Untuk menerima file dalam bentuk Blob
				}
			)
			const fileBlob = response.data
			const fileURL = URL.createObjectURL(fileBlob)
			const a = document.createElement('a')
			a.href = fileURL
			a.download = 'file_downloaded'
			document.body.appendChild(a)
			a.click()
			a.remove()
		} catch (error) {
			console.error('Failed to fetch file data', error)
		}
	}

	// Mengambil kode dari URL saat halaman dimuat
	useEffect(() => {
		const params = new URLSearchParams(location.search)
		const code = params.get('code')

		if (code) {
			handleCodeSubmit(code)
		}
	}, [location.search])

	return (
		<div>
			<h1>Google Drive OAuth2 Authentication</h1>

			<button onClick={getAuthUrl}>Get Auth URL</button>

			<div>
				<input
					type="text"
					placeholder="Enter Google Drive file ID"
					value={fileId}
					onChange={(e) => setFileId(e.target.value)}
				/>
			</div>

			{downloadProgress > 0 && (
				<div>
					<p>Download Progress: {downloadProgress}%</p>
					<progress value={downloadProgress} max="100"></progress>
				</div>
			)}
		</div>
	)
}

export default GoogleDriveAuth
