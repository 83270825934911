import React, { useState, useEffect, useRef } from 'react'
import { BrowserRouter as Router, useNavigate } from 'react-router-dom'
import { Helmet } from 'react-helmet'
import { useDarkModeContext } from '../DarkModeContext'
import fireDb from '../firebase'
import { auth } from '../firebase'
import {
	FaArrowLeft,
	FaCartShopping,
	FaExclamation,
	FaRankingStar,
} from 'react-icons/fa6'
import {
	FaHistory,
	FaTimes,
	FaUserCircle,
	FaArrowRight,
	FaHeart,
	FaDownload,
	FaCheckCircle,
	FaSignOutAlt,
} from 'react-icons/fa'
import { BsArchiveFill, BsHeadsetVr } from 'react-icons/bs'
import bgPage from '../assets/images/bg.jpg'
import { getDownloadURL, listAll } from 'firebase/storage'
import { storage } from '../firebase'
import { signOut } from 'firebase/auth'
import { ref } from 'firebase/storage'
import Swal from 'sweetalert2'

const Dashboard = () => {
	const [user, setUser] = useState(null) // State untuk menyimpan informasi pengguna
	const { isDarkMode } = useDarkModeContext()
	const language = localStorage.getItem('language') || 'id'
	const [margin, setMargin] = useState(false)
	const [timeOfDay, setTimeOfDay] = useState('') // Menyimpan waktu hari berdasarkan waktu pengguna
	const [currentTime, setCurrentTime] = useState('') // Menyimpan waktu saat ini

	const eligibleUsersRef = fireDb.ref('eligibleUsers/standalone')
	const eligibleUsersStandaloneRef = fireDb.ref('eligibleUsers/standalone')
	const eligibleUsersPCVRRef = fireDb.ref('eligibleUsers/pcvr')

	const [showAnnouncement, setShowAnnouncement] = useState(false)
	const [showWhatsNew, setShowWhatsNew] = useState(false)

	const whatsNewRef = useRef(null)

	const [orders, setOrders] = useState([])
	const [cart, setCart] = useState([])

	const [standaloneGames, setStandaloneGames] = useState([])
	const [lengthStandaloneGames, setLengthStandaloneGames] = useState(0)
	const [pcvrGames, setPCVRGames] = useState([])
	const [lengthPCVRGames, setLengthPCVRGames] = useState(0)

	const [loadingOrders, setLoadingOrders] = useState(true)
	const [loadingCart, setLoadingCart] = useState(true)
	const [loadingGames, setLoadingGames] = useState(true)
	const [loadingWeather, setLoadingWeather] = useState(true)

	const [eligibleStandaloneGameQuest, setEligibleStandaloneGameQuest] =
		useState(false)
	const [eligiblePCVRGameQuest, setEligiblePCVRGameQuest] = useState(false)

	useEffect(() => {
		const unsubscribe = auth.onAuthStateChanged(async (user) => {
			if (user) {
				setUser(user)
				try {
					const snapshot = await eligibleUsersRef
						.orderByChild('email')
						.equalTo(user.email)
						.once('value')
					const eligibleUsers = snapshot.val()
					if (eligibleUsers) {
						setShowAnnouncement(false)
					} else {
						setShowAnnouncement(true)
					}
				} catch (error) {
					console.error('Error fetching eligible users:', error.message)
				}
			} else {
				setUser(null)
			}
		})

		return () => {
			unsubscribe()
		}
	}, [showAnnouncement, eligibleUsersRef, user])

	useEffect(() => {
		setShowWhatsNew(true)
	}, [])

	useEffect(() => {
		const standaloneGamesRef = fireDb.ref(`vrGames/standalone`)
		standaloneGamesRef.on('value', (snapshot) => {
			if (snapshot.val() !== null) {
				const gamesData = Object.values(snapshot.val())
				setStandaloneGames(gamesData)
				setLoadingGames(false)
			} else {
				setStandaloneGames([])
			}
		})

		return () => {
			standaloneGamesRef.off('value')
		}
	}, [])

	useEffect(() => {
		const pcvrGamesRef = fireDb.ref(`vrGames/pcvr`)
		pcvrGamesRef.on('value', (snapshot) => {
			if (snapshot.val() !== null) {
				const gamesData = Object.values(snapshot.val())
				setPCVRGames(gamesData)
				setLoadingGames(false)
			} else {
				setPCVRGames([])
			}
		})

		return () => {
			pcvrGamesRef.off('value')
		}
	}, [])

	const [gridColsVRGamesMenu, setGridColsVRGamesMenu] = useState('grid-cols-1')

	useEffect(() => {
		const handleResize = () => {
			if (window.innerWidth >= 800) {
				setGridColsVRGamesMenu('grid-cols-2')
			} else {
				setGridColsVRGamesMenu('grid-cols-1')
			}
		}

		handleResize()

		window.addEventListener('resize', handleResize)

		return () => {
			window.removeEventListener('resize', handleResize)
		}
	}, [])

	const today = new Date()
	today.setHours(23, 59, 59, 999)

	const startOfWeek = new Date(today)
	startOfWeek.setDate(today.getDate() - 6) // Start of the week (Sunday) 7 days ago

	const endOfWeek = new Date(today)
	endOfWeek.setDate(today.getDate()) // End of the week (Saturday) today

	useEffect(() => {
		const today = new Date()
		today.setHours(23, 59, 59, 999)

		const startOfWeek = new Date(today)
		startOfWeek.setDate(today.getDate() - 6) // Start of the week (Sunday) 7 days ago

		const endOfWeek = new Date(today)
		endOfWeek.setDate(today.getDate()) // End of the week (Saturday) today
	}, [])

	useEffect(() => {
		const handleOutsideClick = (event) => {
			if (
				showWhatsNew &&
				whatsNewRef.current &&
				!whatsNewRef.current.contains(event.target)
			) {
				setShowWhatsNew(false)
			}
		}

		document.addEventListener('mousedown', handleOutsideClick)

		return () => {
			document.removeEventListener('mousedown', handleOutsideClick)
		}
	}, [showWhatsNew])

	const navigate = useNavigate()

	useEffect(() => {
		// Cek apakah pengguna sudah login, jika iya, atur state user
		const unsubscribe = auth.onAuthStateChanged((user) => {
			if (user) {
				setUser(user)
			} else {
				setUser(null)
				navigate('/', { replace: true })
			}
		})

		return () => {
			unsubscribe() // Membersihkan listener saat komponen dibongkar
		}
	}, [])

	useEffect(() => {
		// Mengatur waktu hari berdasarkan waktu lokal pengguna
		const currentTime = new Date().getHours()
		if (currentTime >= 5 && currentTime < 12) {
			setTimeOfDay('morning')
		} else if (currentTime >= 12 && currentTime < 15) {
			setTimeOfDay('afternoon')
		} else if (currentTime >= 15 && currentTime < 18) {
			setTimeOfDay('evening')
		} else {
			setTimeOfDay('night')
		}
	}, [])

	useEffect(() => {
		// Mengatur waktu saat ini dan detik
		const interval = setInterval(() => {
			const now = new Date()
			setCurrentTime(
				now.toLocaleTimeString(language === 'id' ? 'id-ID' : 'en-EN', {
					hour: '2-digit',
					minute: '2-digit',
					second: '2-digit',
					hourCycle: 'h23',
					timeZoneName: 'short',
				})
			)
		}, 1)

		return () => clearInterval(interval)
	}, [])

	const translations = {
		en: {
			title: 'Dashboard',

			morning: 'Good Morning',
			afternoon: 'Good Afternoon',
			evening: 'Good Evening',
			night: 'Good Night',

			manage: 'Manage',
			profile: 'Profile',
			account: 'Account',
			manageAccount: 'Manage your account here',

			announcement: 'Announcement',

			newAdded: 'NEW',
			comingSoonGames: 'Coming Soon Games',
			updatedGames: 'Updated Games',
			newGame: 'New Game Added',

			lastLogin: 'Last login: ',

			vrGames: 'VR Games',
			orders: 'Orders',
			cart: 'Cart',
			weather: 'Weather',
			leaderboard: 'Leaderboard',

			updatedOn: 'Updated on',

			new: 'New',
			newInfo:
				'Access your activity and other information in this all new dashboard!',

			mainMenu: 'Main Menu',
			downloadHistory: 'Download History',
			likedGames: 'Liked Games',
			accountInfo: 'Account Info',

			member: 'Member',
			guest: 'Guest',

			displayName: 'Display Name',
			email: 'Email',
			UID: 'UID',
			lastLogin: 'Last Login',

			permanentDataInfo: 'The data above cannot be changed!',

			signOut: 'Sign Out',

			titleSignOut: 'Are you sure?',
			textSignOut: 'Do you really want to sign out?',
			confirmSignOut: 'Yes, sign out!',
			cancelSignOut: 'No, stay logged in',
			successSignOut: 'Signed out!',
			successSignOutText: 'You have been successfully signed out.',
			errorSignOut: 'Oops...',
			errorSignOutText: 'Something went wrong!',

			noLikedGamesYet: 'You have not liked any games yet.',
			noDownloadHistoryYet: 'You have not downloaded any games yet.',
		},
		id: {
			title: 'Dasbor',

			morning: 'Selamat Pagi',
			afternoon: 'Selamat Siang',
			evening: 'Selamat Sore',
			night: 'Selamat Malam',

			manage: 'Kelola',
			profile: 'Profil',
			account: 'Akun',
			manageAccount: 'Kelola akunmu di sini',

			announcement: 'Pengumuman',

			newAdded: 'BARU',
			comingSoonGames: 'Game yang Segera Hadir',
			updatedGames: 'Game Diupdate',
			newGame: 'Game Baru Ditambahkan',

			lastLogin: 'Login terakhir: ',

			vrGames: 'Game VR',
			orders: 'Pesanan',
			cart: 'Keranjang',
			weather: 'Cuaca',
			leaderboard: 'Papan Peringkat',

			updatedOn: 'Diperbarui pada',

			new: 'Baru',
			newInfo: 'Akses aktivitasmu dan informasi lainnya di dasbor baru ini!',

			mainMenu: 'Menu Utama',
			downloadHistory: 'Riwayat Unduhan',
			likedGames: 'Game Disukai',
			accountInfo: 'Informasi Akun',

			member: 'Anggota',
			guest: 'Tamu',

			displayName: 'Nama Tampilan',
			email: 'Email',
			UID: 'UID',
			lastLogin: 'Terakhir Login',

			permanentDataInfo: 'Data di atas tidak dapat diubah!',

			signOut: 'Keluar',

			titleSignOut: 'Apakah kamu yakin?',
			textSignOut: 'Apakah kamu benar-benar ingin keluar?',
			confirmSignOut: 'Ya, keluar!',
			cancelSignOut: 'Tidak, tetap masuk',
			successSignOut: 'Keluar!',
			successSignOutText: 'Anda telah berhasil keluar.',
			errorSignOut: 'Oops...',
			errorSignOutText: 'Ada yang salah!',

			noLikedGamesYet: 'Kamu belum menyukai game apapun.',
			noDownloadHistoryYet: 'Kamu belum pernah mengunduh game apapun.',
		},
	}

	const handleResize = () => {
		// Periksa lebar jendela dan atur state sesuai kebutuhan
		if (window.innerWidth > 768) {
			setMargin(false)
		} else {
			setMargin(true)
		}
	}

	useEffect(() => {
		// Tambahkan event listener untuk mendengar perubahan ukuran jendela
		window.addEventListener('resize', handleResize)

		// Panggil handleResize untuk menetapkan nilai awal
		handleResize()

		// Hapus event listener ketika komponen di-unmount
		return () => {
			window.removeEventListener('resize', handleResize)
		}
	}, [])

	useEffect(() => {
		if (user) {
			const ordersRef = fireDb.ref(`orders/${user.uid}`)

			// Listen for changes in the cart
			const handleSnapshot = (snapshot) => {
				const items = snapshot.val()
				const ordersList = items ? Object.values(items) : []
				setOrders(ordersList)
				setLoadingOrders(false)
			}

			ordersRef.on('value', handleSnapshot)

			return () => {
				// Cleanup the event listener when the component unmounts
				ordersRef.off('value', handleSnapshot)
			}
		}
	}, [user])

	useEffect(() => {
		if (user) {
			const cartRef = fireDb.ref(`cart/${user.uid}`)

			// Listen for changes in the cart
			const handleSnapshot = (snapshot) => {
				const items = snapshot.val()
				const cartItemList = items ? Object.values(items) : []
				setCart(cartItemList)
				setLoadingCart(false)
			}

			cartRef.on('value', handleSnapshot)

			return () => {
				// Cleanup the event listener when the component unmounts
				cartRef.off('value', handleSnapshot)
			}
		}
	}, [user])

	const formatLastSignInTime = (lastSignInTime, language) => {
		if (!lastSignInTime) {
			return '' // or any other appropriate value
		}

		const options = {
			year: 'numeric',
			month: 'long',
			day: 'numeric',
			hour: 'numeric',
			minute: 'numeric',
			hour23: true,
			timeZoneName: 'short',
		}

		return new Intl.DateTimeFormat(language, options).format(
			new Date(lastSignInTime)
		)
	}

	const [dataStandaloneGames, setDataStandaloneGames] = useState({})
	const [dataPCVRGames, setDataPCVRGames] = useState({})
	const [leaderboardData, setLeaderboardData] = useState({})
	const [searchTerm, setSearchTerm] = useState('')
	const [sortBy, setSortBy] = useState('added')
	const [sortOrder, setSortOrder] = useState('desc')

	useEffect(() => {
		const gamesRef = fireDb.ref(`vrGames/standalone`)
		// Menggunakan orderByChild dan limitToFirst untuk mendapatkan 3 game teratas
		gamesRef
			.orderByChild('downloadCount')
			.limitToLast(3)
			.on('value', (snapshot) => {
				if (snapshot.exists()) {
					const data = snapshot.val()
					const sortedData = Object.values(data).sort(
						(a, b) => b.downloadCount - a.downloadCount
					)
					setLeaderboardData(sortedData)
				} else {
					setLeaderboardData([])
				}
			})

		return () => {
			// Membersihkan event listener saat komponen unmount
			gamesRef.off('value')
		}
	}, [])

	useEffect(() => {
		const standaloneGamesRef = fireDb.ref(`vrGames/standalone`)
		standaloneGamesRef.on('value', (snapshot) => {
			if (snapshot.val() !== null) {
				const gamesData = Object.values(snapshot.val())
				setStandaloneGames(gamesData)
				setLengthStandaloneGames(gamesData.length)
			} else {
				setStandaloneGames([])
				setLengthStandaloneGames(0)
			}
		})

		return () => {
			standaloneGamesRef.off('value')
		}
	}, [])

	useEffect(() => {
		const pcvrGamesRef = fireDb.ref(`vrGames/pcvr`)
		pcvrGamesRef.on('value', (snapshot) => {
			if (snapshot.val() !== null) {
				const gamesData = Object.values(snapshot.val())
				setPCVRGames(gamesData)
				setLengthPCVRGames(gamesData.length)
			} else {
				setPCVRGames([])
				setLengthPCVRGames(0)
			}
		})

		return () => {
			pcvrGamesRef.off('value')
		}
	}, [])

	useEffect(() => {
		const unsubscribe = auth.onAuthStateChanged(async (user) => {
			if (user) {
				setUser(user)
				try {
					const snapshot = await eligibleUsersStandaloneRef
						.orderByChild('email')
						.equalTo(user.email)
						.once('value')
					const eligibleUsersStandalone = snapshot.val()
					if (eligibleUsersStandalone) {
						setEligibleStandaloneGameQuest(true) // User is eligible, hide the announcement
					} else {
						setEligibleStandaloneGameQuest(false) // User is not eligible, show the announcement
					}
				} catch (error) {
					console.error('Error fetching eligible users:', error.message)
				}
			} else {
				setUser(null)
			}
		})

		return () => {
			unsubscribe()
		}
	}, [eligibleStandaloneGameQuest, eligibleUsersStandaloneRef, user])

	useEffect(() => {
		const unsubscribe = auth.onAuthStateChanged(async (user) => {
			if (user) {
				setUser(user)
				try {
					const snapshot = await eligibleUsersPCVRRef
						.orderByChild('email')
						.equalTo(user.email)
						.once('value')
					const eligibleUsersPCVR = snapshot.val()
					if (eligibleUsersPCVR) {
						setEligiblePCVRGameQuest(true) // User is eligible, hide the announcement
					} else {
						setEligiblePCVRGameQuest(false) // User is not eligible, show the announcement
					}
				} catch (error) {
					console.error('Error fetching eligible users:', error.message)
				}
			} else {
				setUser(null)
			}
		})

		return () => {
			unsubscribe()
		}
	}, [eligiblePCVRGameQuest, eligibleUsersPCVRRef, user])

	useEffect(() => {
		fireDb.ref(`vrGames/standalone`).once('value', (snapshot) => {
			if (snapshot.val() !== null) {
				const gamesData = snapshot.val()
				const sortedGamesData = Object.keys(gamesData).sort((a, b) => {
					const timeA = new Date(gamesData[a].timeAdded).getTime()
					const timeB = new Date(gamesData[b].timeAdded).getTime()
					return sortOrder === 'asc' ? timeB - timeA : timeA - timeB
				})
				const sortedDataObject = {}
				sortedGamesData.forEach((gameTitle) => {
					sortedDataObject[gameTitle] = gamesData[gameTitle]
				})
				setDataStandaloneGames(sortedDataObject)
			} else {
				setDataStandaloneGames({})
			}
		})
	}, [sortOrder])

	useEffect(() => {
		fireDb.ref(`vrGames/pcvr`).once('value', (snapshot) => {
			if (snapshot.val() !== null) {
				const gamesData = snapshot.val()
				const sortedGamesData = Object.keys(gamesData).sort((a, b) => {
					const timeA = new Date(gamesData[a].timeAdded).getTime()
					const timeB = new Date(gamesData[b].timeAdded).getTime()
					return sortOrder === 'asc' ? timeB - timeA : timeA - timeB
				})
				const sortedDataObject = {}
				sortedGamesData.forEach((gameTitle) => {
					sortedDataObject[gameTitle] = gamesData[gameTitle]
				})
				setDataPCVRGames(sortedDataObject)
			} else {
				setDataPCVRGames({})
			}
		})
	}, [sortOrder])

	const sortedDataStandaloneGames = Object.keys(dataStandaloneGames).sort(
		(a, b) => {
			if (sortBy === 'name') {
				return sortOrder === 'asc' ? a.localeCompare(b) : b.localeCompare(a)
			} else if (sortBy === 'added') {
				const timeA = Date.parse(dataStandaloneGames[a].timeAdded)
				const timeB = Date.parse(dataStandaloneGames[b].timeAdded)
				return sortOrder === 'asc' ? timeA - timeB : timeB - timeA
			}
		}
	)

	const sortedDataPCVRGames = Object.keys(dataPCVRGames).sort((a, b) => {
		if (sortBy === 'name') {
			return sortOrder === 'asc' ? a.localeCompare(b) : b.localeCompare(a)
		} else if (sortBy === 'added') {
			const timeA = Date.parse(dataPCVRGames[a].timeAdded)
			const timeB = Date.parse(dataPCVRGames[b].timeAdded)
			return sortOrder === 'asc' ? timeA - timeB : timeB - timeA
		}
	})

	const filteredDataStandaloneGames = sortedDataStandaloneGames.filter(
		(gameTitle) => {
			const game = dataStandaloneGames[gameTitle]
			return (
				game &&
				game.gameTitle &&
				game.gameTitle.toLowerCase().includes(searchTerm.toLowerCase())
			)
		}
	)

	const filteredDataPCVRGames = sortedDataPCVRGames.filter((gameTitle) => {
		const game = dataPCVRGames[gameTitle]
		return (
			game &&
			game.gameTitle &&
			game.gameTitle.toLowerCase().includes(searchTerm.toLowerCase())
		)
	})

	const handleToOrders = () => {
		navigate('/account/orders')
	}

	const handleToCart = () => {
		navigate('/account/cart')
	}

	const handleToProfile = () => {
		navigate('/account/profile')
	}

	const handleToVRGames = () => {
		navigate('/vr-games/list-games')
	}

	const handleToLeaderboardGames = () => {
		navigate('/vr-games/leaderboard')
	}

	const requestedBySensor = (str, maxLength) => {
		if (str && str.length > maxLength) {
			return str.substring(0, maxLength) + '...'
		}
		return str
	}

	const [isMobile, setIsMobile] = useState(window.innerWidth < 1200)

	useEffect(() => {
		const handleResize = () => {
			setIsMobile(window.innerWidth < 1200)
		}

		window.addEventListener('resize', handleResize)

		return () => {
			window.removeEventListener('resize', handleResize)
		}
	}, [])

	const [downloadHistory, setDownloadHistory] = useState([])
	const [likedGames, setLikedGames] = useState([])
	const [loadingImages, setLoadingImages] = useState(true)
	const imageListRef = ref(storage, 'coverGamesImages/')

	useEffect(() => {
		if (user && user.uid) {
			// Ambil data dari Firebase berdasarkan user.uid
			const historyRef = fireDb.ref(
				`usersData/downloadHistory/${user.uid}/standalone`
			)
			historyRef.on('value', async (snapshot) => {
				const historyData = snapshot.val()
				if (historyData) {
					const historyList = Object.values(historyData)

					// Ambil URL gambar dari Firebase Storage untuk setiap gameTitle
					const downloadHistoryWithImages = await Promise.all(
						historyList.map(async (gameTitle) => {
							try {
								// Dapatkan URL gambar dari Storage
								const response = await listAll(imageListRef)
								const gameImageName = `${gameTitle}`
								const userImage = response.items.find(
									(item) => item.name === gameImageName
								)
								const imageUrl = await getDownloadURL(userImage)
								return { gameTitle, imageUrl } // Kembalikan objek dengan title dan URL gambar
							} catch (error) {
								console.error('Error getting image URL: ', error)
								return { gameTitle, imageUrl: null } // Jika gagal, set imageUrl ke null
							}
						})
					)

					setDownloadHistory(downloadHistoryWithImages)
				}
			})

			// Bersihkan listener saat komponen di-unmount
			return () => historyRef.off()
		}
	}, [user, storage])

	useEffect(() => {
		if (user && user.uid) {
			// Ambil data dari Firebase berdasarkan user.uid dan gameTitle
			const likedGamesRef = fireDb.ref(`usersData/likedGames/${user.uid}`)

			likedGamesRef.on('value', async (snapshot) => {
				const historyData = snapshot.val()
				if (historyData) {
					const likedGamesList = Object.keys(historyData) // Ambil gameTitle sebagai array

					// Ambil URL gambar dari Firebase Storage untuk setiap gameTitle
					const likedGamesWithImages = await Promise.all(
						likedGamesList.map(async (gameTitle) => {
							try {
								// Ambil referensi data spesifik untuk setiap gameTitle
								const userLikedGamesRef = fireDb.ref(
									`usersData/likedGames/${user.uid}/${gameTitle}`
								)

								// Dapatkan URL gambar dari Storage
								const response = await listAll(imageListRef)
								const gameImageName = `${gameTitle}`
								const userImage = response.items.find(
									(item) => item.name === gameImageName
								)
								const imageUrl = await getDownloadURL(userImage)

								return { gameTitle, imageUrl } // Kembalikan objek dengan title dan URL gambar
							} catch (error) {
								console.error('Error getting image URL: ', error)
								return { gameTitle, imageUrl: null } // Jika gagal, set imageUrl ke null
							}
						})
					)

					setLikedGames(likedGamesWithImages)
				}
			})

			// Bersihkan listener saat komponen di-unmount
			return () => likedGamesRef.off()
		}
	}, [user, storage])

	const [showDownloadHistory, setShowDownloadHistory] = useState(false)
	const [showLikedGames, setShowLikedGames] = useState(false)
	const [showAccountInfo, setShowAccountInfo] = useState(false)
	const [showMainMenu, setShowMainMenu] = useState(false)
	const [showBeginning, setShowBeginning] = useState(true)

	const handleShowMainMenu = () => {
		setShowMainMenu(true)
		setShowDownloadHistory(false)
		setShowLikedGames(false)
		setShowAccountInfo(false)
		setShowBeginning(false)
	}

	const handleShowDownloadHistory = () => {
		setShowMainMenu(false)
		setShowDownloadHistory(true)
		setShowLikedGames(false)
		setShowAccountInfo(false)
		setShowBeginning(false)
	}

	const handleShowLikedGames = () => {
		setShowMainMenu(false)
		setShowLikedGames(true)
		setShowDownloadHistory(false)
		setShowAccountInfo(false)
		setShowBeginning(false)
	}

	const handleShowAccountInfo = () => {
		setShowMainMenu(false)
		setShowAccountInfo(true)
		setShowDownloadHistory(false)
		setShowLikedGames(false)
		setShowBeginning(false)
	}

	const handleShowBeginning = () => {
		setShowMainMenu(false)
		setShowAccountInfo(false)
		setShowDownloadHistory(false)
		setShowLikedGames(false)
		setShowBeginning(true)
	}

	const handleSignOut = () => {
		Swal.fire({
			title: translations[language].titleSignOut,
			text: translations[language].textSignOut,
			icon: 'warning',
			showCancelButton: true,
			confirmButtonColor: '#3085d6',
			cancelButtonColor: '#d33',
			confirmButtonText: translations[language].confirmSignOut,
			cancelButtonText: translations[language].cancelSignOut,
		}).then((result) => {
			if (result.isConfirmed) {
				signOut(auth)
					.then(() => {
						setUser(null)
						Swal.fire(
							translations[language].successSignOut,
							translations[language].successSignOutText,
							'success'
						)
					})
					.catch((error) => {
						Swal.fire({
							icon: 'error',
							title: translations[language].errorSignOut,
							text: translations[language].errorSignOutText,
							footer: error.message,
						})
					})
			}
		})
	}

	return (
		<div
			className={`py-5 mx-5 w-full ${!isMobile && 'pt-[100px]'} min-h-screen`}
		>
			<div className="flex flex-row gap-4 items-start w-full h-full">
				<div
					className={`${isMobile ? 'w-full h-full' : 'w-[470px] h-full'} flex-col gap-4 flex `}
				>
					<div
						className={`flex flex-col w-full h-full rounded-2xl items-center justify-start py-8 px-6 backdrop-blur-md border`}
					>
						{!showBeginning && (
							<button
								onClick={handleShowBeginning}
								className="absolute rounded-full bg-white size-12 text-red-500 hover:bg-zinc-100 transition-all duration-300 z-20 top-4 left-4 flex justify-center items-center"
							>
								<FaArrowLeft size={24} />
							</button>
						)}
						<div
							className="absolute -mt-8 h-24 rounded-t-2xl z-10 w-full bg-[#0086FB]"
							style={{
								background: `linear-gradient(to right, rgba(0, 134, 251, 0.80) 10%, rgba(0, 134, 251, 0.95) 100%, rgba(0, 134, 251, 0.95) 100%), url(${bgPage}) center/cover`,
							}}
						/>
						{user ? (
							<img
								src={user.photoURL}
								alt="user-photo"
								className={`rounded-full z-20 mt-4`}
							/>
						) : (
							<FaUserCircle
								className={`text-9xl ${isDarkMode ? 'text-white' : 'text-black'} z-20 mt-4`}
							/>
						)}
						<span className="flex flex-col gap-1 items-center">
							<span
								className={`text-2xl font-bold mt-5 text-center ${isDarkMode ? 'text-white' : 'text-black'}`}
							>
								{user?.displayName}
							</span>
							{user ? (
								<span className={`text-gray-400`}>
									{translations[language].member}
								</span>
							) : (
								<span className={`text-gray-400 text-lg`}>
									{translations[language].guest}
								</span>
							)}
						</span>
						<hr className="w-full my-5" />
						{showBeginning && (
							<div className="flex flex-col gap-3 w-full p-4 border-2 rounded-xl">
								<div className="flex flex-row justify-between items-center w-full">
									<span
										className={`${isDarkMode ? 'text-white' : 'text-black'}`}
									>
										Game Standalone Quest
									</span>
									{eligibleStandaloneGameQuest ? (
										<FaCheckCircle
											className={`text-blue-500 text-2xl bg-white rounded-full`}
										/>
									) : (
										<FaTimes className={`text-red-600 text-2xl rounded-full`} />
									)}
								</div>
								<div className="flex flex-row justify-between items-center w-full">
									<span
										className={`${isDarkMode ? 'text-white' : 'text-black'}`}
									>
										Game PCVR
									</span>
									{eligiblePCVRGameQuest ? (
										<FaCheckCircle
											className={`text-blue-500 text-2xl bg-white rounded-full`}
										/>
									) : (
										<FaTimes className={`text-red-600 text-2xl rounded-full`} />
									)}
								</div>
							</div>
						)}

						{showAccountInfo && (
							<div className="w-full h-full flex flex-col justify-between">
								<div className="flex flex-col">
									<h3 className="flex flex-row gap-2 items-center justify-center text-lg font-bold mb-4 w-full bg-[#0086FB] px-4 py-2 rounded-xl text-white text-center">
										<FaUserCircle className="text-white" />
										{translations[language].accountInfo}
									</h3>
									<div
										className={`flex flex-col items-start gap-2 ${isDarkMode ? 'text-white' : 'text-black'}`}
									>
										<div className="mb-2 flex flex-col w-full gap-1">
											<label htmlFor="displayName" className="font-semibold">
												{translations[language].displayName}:
											</label>
											<input
												type="text"
												id="displayName"
												value={user.displayName}
												disabled
												className={` rounded-xl p-2 pl-5 disabled:bg-gray-200 disabled:text-gray-500 bg-white text-black border w-full outline-none`}
											/>
										</div>
										<div className="mb-2 flex flex-col w-full gap-1">
											<label htmlFor="email" className="font-semibold">
												{translations[language].email}:
											</label>
											<input
												type="text"
												id="email"
												value={user.email}
												disabled
												className={` rounded-xl p-2 pl-5 disabled:bg-gray-200 disabled:text-gray-500 bg-white text-black border w-full outline-none`}
											/>
										</div>
										<div className="mb-2 flex flex-col w-full gap-1">
											<label htmlFor="lastLogin" className="font-semibold">
												{translations[language].lastLogin}:
											</label>
											<input
												type="text"
												id="lastLogin"
												value={formatLastSignInTime(
													user.metadata.lastSignInTime,
													language
												)}
												disabled
												className={` rounded-xl p-2 pl-5 disabled:bg-gray-200 disabled:text-gray-500 bg-white text-black border w-full outline-none`}
											/>
										</div>
									</div>
								</div>
								<span
									className={`text-white text-sm py-2 px-3 bg-red-500 rounded-lg w-fit`}
								>
									<FaExclamation className="inline-block mr-1 text-lg" />
									{translations[language].permanentDataInfo}
								</span>
							</div>
						)}

						{showDownloadHistory && (
							<div className={`w-full pb-4 flex-col flex`}>
								<h3 className="flex flex-row gap-2 items-center justify-center text-lg font-bold mb-4 w-full bg-green-500 px-4 py-2 rounded-xl text-white text-center">
									<FaDownload className="text-white" />
									{translations[language].downloadHistory}
								</h3>
								<div
									className={`flex flex-col h-full w-full items-start justify-start`}
								>
									{downloadHistory.length > 0 ? (
										// Menambahkan max-height dan overflow-y agar list bisa discroll
										<ul className="flex flex-wrap w-full gap-3 max-h-96 overflow-y-auto">
											{downloadHistory.map((item, index) => (
												<li
													key={index}
													className="flex gap-2 flex-row justify-between p-3 rounded-2xl border w-full items-start"
												>
													<span>
														{index + 1}. {item.gameTitle}
													</span>
													{item.imageUrl ? (
														<img
															src={item.imageUrl}
															alt={item.gameTitle}
															className="h-16 w-16 object-cover rounded-xl"
														/>
													) : (
														<div className="w-64 h-32 bg-gray-300 flex items-center justify-center text-center rounded-xl">
															No Image
														</div>
													)}
												</li>
											))}
										</ul>
									) : (
										<p>{translations[language].noDownloadHistoryYet}</p>
									)}
								</div>
							</div>
						)}

						{showLikedGames && (
							<div className={`w-full pb-4 flex-col flex`}>
								<h3 className="flex flex-row gap-2 items-center justify-center text-lg font-bold mb-4 w-full bg-red-500 px-4 py-2 rounded-xl text-white text-center">
									<FaHeart className="text-white" />
									{translations[language].likedGames}
								</h3>
								<div
									className={`flex flex-col h-full w-full items-start justify-start`}
								>
									{likedGames.length > 0 ? (
										// Menambahkan max-height dan overflow-y agar list bisa discroll
										<ul className="flex flex-wrap w-full gap-3 max-h-96 overflow-y-auto">
											{likedGames.map((item, index) => (
												<li
													key={index}
													className="flex gap-2 flex-row justify-between p-3 rounded-2xl border w-full items-start"
												>
													<span>
														{index + 1}. {item.gameTitle}
													</span>
													{item.imageUrl ? (
														<img
															src={item.imageUrl}
															alt={item.gameTitle}
															className="h-16 w-16 object-cover rounded-xl"
														/>
													) : (
														<div className="w-64 h-32 bg-gray-300 flex items-center justify-center text-center rounded-xl">
															No Image
														</div>
													)}
												</li>
											))}
										</ul>
									) : (
										<p>{translations[language].noLikedGamesYet}</p>
									)}
								</div>
							</div>
						)}

						{showBeginning && (
							<>
								{isMobile && (
									<div
										onClick={handleToCart}
										className="h-fit mt-4 cursor-pointer p-4 rounded-2xl bg-gradient-to-br from-green-400 to-green-600 hover:from-green-500 hover:to-green-700 w-full flex flex-row justify-between items-center"
									>
										{loadingCart ? (
											<div className="loading-overlay">
												<div className="spinner"></div>
											</div>
										) : (
											<>
												<span className="flex flex-row gap-3 items-center">
													<span className="p-2 text-white rounded-lg bg-opacity-20 bg-white">
														<FaCartShopping />
													</span>
													<h1 className="text-lg font-semibold text-white">
														{translations[language].cart}
													</h1>
												</span>

												<span className="flex flex-row gap-2 items-end">
													<p className="text-3xl text-white font-bold">
														{cart.length}
													</p>
													<p className="text-sm text-white opacity-80 font-semibold">
														Item
													</p>
												</span>
											</>
										)}
									</div>
								)}

								{isMobile && (
									<div
										onClick={handleToOrders}
										className="h-fit mt-4 cursor-pointer p-4 rounded-2xl bg-gradient-to-br from-blue-400 to-blue-600 hover:from-blue-500 hover:to-blue-700 w-full flex flex-row justify-between items-center"
									>
										{loadingOrders ? (
											<div className="loading-overlay">
												<div className="spinner"></div>
											</div>
										) : (
											<>
												<span className="flex flex-row gap-3 items-center">
													<span className="p-2 text-white rounded-lg bg-opacity-20 bg-white">
														<BsArchiveFill />
													</span>
													<h1 className="text-lg font-semibold text-white">
														{translations[language].orders}
													</h1>
												</span>

												<span className="flex flex-row gap-2 items-end">
													<p className="text-3xl text-white font-bold">
														{orders.length}
													</p>
													<p className="text-sm text-white opacity-80 font-semibold">
														Item
													</p>
												</span>
											</>
										)}
									</div>
								)}

								<button
									onClick={handleShowAccountInfo}
									className="w-full px-4 py-3 flex flex-row justify-between items-center border-2 rounded-xl mt-4 hover:bg-[#0086FB] hover:text-white transition-all duration-300"
								>
									<div className="flex flex-row justify-start items-center gap-3">
										<FaUserCircle />
										<p>{translations[language].accountInfo}</p>
									</div>
									<FaArrowRight />
								</button>

								<button
									onClick={handleShowLikedGames}
									className="w-full px-4 py-3 flex flex-row justify-between items-center border-2 rounded-xl mt-4 hover:bg-red-500 hover:text-white transition-all duration-300"
								>
									<div className="flex flex-row justify-start items-center gap-3">
										<FaHeart />
										<p>{translations[language].likedGames}</p>
									</div>
									<FaArrowRight />
								</button>

								<button
									onClick={handleShowDownloadHistory}
									className="w-full px-4 py-3 flex flex-row justify-between items-center border-2 rounded-xl mt-4 hover:bg-green-500 hover:text-white transition-all duration-300"
								>
									<div className="flex flex-row justify-start items-center gap-3">
										<FaDownload />
										<p>{translations[language].downloadHistory}</p>
									</div>
									<FaArrowRight />
								</button>
							</>
						)}
					</div>
					{user && (
						<button
							onClick={handleSignOut}
							className="w-full rounded-xl p-3 flex items-center justify-center bg-red-500 hover:bg-red-600 transition-all duration-300"
						>
							<span>
								<span className={`text-white`}>
									{translations[language].signOut}
								</span>
								<FaSignOutAlt className="inline-block ml-3 text-2xl text-white" />
							</span>
						</button>
					)}
				</div>

				{!isMobile && (
					<div className="h-full w-full">
						<div className="flex justify-between bg-[#0086FB] rounded-2xl px-5 py-4 mb-3">
							<span>
								<h1 className="text-sm text-white font-normal">
									{translations[language][timeOfDay]},
								</h1>
								<h1 className="text-xl text-white font-bold">
									{user ? user.displayName : 'User'}
								</h1>
							</span>

							{/* Menampilkan informasi cuaca di sebelah kanan */}
							<div className="flex flex-row gap-2 items-center text-right text-white">
								<span>
									<p>{currentTime}</p>
									<p className="font-semibold">
										{new Date().toLocaleDateString(
											language === 'id' ? 'id-ID' : 'en-EN',
											{
												weekday: 'long',
												year: 'numeric',
												month: 'long',
												day: 'numeric',
												lang: 'id',
											}
										)}
									</p>
								</span>
							</div>
						</div>

						<div
							className={`gap-3 w-full ${isMobile ? 'flex flex-col' : 'grid grid-cols-2'}`}
						>
							{/* CART */}
							<div
								onClick={handleToCart}
								className="h-80 cursor-pointer px-6 py-5 rounded-2xl bg-gradient-to-br from-green-400 to-green-600 hover:from-green-500 hover:to-green-700 w-full flex flex-col justify-between"
							>
								{loadingCart ? (
									<div className="loading-overlay">
										<div className="spinner"></div>
									</div>
								) : (
									<>
										<span className="flex justify-between items-center">
											<h1 className="text-xl font-semibold text-white">
												{translations[language].cart}
											</h1>
											<span className="p-2 text-white rounded-lg bg-opacity-20 bg-white">
												<FaCartShopping />
											</span>
										</span>

										<span className="flex flex-row gap-2 items-end mt-5">
											<p className=" text-6xl text-white font-bold">
												{cart.length}
											</p>
											<p className=" text-sm text-white opacity-80 font-semibold">
												Item
											</p>
										</span>
									</>
								)}
							</div>

							{/* ORDERS */}
							<div
								onClick={handleToOrders}
								className="h-80 cursor-pointer px-6 py-5 rounded-2xl bg-gradient-to-br from-blue-400 to-blue-600 hover:from-blue-500 hover:to-blue-700 w-full flex flex-col justify-between"
							>
								{loadingOrders ? (
									<div className="loading-overlay">
										<div className="spinner"></div>
									</div>
								) : (
									<>
										<span className="flex justify-between items-center">
											<h1 className="text-xl font-semibold text-white">
												{translations[language].orders}
											</h1>
											<span className="p-2 text-white rounded-lg bg-opacity-20 bg-white">
												<BsArchiveFill />
											</span>
										</span>

										<span className="flex flex-row gap-2 items-end mt-5">
											<p className=" text-6xl text-white font-bold">
												{orders.length}
											</p>
											<p className=" text-sm text-white opacity-80 font-semibold">
												Item
											</p>
										</span>
									</>
								)}
							</div>

							{/* VR GAMES */}
							<div
								onClick={handleToVRGames}
								className="h-80 cursor-pointer px-6 py-5 rounded-2xl bg-gradient-to-br from-yellow-400 to-yellow-600 hover:from-yellow-500 hover:to-yellow-700 w-full flex flex-col justify-between"
							>
								{loadingGames ? (
									<div className="loading-overlay">
										<div className="spinner"></div>
									</div>
								) : (
									<>
										<span className="flex justify-between items-center">
											<h1 className="text-xl font-semibold text-white">
												{translations[language].vrGames}
											</h1>
											<span className="p-2 text-white rounded-lg bg-opacity-20 bg-white">
												<BsHeadsetVr />
											</span>
										</span>

										<div className="flex flex-col gap-2 mt-5 h-full">
											<span className="px-4 py-2 text-white rounded-xl bg-opacity-20 bg-white flex flex-row justify-between items-center h-full">
												<p>Standalone</p>
												<p className="font-semibold">
													{filteredDataStandaloneGames.length} Games
												</p>
											</span>

											<span className="px-4 py-2 text-white rounded-xl bg-opacity-20 bg-white flex flex-row justify-between items-center h-full">
												<p>PCVR</p>
												<p className="font-semibold">
													{filteredDataPCVRGames.length} Games
												</p>
											</span>
										</div>
									</>
								)}
							</div>

							{/* LEADERBOARD GAMES */}
							<div
								onClick={handleToLeaderboardGames}
								className="h-80 cursor-pointer px-6 py-5 rounded-2xl bg-gradient-to-br from-rose-400 to-rose-600 hover:from-rose-500 hover:to-rose-700 w-full flex flex-col justify-between"
							>
								{loadingGames ? (
									<div className="loading-overlay">
										<div className="spinner"></div>
									</div>
								) : (
									<>
										<span className="flex justify-between items-center">
											<h1 className="text-xl font-semibold text-white">
												{translations[language].leaderboard}
											</h1>
											<span className="p-2 text-white rounded-lg bg-opacity-20 bg-white">
												<FaRankingStar />
											</span>
										</span>

										<div
											className="overflow-y-auto mt-5"
											style={{ height: 'calc(100%)' }}
										>
											{leaderboardData.map((game, index) => (
												<div
													key={index}
													className="flex justify-between items-center bg-white bg-opacity-10 p-3 rounded-lg mb-2 text-sm"
												>
													{/* Menambahkan ikon podium berdasarkan indeks */}
													<p className="text-white">
														{index === 0 && '🥇'}
														{index === 1 && '🥈'}
														{index === 2 && '🥉'}
														{index > 2 && index + 1}{' '}
														{requestedBySensor(game.gameTitle, 18)}
													</p>
													<p className="text-white">{game.downloadCount}</p>
												</div>
											))}
										</div>
									</>
								)}
							</div>
						</div>
					</div>
				)}
			</div>

			<style>
				{`
        .loading-overlay {
          width: 100%;
          height: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
        }
        
        .spinner {
          border: 4px solid rgba(255, 255, 255, 0.3); /* warna putih dengan transparansi */
          border-top: 4px solid #ffffff; /* warna putih */
          border-radius: 50%;
          width: 40px;
          height: 40px;
          animation: spin 1s linear infinite;
        }
        
        @keyframes spin {
          0% { transform: rotate(0deg); }
          100% { transform: rotate(360deg); }
        }
        `}
			</style>

			<Helmet>
				<title>{translations[language].title} - HyperTopia</title>
			</Helmet>
		</div>
	)
}

export default Dashboard
