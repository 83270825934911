import React, { useState, useEffect } from 'react'
import Swal from 'sweetalert2'
import {
	FaBookmark,
	FaHeart,
	FaMinus,
	FaPlus,
	FaRegBookmark,
} from 'react-icons/fa6'
import { LazyLoadImage } from 'react-lazy-load-image-component'
import { MdSell } from 'react-icons/md'
import { ref, getDownloadURL } from 'firebase/storage'
import { storage, auth } from '../../firebase'
import fireDb from '../../firebase'
import { motion } from 'framer-motion'

const ProductsCard = ({ product }) => {
	const { productName, productDescription_EN, productDescription_ID, productStock, productPrice } = product;
	const [productPhotoUrl, setProductPhotoUrl] = useState(null)
	const [quantity, setQuantity] = useState(1)
	const [user, setUser] = useState(null)
	const [isBookmarked, setIsBookmarked] = useState(false)
	const [isWishlisted, setIsWishlisted] = useState(false)
	const [isPopupOpen, setIsPopupOpen] = useState(false)
	const language = localStorage.getItem('language') || 'id'

	const formatPriceWithDot = (price) => {
		return price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.')
	}

	const fetchProductPhotoUrl = async () => {
		const imageRef = ref(storage, `productPhotos/${productName}`)
		try {
			const url = await getDownloadURL(imageRef)
			setProductPhotoUrl(url)
		} catch (error) {
			console.error('Error fetching product photo URL:', error)
		}
	}

	useEffect(() => {
		fetchProductPhotoUrl()
		const unsubscribe = auth.onAuthStateChanged((user) => {
			setUser(user || null)
		})
		return () => unsubscribe()
	}, [productName])

	const handleAddToCart = () => {
		const cartRef = fireDb.ref(`cart/${user.uid}`)

		cartRef.child(productName).once('value', (snapshot) => {
			if (snapshot && snapshot.val()) {
				const existingItem = snapshot.val()
				const existingQuantity = existingItem.quantity || 1

				cartRef.child(productName).update({
					quantity: existingQuantity + quantity,
					productPrice: product.productPrice,
				})

				Swal.fire({
					icon: 'success',
					title: translations[language].productAdded,
					text: `${productName} - ${translations[language].productUpdated}.`,
					timer: 2000,
				})
			} else {
				const newCartItem = {
					productName,
					productDescription_EN,
					productDescription_ID,
					productStock,
					productPrice: product.productPrice,
					productPhotoUrl: productPhotoUrl,
					quantity,
				}
				cartRef.child(productName).set(newCartItem)

				Swal.fire({
					icon: 'success',
					title: translations[language].productAdded,
					text: `${quantity} ${productName} - ${translations[language].productAdded}.`,
					timer: 2000,
				})
			}
		})

		setQuantity(1)
	}

	const translations = {
    en: {
      viewMore: 'View More',
      viewLess: 'View Less',
      currency: 'IDR ',
      stock: 'Stock: ',
      listGames: 'List Games',
      productAdded: 'Product added to cart',
      productUpdated: 'Product quantity in cart has been updated',
      sold: 'Sold: ',
      cart: 'Cart',
      adjustQuantity: 'Adjust Quantity',
      addToCart: 'Add to Cart',
      cancel: 'Cancel',
    },
    id: {
      viewMore: 'Lihat Selengkapnya',
      viewLess: 'Lihat Lebih Sedikit',
      currency: 'Rp',
      stock: 'Stok: ',
      listGames: 'Daftar Game',
      productAdded: 'Produk ditambahkan ke keranjang',
      productUpdated: 'Jumlah item produk di keranjang telah diperbarui',
      sold: 'Terjual: ',
      cart: 'Keranjang',
      adjustQuantity: 'Atur Kuantitas',
      addToCart: 'Tambah ke Keranjang',
      cancel: 'Batal',
    },
  };

	const toggleBookmark = () => {
		setIsBookmarked(!isBookmarked)
		Swal.fire({
			icon: isBookmarked ? 'success' : 'info',
			title: isBookmarked
				? language === 'en'
					? 'Removed from bookmarks'
					: 'Dihapus dari bookmark'
				: language === 'en'
					? 'Bookmarked'
					: 'Ditambahkan ke bookmark',
			timer: 1500,
		})
	}

	const toggleWishlist = () => {
		setIsWishlisted(!isWishlisted)
		Swal.fire({
			icon: isWishlisted ? 'success' : 'info',
			title: isWishlisted
				? language === 'en'
					? 'Removed from wishlist'
					: 'Dihapus dari wishlist'
				: language === 'en'
					? 'Added to wishlist'
					: 'Ditambahkan ke wishlist',
			timer: 1500,
		})
	}

	return (
		<motion.div
			className="border rounded-lg overflow-hidden shadow-md bg-white p-4 flex flex-col space-y-4 transition-all duration-300 hover:shadow-lg"
			initial={{ opacity: 0, y: 20 }}
			animate={{ opacity: 1, y: 0 }}
			whileHover={{ scale: 1.03 }}
		>
			{/* Product Image */}
			{productPhotoUrl && (
				<motion.div
					className="w-full h-48 bg-gray-100 rounded-lg overflow-hidden flex justify-center items-center"
					initial={{ scale: 0.9 }}
					animate={{ scale: 1 }}
					whileHover={{ scale: 1.05 }}
					transition={{ duration: 0.4 }}
				>
					<LazyLoadImage
						src={productPhotoUrl}
						alt={productName}
						className="w-full h-full object-cover"
					/>
				</motion.div>
			)}

			{/* Product Info */}
			<div className="flex justify-between items-center">
				<h2 className="font-semibold text-lg text-gray-800">
					{productName.length > 20
						? `${productName.substring(0, 20)}...`
						: productName}
				</h2>
				<div className="flex space-x-2">
					<button onClick={toggleBookmark}>
						{isBookmarked ? (
							<FaBookmark className="text-yellow-500" />
						) : (
							<FaRegBookmark className="text-gray-400 hover:text-yellow-500" />
						)}
					</button>
					<button onClick={toggleWishlist}>
						{isWishlisted ? (
							<FaHeart className="text-red-500" />
						) : (
							<FaHeart className="text-gray-400 hover:text-red-500" />
						)}
					</button>
				</div>
			</div>

			{/* Price and Sold Information */}
			<div className="flex justify-between items-center">
				<span className="font-bold text-xl text-blue-500">
					{language === 'en' ? 'IDR ' : 'Rp '}
					{formatPriceWithDot(productPrice)}
				</span>
				<span className="bg-blue-100 text-blue-700 px-2 py-1 rounded-lg text-sm flex items-center gap-1">
					<MdSell /> {language === 'en' ? 'Sold' : 'Terjual'}{' '}
					{10000 - productStock}
				</span>
			</div>

			{/* Add to Cart Button */}
			<motion.button
				onClick={() => setIsPopupOpen(true)}
				className="w-full bg-blue-500 text-white py-2 rounded-lg flex items-center justify-center gap-2 shadow-md hover:bg-blue-600 transition-all duration-300"
				whileHover={{ scale: 1.05 }}
			>
				<FaPlus className="text-lg" />
				{language === 'en' ? 'Add to Cart' : 'Tambah ke Keranjang'}
			</motion.button>

			{/* Quantity Adjustment Popup */}
			{isPopupOpen && (
				<motion.div
					className="fixed inset-0 flex items-center justify-center z-50"
					initial={{ opacity: 0 }}
					animate={{ opacity: 1 }}
				>
					<div className="absolute inset-0 bg-black opacity-50"></div>
					<motion.div
						className="relative bg-white p-6 rounded-lg shadow-lg z-10 w-80"
						initial={{ scale: 0.8 }}
						animate={{ scale: 1 }}
					>
						<h3 className="text-lg font-semibold text-center mb-4">
							{language === 'en' ? 'Adjust Quantity' : 'Atur Kuantitas'}
						</h3>
						<div className="flex justify-between items-center mb-6">
							<button
								onClick={() =>
									setQuantity(quantity - 1 >= 1 ? quantity - 1 : 1)
								}
								className="w-10 h-10 rounded-full bg-gray-200 hover:bg-gray-300 flex items-center justify-center text-gray-700"
								disabled={quantity <= 1}
							>
								<FaMinus />
							</button>
							<input
								type="number"
								value={quantity}
								onChange={(e) => setQuantity(parseInt(e.target.value))}
								className="text-center w-16 py-2 border rounded-lg"
							/>
							<button
								onClick={() => setQuantity(quantity + 1)}
								className="w-10 h-10 rounded-full bg-gray-200 hover:bg-gray-300 flex items-center justify-center text-gray-700"
							>
								<FaPlus />
							</button>
						</div>
						<div className="flex justify-end space-x-2">
							<button
								onClick={() => setIsPopupOpen(false)}
								className="px-4 py-2 bg-gray-300 text-gray-700 rounded-lg hover:bg-gray-400 transition duration-200"
							>
								{language === 'en' ? 'Cancel' : 'Batal'}
							</button>
							<button
								onClick={() => {
									handleAddToCart()
									setIsPopupOpen(false)
								}}
								className="px-4 py-2 bg-blue-500 text-white rounded-lg hover:bg-blue-600 transition duration-200"
							>
								{language === 'en' ? 'Add to Cart' : 'Tambah ke Keranjang'}
							</button>
						</div>
					</motion.div>
				</motion.div>
			)}
		</motion.div>
	)
}

export default ProductsCard
