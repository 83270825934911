import firebase from 'firebase/compat/app'
import { getStorage } from 'firebase/storage'
import { getAuth, GoogleAuthProvider } from 'firebase/auth'
import 'firebase/compat/database'

const firebaseConfig = {
  apiKey: "AIzaSyC009NbWFGi3ln8BbMxnwVKe7XBF26lCt0",
  authDomain: "hypertopia-web.firebaseapp.com",
  databaseURL: "https://hypertopia-web-default-rtdb.asia-southeast1.firebasedatabase.app",
  projectId: "hypertopia-web",
  storageBucket: "hypertopia-web.appspot.com",
  messagingSenderId: "197031865238",
  appId: "1:197031865238:web:daa24422ed1858badd4bb7",
  measurementId: "G-QBFT3KRCYM"
};

const fireDb = firebase.initializeApp(firebaseConfig)
const storage = getStorage(fireDb)
const auth = getAuth(fireDb)
const provider = new GoogleAuthProvider()
export { storage, auth, provider }
export default fireDb.database()
