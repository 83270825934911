import React, { useState, useEffect } from 'react'
import fireDb from '../../firebase'
import { auth } from '../../firebase'
import { Helmet } from 'react-helmet'
import { useDarkModeContext } from '../../DarkModeContext'
import { Link, useHistory } from 'react-router-dom'
import Swal from 'sweetalert2'
import { useSwipeable } from 'react-swipeable'
import { FaTrash } from 'react-icons/fa'

const Orders = () => {
	const [orders, setOrders] = useState([])
	const [user, setUser] = useState(null)
	const storedLanguage = localStorage.getItem('language') || 'id'
	const [language, setLanguage] = useState(storedLanguage)
	const [filterStatus, setFilterStatus] = useState(null)
	const [showDeleteButton, setShowDeleteButton] = useState(false)
	const handleSwipeLeft = () => {
		setShowDeleteButton(true)
	}

	const handleSwipeRight = () => {
		setShowDeleteButton(false)
	}

	const swipeHandlers = useSwipeable({
		onSwipedLeft: () => handleSwipeLeft(),
		onSwipedRight: () => handleSwipeRight(),
		preventDefaultTouchmoveEvent: true,
		trackMouse: true,
	})

	const filteredOrders = orders.filter((order) => {
		if (filterStatus === null) return true // Jika tidak ada filter, tampilkan semua pesanan
		return order.status === filterStatus
	})

	const handleFilter = (status) => {
		const params = new URLSearchParams(window.location.search)
		setFilterStatus(status)

		if (status === 'Menunggu Pembayaran') {
			params.set('status', 'waitingPayment')
		} else if (status === 'Pesanan Diproses') {
			params.set('status', 'process')
		} else if (status === 'Pesanan Dikirim') {
			params.set('status', 'sent')
		} else if (status === 'Pesanan Selesai') {
			params.set('status', 'done')
		} else if (status === 'Pesanan Dibatalkan') {
			params.set('status', 'canceled')
		} else if (status === 'Pengembalian Dana') {
			params.set('status', 'refund')
		} else {
			params.delete('status')
		}

		window.history.replaceState({}, '', `${window.location.pathname}?${params}`)
	}

	useEffect(() => {
		const params = new URLSearchParams(window.location.search)
		const status = params.get('status')

		if (status === 'waitingPayment') {
			setFilterStatus('Menunggu Pembayaran')
		} else if (status === 'process') {
			setFilterStatus('Pesanan Diproses')
		} else if (status === 'sent') {
			setFilterStatus('Pesanan Dikirim')
		} else if (status === 'done') {
			setFilterStatus('Pesanan Selesai')
		} else if (status === 'canceled') {
			setFilterStatus('Pesanan Dibatalkan')
		} else if (status === 'refund') {
			setFilterStatus('Pengembalian Dana')
		} else {
			setFilterStatus(null)
		}
	}, [window.location.search])

	useEffect(() => {
		// Check if the user is logged in, if so, set the user state
		const unsubscribe = auth.onAuthStateChanged((user) => {
			if (user) {
				setUser(user)
			} else {
				setUser(null)
			}
		})

		return () => {
			unsubscribe() // Cleanup the listener when the component unmounts
		}
	}, [])

	const translations = {
		en: {
			all: 'All',
			notPaid: 'Not Paid',
			process: 'Dirocess',
			sent: 'Sent',
			done: 'Done',
			canceled: 'Canceled',
			refund: 'Refund',

			totalOrder: 'Total Order',
			account: 'Account',
			orders: 'Orders',
			yourOrders: 'Your Orders',
			noOrdersFound: 'No orders found.',
			orderId: 'Order ID: ',
			totalPrice: 'Total Price: ',
			timeCreated: 'Time Created: ',
			productName: 'Product Name: ',
			quantity: 'Qty(s): ',
			currency: 'IDR ',
			cancelOrder: 'Cancel Order',
			payNow: 'Pay Now',
			orderDetails: 'Order Details',
			waitingForPayment: 'Waiting for Payment',
			waitingForVerification: 'Waiting for Verification from Seller',
			orderFinished: 'Order Finished',

			signIn: 'Sign In',
			pleaseLoginFirst: 'Please sign in to view your orders.',
		},
		id: {
			all: 'Semua',
			notPaid: 'Belum Bayar',
			process: 'Diproses',
			sent: 'Dikirim',
			done: 'Selesai',
			canceled: 'Dibatalkan',
			refund: 'Pengembalian',

			totalOrder: 'Total Pesanan',
			account: 'Akun',
			orders: 'Pesanan',
			yourOrders: 'Pesanan Anda',
			noOrdersFound: 'Tidak ada pesanan ditemukan.',
			orderId: 'ID Pesanan: ',
			totalPrice: 'Total Harga: ',
			timeCreated: 'Waktu Dibuat: ',
			productName: 'Nama Produk: ',
			quantity: 'Jumlah: ',
			currency: 'Rp',
			cancelOrder: 'Batalkan Pesanan',
			payNow: 'Bayar Sekarang',
			orderDetails: 'Detail Pesanan',
			waitingForPayment: 'Menunggu Pembayaran',
			waitingForVerification: 'Menunggu Verifikasi dari Seller',
			orderFinished: 'Pesanan Selesai',

			signIn: 'Masuk',
			pleaseLoginFirst: 'Silakan masuk untuk melihat pesanan Anda.',
		},
	}

	useEffect(() => {
		if (user) {
			const ordersRef = fireDb.ref(`orders/${user.uid}`)

			// Listen for changes in the orders
			const handleSnapshot = (snapshot) => {
				const ordersData = snapshot.val()
				const ordersList = ordersData
					? Object.entries(ordersData)
							.map(([orderId, orderData]) => ({ ...orderData, orderId }))
							.sort((a, b) => new Date(b.timeCreated) - new Date(a.timeCreated))
					: []
				setOrders(ordersList)
			}

			ordersRef.on('value', handleSnapshot)

			return () => {
				// Cleanup the event listener when the component unmounts
				ordersRef.off('value', handleSnapshot)
			}
		}
	}, [user])

	// Fungsi untuk mengonversi ISO string ke format yang mudah dibaca
	const formatDate = (isoString, language) => {
		const date = new Date(isoString)
		const options = {
			year: 'numeric',
			month: 'long',
			day: 'numeric',
			hour: 'numeric',
			minute: 'numeric',
			second: 'numeric',
			timeZoneName: 'short',
		}
		const locale = language === 'id' ? 'id-ID' : 'en-US'
		return date.toLocaleDateString(locale, options)
	}

	// Fungsi untuk membatalkan pesanan
	const cancelOrder = (orderId) => {
		Swal.fire({
			title: 'Are you sure?',
			text: 'Are you sure you want to cancel this order?',
			icon: 'warning',
			showCancelButton: true,
			confirmButtonColor: '#3085d6',
			cancelButtonColor: '#d33',
			confirmButtonText: 'Yes, cancel it!',
		}).then((result) => {
			if (result.isConfirmed) {
				const orderRef = fireDb.ref(`orders/${user.uid}/${orderId}`)
				orderRef
					.update({ status: 'Pesanan Dibatalkan' })
					.then(() => {
						Swal.fire({
							icon: 'success',
							title: 'Order Canceled',
							text: 'Your order has been canceled successfully.',
						})
					})
					.catch((error) => {
						Swal.fire({
							icon: 'error',
							title: 'Error',
							text: 'Failed to cancel order. Please try again later.',
						})
						console.error('Error canceling order: ', error)
					})
			}
		})
	}

	useEffect(() => {
		// Set interval untuk memperbarui countdown setiap detik
		if (user) {
			const interval = setInterval(() => {
				setOrders((prevOrders) => {
					return prevOrders.map((order) => {
						if (order.countdown === 'Expired') {
							const expiredOrderRef = fireDb.ref(
								`orders/${user.uid}/${order.orderId}`
							)
							expiredOrderRef
								.update({ status: 'Pembayaran Kadaluarsa' })
								.then(() => {})
								.catch((error) => {})
							return { ...order, countdown: '' }
						} else {
							const updatedCountdown = calculateCountdown(order.expiry_time)
							if (updatedCountdown === 'Expired') {
								const expiredOrderRef = fireDb.ref(
									`orders/${user.uid}/${order.orderId}`
								)
								expiredOrderRef
									.update({ status: 'Pembayaran Kadaluarsa' })
									.then(() => {})
									.catch((error) => {})
							}
							return { ...order, countdown: updatedCountdown }
						}
					})
				})
			}, 1000)

			return () => clearInterval(interval) // Membersihkan interval saat komponen dibongkar
		}
	}, [user]) // Tambahkan user sebagai dependensi

	// Fungsi untuk menghitung countdown 24 jam
	const calculateCountdown = (expiryTime) => {
		if (!expiryTime) {
			return 'Expired' // Jika expiryTime tidak tersedia
		}

		const timeNow = new Date().getTime() // Waktu sekarang dalam milidetik
		const expiryTimeMillis = new Date(expiryTime.replace(' ', 'T')).getTime() // Konversi expiryTime ke milidetik

		const timeDiffMillis = expiryTimeMillis - timeNow // Selisih waktu hingga expiryTime

		if (timeDiffMillis <= 0) {
			return 'Expired' // Jika waktu sudah habis
		}

		// Hitung jam, menit, dan detik dari selisih waktu
		const hours = Math.floor(timeDiffMillis / (1000 * 60 * 60))
			.toString()
			.padStart(2, '0')
		const minutes = Math.floor(
			(timeDiffMillis % (1000 * 60 * 60)) / (1000 * 60)
		)
			.toString()
			.padStart(2, '0')
		const seconds = Math.floor((timeDiffMillis % (1000 * 60)) / 1000)
			.toString()
			.padStart(2, '0')

		return `${hours}:${minutes}:${seconds}` // Format: HH:MM:SS
	}

	const stringToNumber = (string) => {
		return Number(string.replace(/[^0-9.-]+/g, ''))
	}

	const totalPayment = (totalPrice) => {
		return stringToNumber(totalPrice)
	}

	return (
		<div className="min-h-screen pt-7 px-8 pb-7 w-full">
			{user ? (
				<>
					<span className="flex justify-between items-end text-2xl font-semibold mb-3">
						<h1>{translations[language].yourOrders}</h1>
						<span className="text-[#0081FB]">
							{orders.length} {translations[language].orders}
						</span>
					</span>
					<hr className="mb-5" />
					<div className="flex flex-row gap-0 mb-5 w-full border-2 rounded-full">
						<button
							className={`w-full py-2 px-4 rounded-full focus:outline-none ${filterStatus === null ? 'bg-gradient-to-br from-blue-300 to-blue-500 hover:from-blue-400 hover:to-blue-600 text-white font-semibold' : 'text-gray-400 bg-white hover:bg-gray-200'}`}
							onClick={() => handleFilter(null)}
						>
							{translations[language].all}
						</button>
						<button
							className={`w-full py-2 px-4 rounded-full focus:outline-none ${filterStatus === 'Menunggu Pembayaran' ? 'bg-gradient-to-br from-blue-300 to-blue-500 hover:from-blue-400 hover:to-blue-600 text-white font-semibold' : 'text-gray-400 bg-white hover:bg-gray-200'}`}
							onClick={() => handleFilter('Menunggu Pembayaran')}
						>
							{translations[language].notPaid}
						</button>
						<button
							className={`w-full py-2 px-4 rounded-full focus:outline-none ${filterStatus === 'Pesanan Diproses' ? 'bg-gradient-to-br from-blue-300 to-blue-500 hover:from-blue-400 hover:to-blue-600 text-white font-semibold' : 'text-gray-400 bg-white hover:bg-gray-200'}`}
							onClick={() => handleFilter('Pesanan Diproses')}
						>
							{translations[language].process}
						</button>
						<button
							className={`w-full py-2 px-4 rounded-full focus:outline-none ${filterStatus === 'Pesanan Dikirim' ? 'bg-gradient-to-br from-blue-300 to-blue-500 hover:from-blue-400 hover:to-blue-600 text-white font-semibold' : 'text-gray-400 bg-white hover:bg-gray-200'}`}
							onClick={() => handleFilter('Pesanan Dikirim')}
						>
							{translations[language].sent}
						</button>
						<button
							className={`w-full py-2 px-4 rounded-full focus:outline-none ${filterStatus === 'Pesanan Selesai' ? 'bg-gradient-to-br from-blue-300 to-blue-500 hover:from-blue-400 hover:to-blue-600 text-white font-semibold' : 'text-gray-400 bg-white hover:bg-gray-200'}`}
							onClick={() => handleFilter('Pesanan Selesai')}
						>
							{translations[language].done}
						</button>
						<button
							className={`w-full py-2 px-4 rounded-full focus:outline-none ${filterStatus === 'Pesanan Dibatalkan' ? 'bg-gradient-to-br from-blue-300 to-blue-500 hover:from-blue-400 hover:to-blue-600 text-white font-semibold' : 'text-gray-400 bg-white hover:bg-gray-200'}`}
							onClick={() => handleFilter('Pesanan Dibatalkan')}
						>
							{translations[language].canceled}
						</button>
						<button
							className={`w-full py-2 px-4 rounded-full focus:outline-none ${filterStatus === 'Pengembalian Dana' ? 'bg-gradient-to-br from-blue-300 to-blue-500 hover:from-blue-400 hover:to-blue-600 text-white font-semibold' : 'text-gray-400 bg-white hover:bg-gray-200'}`}
							onClick={() => handleFilter('Pengembalian Dana')}
						>
							{translations[language].refund}
						</button>
					</div>
					{orders.length === 0 ? (
						<p className="text-center">
							{translations[language].noOrdersFound}
						</p>
					) : filteredOrders.length === 0 ? (
						<p className="text-center">
							{translations[language].noOrdersFound}
						</p>
					) : (
						<ul>
							{filteredOrders.map((order, index) => (
								<li
									key={index}
									className="border-2 rounded-2xl mb-3 flex flex-row w-full"
									{...swipeHandlers}
								>
									<div className="w-full">
										<span
											className={`px-4 py-2 bg-blue-500 text-white rounded-t-xl border-b-2 flex justify-between`}
										>
											<h3 className="">
												{translations[language].orderId}{' '}
												<span className="font-semibold">{order.orderId}</span>
											</h3>
											<h3>
												{order.status === 'Menunggu Pembayaran' && (
													<>
														{order.status} -{' '}
														{calculateCountdown(order.expiry_time)}
													</>
												)}
												{order.status !== 'Menunggu Pembayaran' && order.status}
												{order.status === '' && 'Pembayaran Kadaluarsa'}
											</h3>
										</span>
										<div className="bg-white p-4">
											<ul className="flex flex-col gap-3 mb-4">
												{Array.isArray(order.items) &&
													order.items.map((item, itemIndex) => (
														<li key={itemIndex} className="flex flex-row gap-2">
															<span>
																<p className="font-semibold">
																	{item.productName}
																</p>
																<p>
																	{translations[language].quantity}{' '}
																	{item.quantity}
																</p>
																{/* Tampilkan email di sini */}
																{item.emails &&
																	item.emails.map((email, emailIndex) => (
																		<p
																			key={emailIndex}
																			className="text-xs text-zinc-400"
																		>
																			Email {emailIndex + 1}: {email}
																		</p>
																	))}
															</span>
														</li>
													))}
											</ul>
											<hr className="my-4" />
											<p className="text-sm text-zinc-400">
												{translations[language].timeCreated}{' '}
												{formatDate(order.timeCreated, language)}
											</p>
										</div>
										<span className="pl-4 pr-2 py-2 bg-zinc-100 border-t-2 rounded-b-xl flex flex-row items-center justify-between">
											<span className="flex flex-col">
												<p className="">{translations[language].totalOrder}:</p>
												<p className="font-bold">
													{translations[language].currency}
													{totalPayment(order.totalPrice).toLocaleString()}
												</p>
											</span>
											{order.status === 'Belum Memilih Metode Pembayaran' && (
												<span className="flex flex-row gap-0">
													<button
														className="bg-red-500 hover:bg-red-700 transition-all text-white px-4 py-2 rounded-xl mr-2"
														onClick={() => cancelOrder(order.orderId)}
													>
														{translations[language].cancelOrder}
													</button>
													<Link to={`/account/orders/${order.orderId}`}>
														<button className="bg-[#0081FB] hover:bg-blue-700 transition-all text-white px-4 py-2 rounded-xl">
															{language === 'id'
																? 'Pilih Metode Pembayaran'
																: 'Choose Payment Method'}
														</button>
													</Link>
												</span>
											)}
											{order.status === 'Menunggu Pembayaran' && (
												<span className="flex flex-row gap-0">
													<button
														className="bg-red-500 hover:bg-red-700 transition-all text-white px-4 py-2 rounded-xl mr-2"
														onClick={() => cancelOrder(order.orderId)}
													>
														{translations[language].cancelOrder}
													</button>
													<Link to={`/account/orders/${order.orderId}`}>
														<button className="bg-[#0081FB] hover:bg-blue-700 transition-all text-white px-4 py-2 rounded-xl">
															{translations[language].payNow}
														</button>
													</Link>
												</span>
											)}
											{(order.status !== 'Menunggu Pembayaran' ||
												order.status !== 'Belum Memilih Metode Pembayaran') && (
												<Link to={`/account/orders/${order.orderId}`}>
													<button className="bg-[#0081FB] hover:bg-blue-700 transition-all text-white px-4 py-2 rounded-xl">
														{language === 'id'
															? 'Lihat Detail Pesanan'
															: 'View Order Details'}
													</button>
												</Link>
											)}
										</span>
									</div>
									{/* {showDeleteButton && (
										<button
											className="bg-red-500 hover:bg-red-700 transition-all text-white p-2.5 rounded-xl mr-2"
											onClick={() => deleteOrder(order.orderId)}
										>
											<FaTrash />
										</button>
									)} */}
								</li>
							))}
						</ul>
					)}
				</>
			) : (
				<div className="text-center">
					<p>{translations[language].pleaseLoginFirst}</p>
					<Link to="/signin">
						<button className="bg-[#0081FB] hover:bg-blue-700 transition-all text-white px-4 py-2 rounded-xl mt-4">
							{translations[language].signIn}
						</button>
					</Link>
				</div>
			)}

			<Helmet>
				<title>
					{translations[language].orders} | {translations[language].account} -
					HyperTopia
				</title>
			</Helmet>
		</div>
	)
}

export default Orders
